import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Grid,
  Chip,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
  Checkbox,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  ActivitiesList,
  ActivitiesListEn,
  FittingsList,
  FittingsListEn,
  SightseeingList,
  SightseeingListEn,
  TransportList,
  TransportListEn,
} from "constants/campCardItems";
import { FittingsImages, ActivitiesImages, SightseeingImages, TransportImages } from "helpers";
import { useHomeState } from "context/Home";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 220,
    },
    facilitiesList: {
      marginTop: 0,
    },
    facilities: {
      marginRight: 2,
      marginBottom: 2,
    },
    chip: {
      margin: "4px 5px",
      backgroundColor: "#F7F9FB",
      borderRadius: "4px",
      border: "1px   dotted #e4e4e4",
    },
  })
);

export function CampCard({ item, openMapAndCard }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getStartDate, getEndDate } = useHomeState();
  enum Icon {
    BC = "/assets/images/marker/markerBookableCamp.png",
    BP = "/assets/images/marker/markerBookablePark.png",
    C = "/assets/images/marker/markerCamp.png",
    P = "/assets/images/marker/markerPark.png",
  }

  return (
    <Card style={!item.bookable ? { backgroundColor: "#0069b40d" } : { backgroundColor: "#BCCF000d" }}>
      <CardActionArea
        component={Link}
        to={{
          pathname: `/areal/${item.id}`,
          state: {
            ...item,
            ...{
              startDate: getStartDate != null ? getStartDate.toISOString() : null,
              endDate: getEndDate != null ? getEndDate.toISOString() : null,
            },
          },
        }}
      >
        <CardMedia
          className={classes.media}
          image={
            item.image
              ? item.image.length !== ""
                ? item.image
                : `/assets/images/placeholders/${item.id.charCodeAt(6) % 18}.jpg`
              : `/assets/images/placeholders/${item.id.charCodeAt(6) % 18}.jpg`
          }
          title={item.name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={
              item.name.length > 38
                ? !item.bookable
                  ? { height: "84px", color: "#0069B4", fontWeight: 500 }
                  : { height: "84px", color: "#00913C", fontWeight: 500 }
                : !item.bookable
                ? { height: "58px", color: "#0069B4", fontWeight: 500 }
                : { height: "58px", color: "#00913C", fontWeight: 500 }
            }
          >
            <img
              src={item.bookable ? (item.camp ? Icon.BC : Icon.BP) : item.camp ? Icon.C : Icon.P}
              alt={item.title}
              style={{ height: "24px", paddingRight: "6px", marginBottom: "-3px" }}
            />

            {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("label_sort_distance")}: {item.distance} km
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("city")}: {item.area}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("number_of_parking_lots")}: {item.amountOfSpots}
          </Typography>
          <div className={classes.facilitiesList}>
            <Typography variant="body2" color="textSecondary" component="p">
              {t("label_equipment")}
              {": "}
            </Typography>
            {Object.entries(item.fittingsList).map((option: any, v: any) =>
              item.fittingsList[FittingsListEn[v]] === true ? (
                <Tooltip
                  title={FittingsList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "2px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.fittingsList[FittingsListEn[v]]}
                    // onClick={(e) => console.log(FittingsImages)}
                    id={option + v}
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? FittingsImages["Blue/" + v + ".png"].default
                            : FittingsImages["Green/" + v + ".png"].default
                        }
                        height={36}
                        width={36}
                        style={{ backgroundColor: "#fff" }}
                        alt="fittings"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}

            <br />
            <Typography variant="body2" color="textSecondary" component="p">
              {t("activities")}:{" "}
            </Typography>
            {Object.entries(item.activitiesList).map((option: any, v: any) =>
              item.activitiesList[ActivitiesListEn[v]] === true ? (
                <Tooltip
                  title={ActivitiesList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "2px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.activitiesList[ActivitiesListEn[v]]}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? ActivitiesImages["Blue/" + v + ".png"].default
                            : ActivitiesImages["Green/" + v + ".png"].default
                        }
                        height={36}
                        style={{ backgroundColor: "#fff" }}
                        width={36}
                        alt="activities"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}

            <br />
            <Typography variant="body2" color="textSecondary" component="p">
              {t("sightseeing_features")}:{" "}
            </Typography>
            {Object.entries(item.sightseeingList).map((option: any, v: any) =>
              item.sightseeingList[SightseeingListEn[v]] === true ? (
                <Tooltip
                  title={SightseeingList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "2px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.sightseeingList[SightseeingListEn[v]]}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? SightseeingImages["Blue/" + v + ".png"].default
                            : SightseeingImages["Green/" + v + ".png"].default
                        }
                        height={36}
                        width={36}
                        style={{ backgroundColor: "#fff" }}
                        alt="sightseeing"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}

            <br />
            <Typography variant="body2" color="textSecondary" component="p">
              {t("transport_connection")}:{" "}
            </Typography>
            {Object.entries(item.transportList).map((option: any, v: any) =>
              item.transportList[TransportListEn[v]] === true ? (
                <Tooltip
                  title={TransportList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "2px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.transportList[TransportListEn[v]]}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? TransportImages["Blue/" + v + ".png"].default
                            : TransportImages["Green/" + v + ".png"].default
                        }
                        height={36}
                        style={{ backgroundColor: "#fff" }}
                        width={36}
                        alt="transport"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container>
          <Grid item xs={6} sm={6}>
            <Chip
              style={{ marginLeft: "-3px" }}
              variant="outlined"
              className={classes.chip}
              label={
                <section>
                  <div color="textSecondary">
                    {t("price")?.toString()?.toUpperCase()}:{" "}
                    <span style={{ color: "#d32121" }}>
                      {/* {item.bookable ? <span>ab </span> : ""} */}
                      <span>{item.mainPriceUI.toUpperCase() != "KOSTENLOS" ? "ab" : ""} </span>
                      {/* {`${parseFloat(item.mainpriceUI).toFixed(2).replace(".", ",")} €`} */}
                      {item.mainPriceUI.charAt(0).toUpperCase() + item.mainPriceUI.slice(1)}
                    </span>
                  </div>
                </section>
              }
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Rating
              size="medium"
              name="mainItem"
              value={item.ratio ? item.ratio : 0}
              readOnly
              precision={0.5}
              style={{ paddingTop: "6px", marginLeft: "-19px" }}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <Tooltip title={t("on_the_map") + ""} placement="bottom" arrow>
              <Chip
                variant="outlined"
                className={classes.chip}
                label={
                  <section>
                    <IconButton
                      color="primary"
                      onClick={(event) => {
                        openMapAndCard(event, item);
                      }}
                      aria-label={t("add_to_shopping_cart")}
                      style={{ padding: "inherit" }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </section>
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
