import { makeStyles, Theme, createStyles, Button, Grid, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";
import { Typography, Tooltip, Checkbox } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import {
  ActivitiesList,
  ActivitiesListFilter,
  ActivitiesListEn,
  FittingsListFilter,
  FittingsList,
  FittingsListEn,
  SightseeingList,
  SightseeingListEn,
  TransportList,
  TransportListEn,
  RentListFilter,
  RentList,
  RentListEn,
  RabattListFilter,
  RabattListEn,
} from "constants/campCardItems";
import {
  FittingsImages,
  ActivitiesImages,
  SightseeingImages,
  ServiceImages,
  TransportImages,
  RentImages,
  RabattImages,
} from "helpers";

const lists = {
  FittingsList: FittingsList,
  FittingsListEn: FittingsListEn,
  ActivitiesList: ActivitiesList,
  ActivitiesListEn: ActivitiesListEn,
  SightseeingList: SightseeingList,
  SightseeingListEn: SightseeingListEn,
  TransportList: TransportList,
  TransportListEn: TransportListEn,
};

const images = {
  FittingsImages: FittingsImages,
  ActivitiesImages: ActivitiesImages,
  SightseeingImages: SightseeingImages,
  ServiceImages: ServiceImages,
  TransportImages: TransportImages,
  RentImages: RentImages,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: "100%",
      color: "#fff",
      backgroundColor: "#00713C",
      // backgroundColor: "#BCCF00",
      "&:hover": {
        // backgroundColor: "#82e226",
        backgroundColor: "#00913C",
      },
      "&:active": {
        backgroundColor: "#00913C",
        // backgroundColor: "#82e226",
      },
    },
    filter: {
      width: 900,
      margin: "30px auto 0",
      padding: "0px 11px",
      backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0px 0px 0px 10px rgba(255, 255, 255, 0.3)",
      filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.5))",
    },
    filterMobile: {
      width: "100%",
      margin: "30px auto 60px",
      padding: "0px 11px",
      backgroundColor: "#fff",
    },
    filterScroll: {
      // height: "300px",
      overflowY: "scroll",
      overflowX: "hidden",
      marginBottom: "20px",
      paddingLeft: "3px",
    },
  })
);

export function Filters({
  getFilters,
  handleFilterChange,
  currentUser,
  resetFilters,
  saveFilters,
  kostenlos,
  handleKostenlosChange,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1017px)");

  const { t } = useTranslation();

  return (
    <div className={matches ? classes.filter : classes.filterMobile}>
      <div className={classes.filterScroll}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Typography variant="body2" style={{ paddingTop: "3px" }}>
              {t("label_equipment")}
              {": "}
            </Typography>
          </Grid>
          <Grid item style={{ paddingRight: "3px", textAlign: "left" }}>
            {FittingsListFilter.map((option: any) => (
              <Tooltip title={option.name} arrow TransitionComponent={Zoom} key={option.name}>
                <Checkbox
                  onChange={(e) => handleFilterChange(e)}
                  checked={getFilters[FittingsListEn[option.id]]}
                  style={{ paddingRight: "2.6px", paddingLeft: "2.6px", paddingTop: "9px" }}
                  id={FittingsListEn[option.id]}
                  color="primary"
                  icon={
                    <img
                      src={FittingsImages["Gray/" + option.id + ".png"].default}
                      height={36}
                      width={36}
                      alt="fttings"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                  checkedIcon={
                    <img
                      src={FittingsImages["Green/" + option.id + ".png"].default}
                      height={36}
                      width={36}
                      alt="fittings"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                />
              </Tooltip>
            ))}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Typography variant="body2" style={{ paddingTop: "3px" }}>
              {t("activities")}:
            </Typography>
          </Grid>
          <Grid item style={{ paddingRight: "3px", textAlign: "left" }}>
            {ActivitiesListFilter.map((option: any) => (
              <Tooltip title={option.name} arrow TransitionComponent={Zoom} key={option.name}>
                <Checkbox
                  onChange={(e) => handleFilterChange(e)}
                  checked={getFilters[ActivitiesListEn[option.id]]}
                  style={{ paddingRight: "2.6px", paddingLeft: "2.6px", paddingTop: "9px" }}
                  id={ActivitiesListEn[option.id]}
                  color="primary"
                  icon={
                    <img
                      src={ActivitiesImages["Gray/" + option.id + ".png"].default}
                      height={36}
                      width={36}
                      alt="activities"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                  checkedIcon={
                    <img
                      src={ActivitiesImages["Green/" + option.id + ".png"].default}
                      height={36}
                      width={36}
                      alt="activities"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                />
              </Tooltip>
            ))}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Typography variant="body2" style={{ paddingTop: "3px" }}>
              {t("rental_accomodation")}:
            </Typography>
          </Grid>
          <Grid item style={{ paddingRight: "3px", textAlign: "left" }}>
            {RentListFilter.map((option: any) => (
              <Tooltip title={option.name} arrow TransitionComponent={Zoom} key={option.name}>
                <Checkbox
                  onChange={(e) => handleFilterChange(e)}
                  checked={
                    option.id !== 8 ? getFilters[RentListEn[option.id]] : getFilters[FittingsListEn[4]]
                  }
                  style={{ paddingRight: "2.6px", paddingLeft: "2.6px", paddingTop: "9px" }}
                  id={RentListEn[option.id]}
                  color="primary"
                  icon={
                    <img
                      src={RentImages["Gray/" + option.id + ".png"].default}
                      height={36}
                      width={36}
                      alt="rent"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                  checkedIcon={
                    <img
                      src={RentImages["Green/" + option.id + ".png"].default}
                      height={36}
                      width={36}
                      alt="rent"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                />
              </Tooltip>
            ))}
          </Grid>

          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Typography variant="body2" style={{ paddingTop: "3px" }}>
              {t("discount_cards")}:
            </Typography>
          </Grid>
          <Grid item style={{ paddingRight: "3px", textAlign: "left" }}>
            {RabattListFilter.map((option: any) => (
              <Tooltip title={option.name} arrow TransitionComponent={Zoom} key={option.name}>
                <Checkbox
                  onChange={(e) => handleFilterChange(e)}
                  checked={getFilters[RabattListEn[option.id]]}
                  style={{ paddingRight: "3px", paddingLeft: "3px", paddingTop: "9px" }}
                  id={RabattListEn[option.id]}
                  color="primary"
                  icon={
                    <img
                      src={RabattImages["Gray/" + option.id + ".png"].default}
                      height={36}
                      width={56}
                      alt="promotion"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                  checkedIcon={
                    <img
                      src={RabattImages["Green/" + option.id + ".png"].default}
                      height={36}
                      width={56}
                      alt="promotion"
                      style={{ backgroundColor: "#fff" }}
                    />
                  }
                />
              </Tooltip>
            ))}
            <Tooltip title={t("for_free") + ""} arrow TransitionComponent={Zoom} key={"kostenlos"}>
              <Checkbox
                onChange={(e) => handleKostenlosChange(e)}
                checked={kostenlos}
                style={{ paddingRight: "3px", paddingLeft: "3px", paddingTop: "9px" }}
                id="#kostenlos"
                color="primary"
                icon={
                  <img
                    src={`/assets/images/kostenlos_green.png`}
                    height={36}
                    width={40}
                    alt="promotion"
                    style={{ backgroundColor: "#fff" }}
                  />
                }
                checkedIcon={
                  <img
                    src={`/assets/images/kostenlos_red.png`}
                    height={36}
                    width={40}
                    alt="promotion"
                    style={{ backgroundColor: "#fff" }}
                  />
                }
              />
            </Tooltip>
          </Grid>
          {/* {filtersArray.map((filter) => (
            <FilterItem
              key={filter.name}
              name={filter.name}
              text={filter.text}
              getFilters={getFilters}
              handleFilterChange={handleFilterChange}
            />
          ))} */}
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={3} style={{ marginBottom: 11 }}>
          <Button
            variant="contained"
            onClick={resetFilters}
            className={classes.button}
            disableElevation
            fullWidth
          >
            {t("label_reset_filters")}
          </Button>
        </Grid>
        <Grid item xs={12} md={6} />
        {currentUser && (
          <Grid item xs={12} md={3} style={{ marginBottom: 11 }}>
            <Button
              variant="contained"
              onClick={saveFilters}
              className={classes.button}
              disableElevation
              fullWidth
            >
              {t("label_save_filters")}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
