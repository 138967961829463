import { ServiceList, ServiceListEn } from "../Data/tooltips";
import { Checkbox, Tooltip, Zoom, Typography, Grid } from "@mui/material";
import { ServiceImages } from "../../../helpers/images";
import { formStore } from "../State/state";
import { useTranslation } from "react-i18next";

export default function Service() {
  const { t } = useTranslation();
  const { updateService, service } = formStore();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateService(ServiceListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          {t("service")}
        </Typography>
      </Grid>
      {ServiceList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={service[ServiceListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img src={ServiceImages["Gray/" + v + ".png"].default} height={48} width={48} alt="service" />
            }
            checkedIcon={
              <img src={ServiceImages["Green/" + v + ".png"].default} height={48} width={48} alt="service" />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
