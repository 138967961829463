/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link as Links } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import firebase from "firebase";
import { Button, FormControl, Input, InputLabel, SvgIcon, TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Alert } from "@material-ui/lab";
import { FirebaseAuth } from "../../../services/firebase/Firebase";
import { AuthContext } from "../../../context/Auth";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBarLogo: {
    height: 56,
    marginBottom: "20px",
    marginTop: "10px",
  },
}));

const GoogleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="googleIconA"
        d="M19.64,10.26a11.69,11.69,0,0,0-.16-1.9H10v3.77h5.41a4.62,4.62,0,0,1-2,3v2.51h3.23A9.81,9.81,0,0,0,19.64,10.26Z"
      />
      <path
        className="googleIconB"
        d="M10,20.06a9.54,9.54,0,0,0,6.63-2.43l-3.23-2.51a6,6,0,0,1-9-3.17H1.08v2.58A10,10,0,0,0,10,20.06Z"
      />
      <path
        className="googleIconC"
        d="M4.41,12A5.86,5.86,0,0,1,4.09,10a6,6,0,0,1,.32-1.91V5.53H1.08a9.91,9.91,0,0,0,0,9Z"
      />
      <path
        className="googleIconD"
        d="M10,4a5.44,5.44,0,0,1,3.85,1.51l2.86-2.86A9.57,9.57,0,0,0,10,0,10,10,0,0,0,1.08,5.53L4.41,8.12A6,6,0,0,1,10,4Z"
      />
    </SvgIcon>
  );
};

const AppleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573
			c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z"
      />
      <path
        d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334
			c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0
			c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019
			c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464
			c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648
			c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z"
      />
    </SvgIcon>
  );
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © Camp24CheckIn "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const LoginPage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const { currentUser }: any = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }

    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        signIn();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  const signIn = () => {
    FirebaseAuth.signInWithEmailAndPassword(email, password)
      .then((_) => {
        localStorage.removeItem("ParkingArealSearchLocalStorage");
        localStorage.removeItem("ParkingArealSearchFilterLocalStorage");

        history.goBack();
      })
      .catch((reason) => {
        setReason(reason.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);
      });
  };

  const googleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    FirebaseAuth.signInWithPopup(provider)
      .then((result) => {
        localStorage.removeItem("ParkingArealSearchLocalStorage");
        localStorage.removeItem("ParkingArealSearchFilterLocalStorage");
        history.goBack();
      })
      .catch((error) => {
        setReason(error.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);
      });
  };

  const appleSignIn = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.setCustomParameters({
      locale: "de",
    });

    // firebase
    // .auth()
    // .signInWithPopup(provider)
    // .then((result) => {
    //   /** @type {firebase.auth.OAuthCredential} */
    //   var credential = result.credential;

    //   // The signed-in user info.
    //   var user = result.user;

    //   // You can also get the Apple OAuth Access and ID Tokens.
    //   var accessToken = credential.accessToken;
    //   var idToken = credential.idToken;

    //   // ...
    // })
    // .catch((error) => {
    //   // Handle Errors here.
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // The email of the user's account used.
    //   var email = error.email;
    //   // The firebase.auth.AuthCredential type that was used.
    //   var credential = error.credential;

    //   // ...
    // });

    FirebaseAuth.signInWithPopup(provider)
      .then((result) => {
        var credential = result.credential;
        console.log(credential);
        localStorage.removeItem("ParkingArealSearchLocalStorage");
        localStorage.removeItem("ParkingArealSearchFilterLocalStorage");
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
        setReason(error.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {errorAlert && (
          <Alert variant="filled" severity="error">
            {t(reason)}
          </Alert>
        )}
        <Links to={"/"}>
          <img src="/assets/images/logo.png" alt="Camp 24" className={classes.appBarLogo} />
        </Links>
        <form className={classes.form} noValidate>
          <TextField
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            // label={t("label_email")}
            placeholder={t("label_email") + " *"}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <FormControl fullWidth variant="outlined">
            {/* <InputLabel htmlFor="password">{t("label_password")}*</InputLabel> */}
            <OutlinedInput
              onChange={(event) => setPassword(event.target.value)}
              // variant="outlined"
              // margin="normal"
              placeholder={t("label_password") + " *"}
              // value={password || ""}
              required
              fullWidth
              name="password"
              type={showPassword ? "text" : "password"}
              // label={t("label_password")}
              // type="password"
              id="password"
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button onClick={signIn!} fullWidth variant="contained" color="primary" className={classes.submit}>
            {t("label_login")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login/forget" variant="body2">
                {t("label_forgot_password")}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/login/signup" variant="body2">
                {t("label_registration")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <br />
      <Grid container justifyContent="center">
        <Grid item>
          <Button
            style={{ minWidth: "210px" }}
            variant="outlined"
            component="span"
            disableElevation
            onClick={() => googleSignIn()}
            startIcon={<GoogleIcon />}
          >
            {t("sign_in_with_google")}
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ paddingTop: "10px" }}>
        <Grid item>
          <Button
            style={{ minWidth: "210px" }}
            variant="outlined"
            component="span"
            disableElevation
            onClick={() => appleSignIn()}
            startIcon={<AppleIcon />}
          >
            {t("sign_in_with_apple")}
          </Button>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export const Login = withTranslation()(LoginPage);
