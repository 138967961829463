import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Grid, Hidden, IconButton, TextField, withStyles } from "@material-ui/core";
import VehicleSelect from "./vehicleSelect";
import { useTranslation } from "react-i18next";
import ParkPlan from "./ParkPlan";
import { PreBookingForm } from "./PreBookingForm";
import SaveIcon from "@material-ui/icons/Save";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import End from "./End";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { createPreBooking } from "rest/AdminRestClient";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { error } from "console";
import { useUserData } from "context";
import { getPriceForPreBooking, verifyPhone } from "rest/AdminRestClient";
import PreBookingBillTable from "./BillTable";
import PaymentIcon from "@mui/icons-material/Payment";
import { green } from "@material-ui/core/colors";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { textAlign } from "@material-ui/system";
import { Link } from "react-router-dom";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="primary" {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonGreen: {
      color: "#fff",
      backgroundColor: "#00713C",
      "&:hover": {
        backgroundColor: "#00913C",
      },
    },
    buttonRed: {
      color: "#fff",
      backgroundColor: "crimson",
      "&:hover": {
        backgroundColor: "red",
      },
    },
    buttonSms: {
      marginRight: theme.spacing(1),
      color: "#fff",
      backgroundColor: "#ff9800",
      "&:hover": {
        backgroundColor: "#f57c00",
      },
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

export default function PreReserveStepper(params: any) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [saveError, setSaveError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [preBookingLoading, setPreBookingLoading] = React.useState(false);
  const [baseInfoErrors, setBaseInfoErrors] = React.useState({
    arrivalDate: false,
    departureDate: false,
    vehicleSize: false,
    spotName: false,
    numberplate: false,
    phone: false,
    phoneCode: false,
    mail: false,
    firstName: false,
    lastName: false,
    adultCount: false,
    childCount: false,
    petCount: false,
    email: false,
  });
  const [retry, setRetry] = React.useState(false);
  const [priceParams, setPriceParams] = React.useState<any>({});
  const { t } = useTranslation();
  const [isAgbConfirmed, setIsAgbConfirmed] = React.useState(false);

  const {
    getUserData,
    setUserData,
    // setVerifyCodeStatus,
    // setChecked,
    setOpenImageBackdrop,
  } = useUserData();

  const { baseInfo } = getUserData;
  function getSteps() {
    return [
      t("vehicle_length"),
      t("label_booking_select_spot"),
      t("pre_booking_form"),
      t("label_price_list_short"),
      params.parkAreal.isPrepayment ? t("label_payment") : t("label_confirm"),
    ];
  }

  const [disableButton, setDisableButton] = React.useState(false);

  const steps = getSteps();
  const isStepOptional = (step: number) => {
    return step === 5;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handlePrePaymentForm = (e: any) => {
    if (e.target.id === "description") {
      if (e.target.value.length < 200) {
        params.setPreBookingData({
          ...params.preBookingData,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      if (e.target.value.length < 50) {
        params.setPreBookingData({
          ...params.preBookingData,
          [e.target.id]: e.target.value,
        });
      }
    }
    // }
  };

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    //console.log(activeStep);
    if (activeStep === 2) {
      // savePreBooking();
      getPrice();
    } else if (activeStep === 3) {
      savePreBooking();
      // getPrice();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      params.setSpotId("");
      params.setSpotName("");
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleClose = () => {
    params.handleReset();
    params.situation();
    params.setPreBookingExist(false);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <VehicleSelect {...params} />;
      case 1:
        return <ParkPlan {...params} />;
      case 2:
        return <PreBookingForm {...params} baseInfoErrors={baseInfoErrors} />;
      case 3:
        return <PreBookingBillTable priceParams={priceParams} {...params} />;
      case 4:
        return <End {...params} />;
      default:
        return "Unknown step";
    }
  }

  const handleAgb = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgbConfirmed(event.target.checked);
  };

  const getPrice = () => {
    const { address, baseInfo, travellers } = getUserData;
    const obj: any = {};
    let error = false;
    Object.keys(baseInfo).forEach((key) => {
      // console.log(key);
      const value = baseInfo[key];
      // console.log(value);
      if (!value) {
        obj[key] = true;
        error = true;
      } else {
        obj[key] = false;
      }
    });
    setBaseInfoErrors(obj);
    if (error) {
      // params.setPreBookingError({
      //   ...params.preBookingError,
      //   ...obj,
      // });
      params.dialogRef.current.scrollIntoView();
    } else {
      getPriceForPreBooking({
        adultCount: baseInfo.adultCount,
        childCount: baseInfo.childCount,
        phoneNumber: baseInfo.phoneCode + baseInfo.phone,
        parkingArealId: params.parkAreal._id,
        parkingSpotId: params.spotId,
        parkingSpotType: params.vehicleType,
        numberPlate: baseInfo.numberplate,
        start: baseInfo.arrivalDate,
        end: baseInfo.departureDate,
        pet: baseInfo.petCount,
        firstName: baseInfo.firstName,
        lastName: baseInfo.lastName,
      })
        .then((result: any) => {
          setPriceParams(result.data.data);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // console.log(result.data);
          // openPriceTable();
          console.log(result);
        })
        .catch((error) => {
          // setErrorMessage(t(error.response.data));
          // setTimeout(() => {
          //   setErrorMessage("");
          // }, 10500);
          // setSaveError(true);
          // setTimeout(() => {
          //   setSaveError(false);
          // }, 10000);
          console.error(error);
        });
    }
  };

  const savePreBooking = () => {
    const { address, baseInfo, travellers } = getUserData;

    address.birthDate =
      address.birthDate !== null
        ? address.birthDate instanceof Date
          ? address.birthDate?.toISOString()
          : address.birthDate
        : address.birthDate;
    address.validUntil =
      address.validUntil !== null
        ? address.validUntil instanceof Date
          ? address.validUntil?.toISOString()
          : address.validUntil
        : address.validUntil;

    travellers.map((t: any) => {
      t.birthDate =
        t.birthDate !== null
          ? t.birthDate instanceof Date
            ? t.birthDate?.toISOString()
            : t.birthDate
          : t.birthDate;
      t.validUntil =
        t.validUntil !== null
          ? t.validUntil instanceof Date
            ? t.validUntil?.toISOString()
            : t.validUntil
          : t.validUntil;
    });

    const form = { address, baseInfo, travellers };
    setPreBookingLoading(true);
    const messages = createPreBooking({
      firstName: baseInfo.firstName,
      lastName: baseInfo.lastName,
      numberPlate: baseInfo.numberplate,
      description: params.preBookingData.description,
      prePayment: params.preBookingData.prePayment,
      startDate: baseInfo.arrivalDate,
      endDate: baseInfo.departureDate,
      parkingArealId: params.parkAreal._id,
      parkingSpotId: params.spotId,
      date: params.preBookingData.date,
      email: baseInfo.mail,
      phoneNumber: baseInfo.phoneCode + baseInfo.phone,
      code: params.preBookingData.code,
      form: form,
      vehicleType: params.vehicleType,
    })
      .then((result: any) => {
        setPreBookingLoading(false);
        params.handleReset();
        if (result.data.isPrePayment && result.data.pageUrl !== "") {
          window.location.href = result.data.pageUrl;
        } else {
          setActiveStep(4);
        }
      })
      .catch((error) => {
        if (error.response.data === "code_is_expired") {
          setRetry(true);
          setDisableButton(false);
        }
        setErrorMessage(t(error.response.data));
        setTimeout(() => {
          setErrorMessage("");
        }, 10500);
        setSaveError(true);
        setTimeout(() => {
          setSaveError(false);
        }, 10000);
        console.error(error);
      });
  };

  const verify = (e: any) => {
    const obj: any = {};
    let error = false;
    Object.keys(baseInfo).forEach((key) => {
      // console.log(key);
      const value = baseInfo[key];
      // console.log(value);
      if (!value) {
        obj[key] = true;
        error = true;
      } else {
        obj[key] = false;
      }
    });
    // setBaseInfoErrors(obj);
    if (error) {
      // params.setPreBookingError({
      //   ...params.preBookingError,
      //   ...obj,
      // });
      params.dialogRef.current.scrollIntoView();
    } else {
      verifyPhone(baseInfo.phoneCode.toString() + baseInfo.phone, params.parkAreal._id)
        .then((result: any) => {
          if (result.data.ok) {
            params.setCodeInput(true);
          } else if (result.data.ok === false && result.data.preBooking === true) {
            params.setPreBookingExist(true);
          }
          setDisableButton(true);
        })
        .catch((error) => console.error(error));
    }
  };

  const verifyForce = (e: any) => {
    verifyPhone(baseInfo.phoneCode.toString() + baseInfo.phone, params.parkAreal._id, true)
      .then((result: any) => {
        params.setPreBookingExist(false);
        params.setCodeInput(true);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Grid container spacing={4} justifyContent="center">
      <Hidden mdDown>
        <Grid item xs={12} sm={4} md={6} lg={6}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Hidden>
      {/* <Grid item xs={1} /> */}
      <Grid
        container
        xs={12}
        sm={7}
        md={5}
        lg={3}
        xl={2}
        alignContent="flex-start"
        justifyContent="space-around"
        style={{ paddingTop: "30px" }}
      >
        <Button
          variant="contained"
          size={activeStep === 2 ? "medium" : "large"}
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          disableElevation
          color="primary"
          startIcon={<ArrowBackIcon />}
        >
          {t("label_back")}
        </Button>
        {activeStep < 3 && (
          <Button
            disabled={
              activeStep === 1 && params.spotId === ""
              // (activeStep === 3 && params.preBookingData.code.length < 6)
            }
            variant="contained"
            size={activeStep === 2 ? "medium" : "large"}
            disableElevation
            color="primary"
            onClick={handleNext}
            className={classes.button}
            endIcon={
              activeStep !== 3 ? (
                <ArrowForwardIcon />
              ) : params.parkAreal.isPrePayment ? (
                <PaymentIcon />
              ) : (
                <SaveIcon />
              )
            }
          >
            {activeStep === 3 && params.parkAreal.isPrePayment
              ? t("pay_now")
              : activeStep === 3 && !params.parkAreal.isPrePayment
              ? t("label_save_pre")
              : t("label_next")}
          </Button>
        )}

        <Hidden smUp>
          <IconButton size="small" color="primary" aria-label="cancel" component="span" onClick={handleClose}>
            <CancelPresentationIcon />
          </IconButton>
        </Hidden>
      </Grid>

      <Hidden xsDown>
        <Grid item xs={1} sm={1} style={{ padding: "0px !important" }}>
          <IconButton color="primary" aria-label="cancel" component="span" onClick={handleClose}>
            <CancelPresentationIcon />
          </IconButton>
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        <div>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {/* {activeStep === 3 && params.preBookingData.code.length === 6 && ( */}
        {activeStep === 3 && params.parkAreal.isPrePayment && (
          <>
            <Grid container xs={12} justifyContent="center" spacing={2}>
              <Grid container xs={8} md={6} lg={4} xl={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<GreenCheckbox checked={isAgbConfirmed} onChange={handleAgb} name="checkedG" />}
                    label="AGB Einverständnis (Pflichtfeld)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <span>
                    Ich bin mit den{" "}
                    <Link to="/agb" target="_blank" rel="noopener noreferrer">
                      AGB von Camp24checkin
                    </Link>{" "}
                    einverstanden und ich bin mir bewusst, dass die Buchung definitiv ist.
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Button
                  // disabled={activeStep === 3 && params.preBookingData.code.length < 6}
                  variant="contained"
                  size={activeStep === 3 ? "medium" : "large"}
                  disableElevation
                  color="primary"
                  onClick={handleNext}
                  disabled={!isAgbConfirmed}
                  className={classes.button}
                  endIcon={activeStep !== 3 ? <ArrowForwardIcon /> : <PaymentIcon />}
                >
                  {activeStep === 3 ? t("pay_now") : t("label_next")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {activeStep === 3 && !params.parkAreal.isPrePayment && (
          <Button
            onClick={verify}
            // disabled={params.codeInput || params.preBookingExist}
            disabled={disableButton}
            disableElevation
            variant="contained"
            className={classes.buttonSms}
            // style={{ color: "#fff", backgroundColor: "#ff9800" }}
            // fullWidth
          >
            {retry ? t("resend_code") : t("sms_check")}
          </Button>
        )}
      </Grid>
      {activeStep === 3 && params.codeInput && (
        <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ color: "#00713C", fontWeight: "bold", textAlign: "center" }}>
              {t("label_code_input")}
            </Typography>
          </Grid>
        </Grid>
      )}

      {activeStep === 3 && params.preBookingExist && (
        <>
          <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
            <Grid item xs={8}>
              <Typography
                variant="body1"
                style={{ color: "crimson", fontWeight: "bold", textAlign: "center" }}
              >
                {t("label_prebooking_exists")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={3} justifyContent="center" style={{ paddingTop: "20px" }}>
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  params.handleReset();
                  params.situation();
                  params.setPreBookingExist(false);
                }}
                // disabled={params.codeInput}
                disableElevation
                variant="contained"
                className={classes.buttonRed}
                // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                fullWidth
              >
                {t("label_no")}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={verifyForce}
                // disabled={params.codeInput}
                disableElevation
                variant="contained"
                className={classes.buttonGreen}
                // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                fullWidth
              >
                {t("label_yes")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {activeStep === 3 && params.codeInput && (
        <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              id="code"
              label="Code"
              onChange={(e) => handlePrePaymentForm(e)}
              value={params.preBookingData.code}
              type="text"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      {activeStep === 3 && params.preBookingData.code.length === 6 && (
        <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
          {/* <Grid item xs={2}> */}
          <Button
            // disabled={activeStep === 3 && params.preBookingData.code.length < 6}
            variant="contained"
            size={"medium"}
            disableElevation
            color="primary"
            onClick={handleNext}
            className={classes.button}
            endIcon={<PaymentIcon />}
          >
            {t("label_save_pre")}
          </Button>
          {/* </Grid> */}
        </Grid>
      )}
      <Grid item xs={6}>
        {saveError && <Alert severity="error">{errorMessage}</Alert>}
      </Grid>
    </Grid>
  );
}
