import { withTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import NewArealForm from "../../components/CreateNewArealForm/layout";

import { AppBarMain } from "components/AppBar";

const ApplyNewArealPage = () => {
  return (
    <>
      <CssBaseline />
      <AppBarMain location={"imprint"} />
      <NewArealForm />
    </>
  );
};

export const ApplyNewAreal = withTranslation()(ApplyNewArealPage);
