import { RentList, RentListEn } from "../Data/tooltips";
import { Checkbox, Tooltip, Zoom, Typography, Grid } from "@mui/material";
import { RentImages } from "../../../helpers/images";
import { formStore } from "../State/state";
import { useTranslation } from "react-i18next";

export default function Rent() {
  const { t } = useTranslation();
  const { updateRent, rent } = formStore();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateRent(RentListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          {t("rental_accomodation")}:
        </Typography>
      </Grid>
      {RentList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={rent[RentListEn[v]]}
            id="stadion"
            color="primary"
            icon={<img src={RentImages["Gray/" + v + ".png"].default} height={48} width={48} alt="rent" />}
            checkedIcon={
              <img src={RentImages["Green/" + v + ".png"].default} height={48} width={48} alt="rent" />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
