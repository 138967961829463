import React from "react";
import "./App.css";
import "./translations/i18n";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { themeUnbookable, themeBookable } from "constants/theming";
// import { Home } from "./pages/public/Home";
import { ParkingAreal } from "./pages/public/ParkingAreaDetail";
import { Areal } from "./pages/public/AreaDetail";
import { AuthProvider } from "./context/Auth";
import { CookieProvider } from "./context/Cookie";
import { PrivateRoute } from "./components/PrivateRoute";
import { UserPages } from "./components/user";

import {
  Impressum,
  Fair,
  Return,
  Help,
  ReservationSVG,
  Login,
  Forget,
  SignUp,
  ApplyNewAreal,
  Agb,
} from "pages/public";
import { VerifyEmail } from "pages/authenticated";
import { ParkingAreaDetailProvider, ProfileProvider, AreaDetailProvider, UserDataProvider } from "context";
import { ApiServiceProvider } from "rest";
import { HomeProvider } from "context/Home";
import { GoogleMapsProvider } from "context/GoogleMaps";

import { ReactQueryDevtools } from "react-query/devtools";
import { Suspense, lazy } from "react";
import Home from "pages/public/Home";

// const Home = lazy(() => import("./pages/public/Home"));

// const HomeWithProvider = () => (
//   <CookieProvider>
//     <HomeProvider>
//       <Suspense fallback={<div>Laden...</div>}>
//         <Home />
//       </Suspense>
//     </HomeProvider>
//   </CookieProvider>
// );

const HomeWithProvider = () => (
  <CookieProvider>
    <GoogleMapsProvider>
      <HomeProvider>
        <Home />
      </HomeProvider>
    </GoogleMapsProvider>
  </CookieProvider>
);

const ParkingArealWithProvider = () => (
  <CookieProvider>
    <GoogleMapsProvider>
      <ParkingAreaDetailProvider>
        <ParkingAreal />
      </ParkingAreaDetailProvider>
    </GoogleMapsProvider>
  </CookieProvider>
);

const ArealWithProvider = () => (
  <CookieProvider>
    <GoogleMapsProvider>
      <AreaDetailProvider>
        {/* <MuiThemeProvider theme={themeUnbookable}> */}
        <Areal />
        {/* </MuiThemeProvider> */}
      </AreaDetailProvider>
    </GoogleMapsProvider>
  </CookieProvider>
);

const ProfileWithProvider = () => (
  <ProfileProvider>
    <GoogleMapsProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <UserPages />
      {/* </MuiThemeProvider> */}
    </GoogleMapsProvider>
  </ProfileProvider>
);

const ImpressumWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Impressum />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const ReturnWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Return />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const ApplyNewArealWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <ApplyNewAreal />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const LoginWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Login />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const ForgetWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Forget />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const SignUpWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <SignUp />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const VerifyEmailWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <VerifyEmail />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApiServiceProvider>
        <UserDataProvider>
          <BrowserRouter>
            <MuiThemeProvider theme={themeBookable}>
              <Switch>
                <Route exact path={"/help"} component={Help} />
                <Route exact path={"/agb"} component={Agb} />
                <Route exact path={"/"} component={HomeWithProvider} />
                <Route exact path={"/impressum"} component={ImpressumWithProvider} />
                {/* <Route exact path={"/fair"} component={Fair} /> */}
                <Route exact path={"/return"} component={ReturnWithProvider} />
                <Route exact path={"/booking/svg"} component={ReservationSVG} />
                <Route exact path={"/apply-new-areal"} component={ApplyNewArealWithProvider} />
                <Route exact path={"/login"} component={LoginWithProvider} />
                <Route exact path={"/login/forget"} component={ForgetWithProvider} />
                <Route exact path={"/login/signup"} component={SignUpWithProvider} />
                <Route exact path={"/parkingAreal/:id"} component={ParkingArealWithProvider} />
                <Route exact path={"/areal/:id"} component={ArealWithProvider} />
                <PrivateRoute exact path={"/user/verify"} component={VerifyEmailWithProvider} />
                <PrivateRoute exact path={"/user/profile"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/travellers"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/vehicles"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/bookmarks"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/bookings"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/booking-detail/:id"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/bookings-past"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/reviews"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/guthaben"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/stornieren"} component={ProfileWithProvider} />
                <PrivateRoute exact path={"/user/rechnungen"} component={ProfileWithProvider} />
              </Switch>
            </MuiThemeProvider>
          </BrowserRouter>
          <ReactQueryDevtools />
        </UserDataProvider>
      </ApiServiceProvider>
    </AuthProvider>
  );
};

export default App;
