import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { FirebaseAuth } from "services/firebase/Firebase";
import { useTranslation, withTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { DateRangePicker } from "react-dates";
import "../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { AppBarMain } from "components/AppBar";
import {
  useUserRole,
  useUserOwn,
  useParkingAreaById,
  useBookmarkFolderAll,
  useCreateBookmark,
  useCommentsOfParkingAreal,
} from "rest";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { AuthContext } from "../../../context/Auth";
import HorizontalLabelPositionBelowStepper2 from "components/Booking/Stepper";
import {
  Map as MapIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Info as InfoIcon,
} from "@material-ui/icons/";
// import "./pstyles.css";
import moment from "moment";
import "moment/locale/de";
import { Cookies } from "components";
import {
  MainCard,
  CommentArea,
  PriceInfoDefaultCard,
  PriceInfoBookableCard,
  UploadImage,
  GeneralInfoCard,
  ContactInfoCard,
  TouristInfoModal,
} from "components/AreaDetail";

import useStyles from "./style";
import {
  Dialog,
  Slide,
  useScrollTrigger,
  Zoom,
  useMediaQuery,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  CssBaseline,
  Drawer,
  Grid,
  Paper,
  Container,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  DialogContentText,
  ListSubheader,
  CardActions,
  Fab,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useParkingAreaDetailState } from "context/ParkingAreaDetail";
import { IParkingAreaDetail } from "interfaces/parkingAreaDetail/IParkingAreaDetail";
import { TransitionProps } from "@material-ui/core/transitions";

const StyledDialog = withStyles({
  paper: {
    overflowY: "auto",
  },
})(Dialog);

const dist = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515 * 1.609344;
  return dist.toFixed(2);
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

type own = {
  address_valid: boolean;
  has_traveller: boolean;
  person_valid: boolean;
  vehicle_valid: boolean;
};

function ScrollTop(props: Props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick2 = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick2} role="presentation" className={classes.rootx}>
        {children}
      </div>
    </Zoom>
  );
}

function ParkingArealImp(props: any) {
  const { currentUser }: any = useContext(AuthContext);
  const location = useLocation<any>();

  const {
    setTotalRating,
    comment,
    setComment,
    galerie,
    setGalerie,
    uploadSuccess,
    uploadError,
    bookmarkForm,
    setBookmarkForm,
    setExpControl,
    setTotalComments,
    setClosestBakery,
    setInfos,
    touristInfoTab,
  } = useParkingAreaDetailState();

  const history = useHistory();
  let { id }: any = useParams();
  const classes = useStyles();
  const [own, setOwn] = useState<own>({
    address_valid: false,
    has_traveller: false,
    person_valid: false,
    vehicle_valid: false,
  });

  const [images, setImages] = React.useState<any>([]);
  const [item, setItem] = useState<any>(null);
  const [getEndDate, setEndDate] = useState<any>(null);
  const [getStartDate, setStartDate] = useState<any>(null);
  const [folders, setFolders] = React.useState<any>([]);
  const [dialog, setDialog] = React.useState<boolean>(false);
  const [dialogAlert, setDialogAlert] = React.useState<boolean>(false);
  const [getOpenMap, setOpenMap] = useState<any>(false);
  const [getFocusedInput, setFocusedInput] = useState<any>(null);
  const [lat, setLat] = useState<String>("");
  const [lng, setLng] = useState<String>("");
  const [user, setUser] = React.useState<any>(null);
  const [bookmarkFolderId, setBookmarkFolderId] = React.useState<any>("");
  const [commentx, setCommentx] = React.useState<boolean>(false);
  const [copyOpen, setCopyOpen] = React.useState<boolean>(false);
  const [openImageBackdrop, setOpenImageBackdrop] = React.useState(false);
  const [vehicleType, setVehicleType] = React.useState("B");
  const matches = useMediaQuery("(min-width:510px)");
  const getPosition = (position: any) => {
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);
  };

  const [dialogOpenPhoto, setDialogOpenPhoto] = useState(false);
  const { t } = useTranslation();
  const mags = [
    {
      original: `/assets/images/placeholders/1.jpg`,
    },
    {
      original: `/assets/images/placeholders/2.jpg`,
    },
    {
      original: `/assets/images/placeholders/3.jpg`,
    },
    {
      original: `/assets/images/placeholders/4.jpg`,
    },
    {
      original: `/assets/images/placeholders/5.jpg`,
    },
  ];

  const reducer = (accumulator, item) => {
    return (
      accumulator +
      (item.location + item.noiseLevel + item.facilities + item.cleanliness + item.price + item.activity) / 6
    );
  };

  const { mutate: createBookmark } = useCreateBookmark({
    onSuccess: () => {
      setBookmarkForm(false);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { refetch: getUserOwn } = useUserOwn({
    enabled: false,
    onSuccess: (data) => {
      setOwn(data);
    },
  });

  const { refetch: getBookmarkFolderAll } = useBookmarkFolderAll({
    enabled: false,
    onSuccess: (data) => {
      setFolders(data);
    },
    onError: (error) => console.error(error),
  });

  const { refetch: getUserRole } = useUserRole({
    enabled: false,
    onSuccess: (data) => {
      setUser(data.user);
      setVehicleType(data.vehicleType);

      getBookmarkFolderAll();
      getUserOwn();
    },
    onError: (error) => {
      FirebaseAuth.signOut()
        .then((_) => console.log("user failed"))
        .catch((reason) => console.log(reason));
      console.info(error);
    },
  });

  const { refetch: getParkingArealById } = useParkingAreaById<IParkingAreaDetail>(
    {
      id,
    },
    {
      enabled: !id,
      onSuccess: (parkingAreaDetailData) => {
        setItem(parkingAreaDetailData);
        if (parkingAreaDetailData.image) {
          let mages = parkingAreaDetailData.image.map((a: any) => {
            return { original: a.url, desc: a.description ? a.description : "" };
          });
          if (mages.length > 0) {
            setImages(mages);
          }
        }
      },
      onError: (error) => console.error(error),
    }
  );

  const { refetch: getCommentsOfParkingAreal } = useCommentsOfParkingAreal(
    { id },
    {
      enabled: !id,
      onSuccess: (data) => {
        setComment(data);
        let arr: any = [];
        for (let i = 0; i < data.length; i++) {
          arr.push(false);
        }
        setExpControl(arr);
        const initialValue = 0;
        const total = data.reduce(reducer, initialValue) / data.length;

        setTotalRating(total);
        setTotalComments(data.length);
      },
      onError: (error) => console.error(error),
    }
  );

  useEffect(() => {
    if (currentUser) {
      if (currentUser.emailVerified === false) {
        history.push("/user/verify");
      } else {
        getUserRole();
      }
    }

    setImages(mags);

    getParkingArealById();

    if (location.state) {
      if (location.state.startDate) {
        setStartDate(moment(location.state.startDate).set("hour", 9));
      }
      if (location.state.endDate) {
        setEndDate(moment(location.state.endDate).set("hour", 9));
      }
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition);
    }

    getCommentsOfParkingAreal();
  }, []);

  const openCopyDialog = () => {
    setCopyOpen(true);
    navigator.clipboard.writeText(window.location.href);
  };

  const openDialog = () => {
    if (own.vehicle_valid) {
      setDialog(true);
    } else {
      setDialogAlert(true);
    }
  };

  const openClosestBakery = () => {
    setInfos(
      item.info.filter(function (el) {
        return el.mode === touristInfoTab;
      })
    );
    setClosestBakery(true);
  };

  const openMap = () => {
    setOpenMap(!getOpenMap);
  };

  const galerieHandle = (event: React.MouseEvent<HTMLDivElement>) => {
    setGalerie(true);
    setCommentx(false);
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#galerie");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const commentHandle = (event: React.MouseEvent<HTMLDivElement>) => {
    setCommentx(true);
    setGalerie(false);
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#comment");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const Map = withGoogleMap(() => (
    <GoogleMap defaultZoom={12} defaultCenter={item && { lat: item.lat, lng: item.lon }}>
      <Marker icon="/assets/images/marker/marker3.png" position={item && { lat: item.lat, lng: item.lon }} />
    </GoogleMap>
  ));

  const saveBookmarkForm = () => {
    let data = {
      bookmarkFolderId: bookmarkFolderId,
      name: item.name,
      parkingArealId: item.id,
    };
    createBookmark(data);
  };

  return (
    <React.Fragment>
      <Backdrop
        className={classes.backdrop}
        open={openImageBackdrop}
        onClick={() => {
          setOpenImageBackdrop(false);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <AppBarMain location={"parkingAreal"} />
      <Cookies />
      <Drawer
        anchor="top"
        open={getOpenMap}
        onClose={openMap}
        variant={"permanent"}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: getOpenMap,
          [classes.drawerClose]: !getOpenMap,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: getOpenMap,
            [classes.drawerClose]: !getOpenMap,
          }),
        }}
        id="back-to-top-anchor"
      >
        <Grid container spacing={0} style={{ marginTop: "auto" }}>
          {getOpenMap && (
            <Grid item xs={12} md={12}>
              <br />
              <br />
              <br />
              <Map
                containerElement={<div style={{ height: `500px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
              <br />
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Paper elevation={0} style={{ marginBottom: "9px" }}>
              <Container maxWidth="lg">
                <Grid container spacing={0} justifyContent="space-between">
                  <Grid item xs={4} sm={2} md={2} lg={2}>
                    <Button
                      variant="contained"
                      color={galerie ? "primary" : "secondary"}
                      disableElevation
                      size={!matches ? "small" : "medium"}
                      onClick={(event: any) => galerieHandle(event)}
                      style={{ marginRight: "10px" }}
                    >
                      {t("gallery")}
                    </Button>
                    <Button
                      variant="contained"
                      color={commentx ? "primary" : "secondary"}
                      disableElevation
                      size={!matches ? "small" : "medium"}
                      onClick={(event: any) => commentHandle(event)}
                    >
                      {/* setCommentx triggers inside commentHandle */}
                      {t("label_reviews")}
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} style={{ textAlign: "right" }}>
                    {item && item.info && item.info.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<InfoIcon />}
                        disableElevation
                        size={!matches ? "small" : "medium"}
                        onClick={openClosestBakery}
                        style={{ marginRight: "10px" }}
                      >
                        {t("turist_info")}
                      </Button>
                    )}
                    {/* Tourist Info Modal */}
                    <TouristInfoModal item={item} />

                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      size={!matches ? "small" : "medium"}
                      endIcon={<MapIcon />}
                      onClick={openMap}
                    >
                      {!getOpenMap === true ? t("show_map") : t("hide_map")}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
      {item && comment && (
        <Container
          maxWidth="lg"
          className={clsx(classes.container, {
            [classes.containerShift]: getOpenMap,
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <MainCard
                images={images}
                item={item}
                commentHandle={commentHandle}
                dist={dist}
                openBookmarkForm={() => {
                  setBookmarkForm(true);
                }}
                openCopyDialog={openCopyDialog}
                lat={lat}
                lng={lng}
                user={user}
                getUserArealEditToken={null}
                hearth={null}
              />
              <Dialog
                open={copyOpen}
                onClose={() => {
                  setCopyOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" style={{ color: "#434d47" }}>
                  {t("link_copied_clipboard")}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setCopyOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    {t("label_ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              {currentUser && user && (
                <Dialog
                  open={bookmarkForm}
                  onClose={() => {
                    setBookmarkForm(false);
                  }}
                >
                  <DialogContent style={{ width: 400 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="select">{t("select_folder")}</InputLabel>
                      <Select
                        labelId="select"
                        onChange={(e) => {
                          setBookmarkFolderId(e.target.value);
                        }}
                      >
                        {user &&
                          folders.map((bookmark: any, index: number) => (
                            <MenuItem value={bookmark.id} key={index}>
                              {bookmark.name === "default" ? t("standard") : bookmark.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={() => {
                        setBookmarkForm(false);
                      }}
                    >
                      {t("label_cancel")}
                    </Button>
                    <Button onClick={saveBookmarkForm} color="primary">
                      {t("create")}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <CommentArea id={id} item={item} />

              <Collapse in={uploadSuccess}>
                <Alert severity="success"> {t("label_file_upload_success")}</Alert>
              </Collapse>
              <Collapse in={uploadError}>
                <Alert severity="error"> {t("label_file_upload_fail")}</Alert>
              </Collapse>
              {/* upload image dialog */}
              <Dialog
                open={dialogOpenPhoto}
                onClose={() => {
                  setDialogOpenPhoto(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{t("label_wrong_image_format_size_title")}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("label_wrong_image_format_or_size", { sizeLimit: "10" })}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogOpenPhoto(false);
                    }}
                    color="primary"
                  >
                    {t("label_ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              {currentUser && (
                <UploadImage
                  id={id}
                  setDialogOpenPhoto={setDialogOpenPhoto}
                  setOpenImageBackdrop={setOpenImageBackdrop}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {item.bookable === true ? (
                <PriceInfoBookableCard item={item} vehicleType={vehicleType} />
              ) : (
                <PriceInfoDefaultCard item={item} vehicleType={vehicleType} />
              )}
              {currentUser && item.bookable && item.svg && (
                <Card style={{ marginBottom: 18, overflow: "visible" }}>
                  <List
                    dense={true}
                    subheader={<ListSubheader color="primary">{t("book")}</ListSubheader>}
                  ></List>
                  <CardContent className={classes.dateCard}>
                    <div className={classes.dateRangePicker}>
                      <DateRangePicker
                        startDate={getStartDate}
                        startDateId="startDate"
                        endDate={getEndDate}
                        endDateId="endDate"
                        onDatesChange={({ startDate, endDate }) => {
                          // @ts-ignore
                          setStartDate(startDate != null ? startDate.set("hour", 9) : startDate);
                          setEndDate(endDate != null ? endDate.set("hour", 9) : endDate);
                        }}
                        startDatePlaceholderText={t("label_startDate")}
                        endDatePlaceholderText={t("label_endDate")}
                        focusedInput={getFocusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                      />
                    </div>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      onClick={openDialog}
                      disabled={!getStartDate || (!getEndDate && true)}
                    >
                      {t("reserve_now")}
                    </Button>
                  </CardActions>
                </Card>
              )}

              <Dialog
                open={dialogAlert}
                onClose={() => {
                  setDialogAlert(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Bitte ein Fahrzeug hinzufügen"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("before_reservation_add_vehicle")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogAlert(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    {t("label_ok")}
                  </Button>
                </DialogActions>
              </Dialog>

              <StyledDialog
                fullScreen
                open={dialog}
                TransitionComponent={Transition}
                onClose={() => {
                  setDialog(false);
                }}
              >
                <DialogContent style={{ paddingTop: "5px" }}>
                  <HorizontalLabelPositionBelowStepper2
                    parkAreal={item}
                    startDate={getStartDate}
                    endDate={getEndDate}
                    user={user}
                    situation={() => {
                      setDialog(false);
                    }}
                  />
                </DialogContent>
              </StyledDialog>
              <GeneralInfoCard item={item} />
              <ContactInfoCard item={item} />
            </Grid>
          </Grid>
        </Container>
      )}
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top" style={{ borderRadius: "10%" }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}

export const ParkingAreal = withTranslation()(ParkingArealImp);
