import React from "react";
import { withTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useParkingSpotsSVGMobile } from "rest";

const ReservationSVGPage: React.FC = () => {
  const [svg, setSvg] = React.useState<string>("");

  useParkingSpotsSVGMobile({
    onSuccess: (data) => {
      let svg = data.svg;
      if (data.demo) {
        const regex = /width="([0-9]*)/m;
        const width = data.svg.match(regex)[1];
        const trans = parseInt(width) * 0.57;
        const pix = parseInt(width) * 0.33;
        const st = `<g id="DEMO"><text transform="translate(0 ${trans})" style="opacity:0.28;font-size:${pix}px;stroke:#000;stroke-miterlimit:10;stroke-width:5px;font-family:Arial-BoldMT, Arial;font-weight:700">DEMO</text></g></svg>`;
        svg = svg.substring(0, svg.length - 6) + st;
      }
      setSvg(svg);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <>
      <CssBaseline />
      <Grid container spacing={0}>
        {svg && (
          <Grid item xs={12} md={12} lg={12}>
            <Paper elevation={0}>
              <SVG src={svg} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const ReservationSVG = withTranslation()(ReservationSVGPage);
