import React, { useContext, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, TextField } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";

import { FirebaseAuth } from "../../../services/firebase/Firebase";
import { AuthContext } from "../../../context/Auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  appBarLogo: {
    height: 56,
    marginBottom: "20px",
    marginTop: "10px",
  },
}));

const ForgetPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [forgetAlert, setForgetAlert] = useState(false);
  const { currentUser }: any = useContext(AuthContext);

  const history = useHistory();
  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, []);

  const resetPassword = () => {
    FirebaseAuth.sendPasswordResetEmail(email)
      .then((value) => {
        setForgetAlert(true);
        setTimeout(() => {
          setForgetAlert(false);
          history.push("/login");
        }, 5000);
      })
      .catch((reason) => {
        // eslint-disable-next-line no-useless-escape
        setReason(reason.code.replaceAll(/[\/-]/gi, "_"));
        setErrorAlert(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {errorAlert && (
          <Alert variant="filled" severity="error">
            {t(reason)}
          </Alert>
        )}
        {forgetAlert && (
          <Alert variant="filled" severity="success">
            {t("login_forget_password_success")}
          </Alert>
        )}
        <img src="/assets/images/logo.png" alt="Camp 24" className={classes.appBarLogo} />
        <form className={classes.form} noValidate>
          <TextField onChange={(event) => setEmail(event.target.value)} variant="outlined" margin="normal" required fullWidth id="email" label={t("label_email")} name="email" autoComplete="email" autoFocus />
          <Button onClick={resetPassword!} fullWidth variant="contained" color="primary" className={classes.submit}>
            {t("label_forgot_password")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                {t("label_cancel")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export const Forget = withTranslation()(ForgetPage);
