import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

import { ICreateFairMailParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const useCreateFairMail = (options?: UseMutationOptions<any, any, ICreateFairMailParams>) => {
  const { publicApiService } = useContext(apiServiceContext);

  const mutationFn = useCallback(
    (payload: ICreateFairMailParams) =>
      publicApiService.createCancellableRequest((cancelToken) =>
        publicApiService.fairMail(payload, cancelToken)
      ),
    [publicApiService]
  );

  const mutation = useMutation<any, any, ICreateFairMailParams>(mutationFn, options);

  return mutation;
};
