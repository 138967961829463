import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";

import {
  CssBaseline,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Collapse,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  CardHeader,
  Backdrop,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { IComment } from "interfaces/user";
import { IBooking } from "interfaces/booking";
import { CommentRow, BookingCommentRow } from "components/user/reviews";
import {
  useDeleteComment,
  useBookingOfUser,
  useCommentOfUser,
  useUpdateBookingComment,
  useCreateCommentUser,
} from "rest";
import { SettingsSystemDaydreamOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    booking: {
      backgroundColor: "#DCE6A0",
      borderBottom: "5px solid white",
    },
    red: {
      backgroundColor: "#fad0c5",
      borderBottom: "5px solid white",
    },
    titlex: {
      backgroundColor: "#FFFFFF",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const UserReviews: React.FC = () => {
  const classes = useStyles();

  const [getComments, setComments] = useState<IComment[]>([]);
  const [getBookings, setBookings] = useState<IBooking[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [RID, setRID] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedE, setCheckedE] = useState(false);

  const [checkedA, setCheckedA] = useState(false);
  const [openImageBackdrop, setOpenImageBackdrop] = useState(false);
  const [getStars, setStars] = useState<any>([]);
  const [getComment, setComment] = useState<any>([]);
  const [getReady, setReady] = useState(false);
  const { t } = useTranslation();

  const d = new Date();
  const dx = d.setMonth(d.getMonth() - 3);

  const handleImageBackdropClose = () => {
    setOpenImageBackdrop(false);
  };
  const handleImageBackdropOpen = () => {
    setOpenImageBackdrop(true);
  };

  useCommentOfUser({
    onSuccess: (results) => {
      setComments(results);
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  const { refetch } = useBookingOfUser(
    {
      onSuccess: (results: IBooking[]) => {
        const books = results
          // .filter((item: any) => new Date(item.endDate).getTime() - new Date().getTime() < 0)
          .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
        setBookings(books);
        let arrResult: any = [];
        books.forEach(function (value) {
          arrResult[value.id] = 5;
        });
        setStars(books);
        handleImageBackdropClose();
        setReady(true);
      },
      onError: (error: any) => {
        console.error(error);
      },
      // enabled: false
    },
    true
  );

  useEffect(() => {
    handleImageBackdropOpen();
  }, []);

  const cDelete = (item: any) => {
    setDialogOpen(true);
    setRID(item.id);
  };

  const { mutate: deleteComment } = useDeleteComment({
    onSuccess: () => {
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => setCheckedE(false), 5000);
    },
  });

  const handleDelete = () => {
    setDialogOpen(false);
    deleteComment({ id: RID });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const { mutate: createCommentUser } = useCreateCommentUser({
    onSuccess: (result, params) => {
      refetch();
      setCheckedA(true);
      setTimeout(() => {
        setCheckedA(false);
      }, 5000);
    },
    onError: (error) => console.error(error),
  });

  const sendComment = (id: any, pid: any) => {
    const send = {
      body: getComment[id],
      parkingArealId: pid,
      noiseLevel: getStars[id],
      location: getStars[id],
      facilities: getStars[id],
      cleanliness: getStars[id],
      price: getStars[id],
      activity: getStars[id],
      bookingId: id,
    };

    createCommentUser(send);
  };

  if (getBookings != null && getComments != null && getReady) {
    return (
      <Fade in={true}>
        <>
          <CssBaseline />

          <Collapse in={checked}>
            <Alert severity="success">{t("your_rewiev_succesfully_deleted")}</Alert>
          </Collapse>
          <Collapse in={checkedE}>
            <Alert severity="error">{t("your_rewiev_succesfully_deleted")}</Alert>
          </Collapse>

          <Collapse in={checkedA}>
            <Alert severity="success">{t("your_rewiev_succesfully_saved")}</Alert>
          </Collapse>
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Möchten Sie Ihre Bewertung löschen?"}</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                {t("label_cancel")}
              </Button>
              <Button onClick={handleDelete} color="primary">
                {t("delete")}
              </Button>
            </DialogActions>
          </Dialog>

          <Card
            style={{ marginBottom: 18, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}
            id={"bookingpast"}
          >
            <CardHeader style={{ textAlign: "center" }} title="Ihre Meinung ist uns wichtig" />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%", paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "5%", paddingTop: "0px", paddingBottom: "0px" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getBookings?.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={6}>{t("comment_table_no_record")}</TableCell>
                    </TableRow>
                  )}
                  {getBookings.map(
                    (item) =>
                      // new Date(item.endDate).getTime() - new Date(dx).getTime() > 0 && (
                      !item.commentStatus && (
                        <BookingCommentRow
                          item={item}
                          getStars={getStars}
                          setStars={setStars}
                          setComment={setComment}
                          sendComment={sendComment}
                          getComment={getComment}
                          key={item.id + "comment"}
                        />
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          <Card
            style={{ marginBottom: 18, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}
            id={"bookingpast"}
          >
            <CardHeader style={{ textAlign: "center" }} title={t("your_ratings")} />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%", paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell style={{ paddingTop: "0px", paddingBottom: "0px" }}></TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "5%", paddingTop: "0px", paddingBottom: "0px" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getComments?.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={6}>{t("comment_table_no_record")}</TableCell>
                    </TableRow>
                  )}
                  {getComments.map((item) => (
                    <CommentRow item={item} key={item.id} cDelete={cDelete} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      </Fade>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={openImageBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export const Reviews = withTranslation()(UserReviews);
