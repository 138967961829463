import {
  priceLoadStateType,
  priceLoadSecondDropdownType,
  generalLoadType,
  ActivitiesListType,
  FittingsListType,
  RentListType,
  SightseeingListType,
  TransportListType,
  ServiceListType,
} from "../Types/types";

export const contactLoad = {
  name: "",
  street: "",
  phone: "",
  postCode: "",
  email: "",
  city: "",
  homepage: "",
  state: "",
};

export const priceLoad = {
  zweipersonen: "Kostenlos",
  proPerson: "inklusive",
  proKid: "inklusive",
  proPet: "Nein",
  taxPerson: "Nein",
  taxKid: "Nein",
  taxPet: "Nein",
  electricity: "Nein",
  water: "Nein",
  shower: "Nein",
  wc: "Nein",
  casset: "Nein",
  ground: "Nein",
  wlan: "Nein",
  trashbin: "Nein",
  wash: "Nein",
  barrier: "Nein",
  playground: "Nein",
} as priceLoadStateType;

export const priceLoadStatus = {
  zweipersonen: true,
  proPerson: true,
  proKid: true,
  proPet: true,
  taxPerson: true,
  taxKid: true,
  taxPet: true,
  electricity: true,
  water: true,
  shower: true,
  wc: true,
  casset: true,
  ground: true,
  wlan: true,
  trashbin: true,
  wash: true,
  barrier: true,
  playground: true,
};

export const priceLoadSecondDropdown: priceLoadSecondDropdownType = {
  proPet: false,
  taxPerson: false,
  taxKid: false,
  taxPet: false,
  electricity: false,
  water: false,
  shower: false,
  wc: false,
  casset: false,
  ground: false,
  wlan: false,
  trashbin: false,
  wash: false,
};

export const generalLoad = {
  season: "Ganzjährig",
  parkplaceCount: "",
  maximumLength: "",
  maximumStay: "",
} as generalLoadType;

export const ActivitiesLoad: ActivitiesListType = {
  cycling: false,
  mountainBiking: false,
  swimming: false,
  swimmingPool: false,
  hiking: false,
  fishing: false,
  paddling: false,
  rafting: false,
  sailing: false,
  diving: false,
  golf: false,
  tennis: false,
  skateboarding: false,
  volleyball: false,
  horsebackRiding: false,
  beach: false,
  badminton: false,
  boccia: false,
  tableTennis: false,
  skiing: false,
  crossCountrySkiing: false,
  climbing: false,
  cableCar: false,
  cogRailway: false,
  boatTrip: false,
  paragliding: false,
  BalloonRide: false,
  SafariTour: false,
  naturist: false,
  thermalBath: false,
};

export const FittingsListLoad: FittingsListType = {
  electricity: false,
  water: false,
  WC: false,
  shower: false,
  rentaBathrooms: false,
  WLAN: false,
  barrierFree: false,
  dogsAllowed: false,
  dogShower: false,
  wasteDisposal: false,
  cassetteDisposal: false,
  floorDisposal: false,
  wasteDisposalMachine: false,
  waterOnSite: false,
  sewageOnSite: false,
  TVconnection: false,
  childrenPlayground: false,
  childrenPlayroom: false,
  childrenAnimation: false,
  babyChangingRoom: false,
  recreationRoom: false,
  dishwasher: false,
  washingMachine: false,
  tumbleDryer: false,
  dryerRoom: false,
  wellness: false,
  sauna: false,
  massage: false,
  restaurant: false,
  kiosk: false,
  telmarkt: false,
  bakery: false,
  coffee: false,
  barbecue: false,
  winterCamping: false,
  multilingual: false,
};

export const RentListLoad: RentListType = {
  mobilehome: false,
  flats: false,
  campingBarrel: false,
  cabin: false,
  caravan: false,
  tent: false,
};

export const SightseeingListLoad: SightseeingListType = {
  sightseeingList: false,
  castleFortress: false,
  HistoricalBuildings: false,
  museum: false,
  church: false,
  mosque: false,
  synagogue: false,
  pedestrianZone: false,
  shoppingCentre: false,
  cinemaTheatre: false,
  zoo: false,
  stadium: false,
};

export const TransportListLoad: TransportListType = {
  bus: false,
  tram: false,
  taxi: false,
  train: false,
  ship: false,
};

export const ServiceListLoad: ServiceListType = {
  breadService: false,
  touristInfo: false,
  gas: false,
  hairdresser: false,
  pharmacy: false,
  postOffice: false,
  cashDispenser: false,
  LaundryService: false,
  BicycleRental: false,
  bicycleRepair: false,
  carRental: false,
  workshop: false,
  boatHire: false,
  slipway: false,
  petrolStation: false,
  carFree: false,
};
