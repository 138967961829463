import { makeStyles, Theme, createStyles, withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
export const homeStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      height: 510,
      margin: "64px 0px 24px",
      padding: "30px 0px",
      position: "relative",
      textAlign: "center",
      backgroundImage: `url("/assets/images/main.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    rectangle: {
      border: "1px solid black",
      position: "relative",
      width: 120,
      // "&::before": {
      //   display: "block",
      //   content: "''",
      //   paddingBottom: "100%",
      // },
    },
    searchMobile: {
      minHeight: 340,
      margin: "56px 0px 24px",
      padding: "30px 0px",
      position: "relative",
      textAlign: "center",
      backgroundImage: `url("/assets/images/main.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    mainTitle: {
      marginTop: 0,
      marginBottom: 40,
      fontSize: 44,
      color: "#fff",
    },
    mainTitleMobile: {
      marginTop: 0,
      marginBottom: 30,
      fontSize: 22,
      color: "#fff",
    },
    content: {
      marginBottom: 24,
    },

    switchContainer: {
      margin: "0px -8px",
      position: "absolute",
      right: 24,
      bottom: 18,
      display: "flex",
    },
    switchContainerMobile: {
      width: "100%",
      position: "absolute",
      left: 0,
      bottom: 24,
      display: "flex",
      justifyContent: "center",
    },
    switch: {
      margin: "0px 8px",
      paddingLeft: 14,
      backgroundColor: "#fff",
      borderRadius: 8,
      marginBottom: "5px",
      display: "flex",
      boxShadow: "0px 0px 0px 5px rgba(255, 255, 255, 0.3)",
      filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.5))",
      // width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "290px",
        margin: "5px auto",
        paddingLeft: "30px",
      },
    },
  })
);

export const BlueSwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#00713C",
      // color: "#0069B4",
    },
    "&$checked + $track": {
      backgroundColor: "#00713C",
      // backgroundColor: "#0069B4",
    },
  },
  checked: {},
  track: {},
})(Switch);
export const GreenSwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#D3D800",
    },
    "&$checked + $track": {
      backgroundColor: "#D3D800",
    },
  },
  checked: {},
  track: {},
})(Switch);
