import React, { useState, useContext } from "react";
import {
  Card,
  List,
  ListSubheader,
  AccordionSummary,
  Avatar,
  Typography,
  AccordionDetails,
  Grid,
  Collapse,
  CardContent,
  ListItem,
  ListItemText,
  FormControl,
  TextField,
  CardActions,
  Button,
  Accordion,
} from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import { Rating, Alert } from "@material-ui/lab";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import SendIcon from "@material-ui/icons/Send";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { rateCategoriesArray } from "./rateCategoriesArray";
import { useCreateCommentUser, useCommentsOfParkingAreal, ICreateCommentUserParams } from "rest";
import { AuthContext } from "context/Auth";
import { useAreaDetailState } from "context/AreaDetail";

import useStyles from "./style";

type Ratings = {
  lage: number;
  ruhe: number;
  aus: number;
  sauber: number;
  preis: number;
  aktiv: number;
};

const defaultRatings = {
  lage: 1,
  ruhe: 1,
  aus: 1,
  sauber: 1,
  preis: 1,
  aktiv: 1,
};

const CommentAreaImpl = ({ id, item }) => {
  const reducer = (accumulator, item) => {
    return (
      accumulator +
      (item.location + item.noiseLevel + item.facilities + item.cleanliness + item.price + item.activity) / 6
    );
  };

  const { comment, setComment, setTotalRating, expControl, setExpControl, totalComments, setTotalComments } =
    useAreaDetailState();

  const classes = useStyles();
  const { currentUser }: any = useContext(AuthContext);

  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [ratings, setRatings] = useState<Ratings>(defaultRatings);

  const { refetch: getCommentsOfParkingAreal } = useCommentsOfParkingAreal(
    { id },
    {
      enabled: false,
      onSuccess: (data) => {
        // setComment(data);
        const da = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        console.log(da);
        setComment(da);
        let arr: any = [];
        for (let i = 0; i < data.length; i++) {
          arr.push(false);
        }
        setExpControl(arr);
        const initialValue = 0;
        const total = data.reduce(reducer, initialValue) / data.length;
        setTotalRating(total);
        setTotalComments(data.length);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const { mutate: createCommentUser } = useCreateCommentUser({
    onSuccess: () => {
      setTitle("");
      setContent("");
      setChecked(true);
      setRatings(defaultRatings);

      setTimeout(() => {
        setChecked(false);
      }, 5000);

      getCommentsOfParkingAreal(id);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  function commentSend() {
    const send: ICreateCommentUserParams = {
      body: content,
      parkingArealId: item.id,
      noiseLevel: ratings.ruhe,
      location: ratings.lage,
      facilities: ratings.aus,
      cleanliness: ratings.sauber,
      price: ratings.preis,
      activity: ratings.aktiv,
    };

    createCommentUser(send);
  }

  // @ts-ignore
  const forceUpdate = React.useReducer(() => ({}), undefined)[1] as () => void;

  return (
    <>
      {comment?.length > 0 && (
        <Card style={{ marginBottom: 18, maxHeight: "500px", overflow: "auto" }}>
          <div style={{ overflow: "auto" }}>
            <List
              dense={true}
              subheader={<ListSubheader color="primary">{t("label_reviews")}</ListSubheader>}
            >
              {comment.map((data: any, index: any) => (
                <React.Fragment key={data.id}>
                  <div>
                    <Accordion
                      square={true}
                      elevation={0}
                      onChange={(e, value) => {
                        const arr = expControl;
                        arr[index] = value;
                        setExpControl(arr);
                        forceUpdate();
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Avatar alt="Remy Sharp" src={data.authorUserAvatar} />
                        <div className={classes.title} style={{ flexGrow: 1, marginLeft: "12px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography align="left" className={classes.heading}>
                              {data.authorUserName}
                              <span className={classes.date}> {`am ${data.date} um ${data.time}`}</span>
                            </Typography>
                            <Rating
                              value={
                                (data.location +
                                  data.noiseLevel +
                                  data.facilities +
                                  data.cleanliness +
                                  data.price +
                                  data.activity) /
                                6
                              }
                              readOnly
                              emptyIcon={<StarBorderIcon fontSize="inherit" />}
                            />
                          </div>
                          <Typography className={classes.secondaryHeading}>
                            {!expControl[index]
                              ? data.body.length > 180
                                ? data.body.substring(0, 180) + "..."
                                : data.body
                              : data.body}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        <div className={classes.upDown}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="flex-start"
                            style={{ textAlignLast: "center" }}
                          >
                            {rateCategoriesArray.map((category) => {
                              return (
                                <Grid key={category.name} item xs={6} sm={4} md={2} lg={2}>
                                  <div className={classes.helperLeft}>
                                    <Rating
                                      size="small"
                                      name={category.name}
                                      value={data[category.data]}
                                      readOnly
                                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                    />
                                    <Typography align="center" className={classes.thirdHeading}>
                                      {category.text}
                                    </Typography>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {comment.length - 1 !== index ? null : null}
                </React.Fragment>
              ))}
            </List>
          </div>
        </Card>
      )}
      <Collapse in={checked}>
        <Alert severity="success"> {t("label_review_submitted")}</Alert>
      </Collapse>

      {currentUser && (
        <Card style={{ marginBottom: 18 }}>
          <List
            dense={true}
            subheader={<ListSubheader color="primary">{t("label_add_review")}</ListSubheader>}
          ></List>
          <CardContent>
            <List dense={true} style={{ marginTop: "-45px" }}>
              <ListItem>
                <ListItemText primary={t("label_location")} />
                <Rating
                  name="lagex"
                  value={ratings.lage}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(e, value) => setRatings({ ...ratings, lage: value || 0 })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("label_calm")} />
                <Rating
                  name="ruhe"
                  value={ratings.ruhe}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(e, value) => setRatings({ ...ratings, ruhe: value || 0 })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("label_equipment")} />
                <Rating
                  name="aus"
                  value={ratings.aus}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(e, value) => setRatings({ ...ratings, aus: value || 0 })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("label_cleanliness")} />
                <Rating
                  name="sauber"
                  value={ratings.sauber}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(e, value) => setRatings({ ...ratings, sauber: value || 0 })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("label_value_for_money")} />
                <Rating
                  name="preis"
                  value={ratings.preis}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(e, value) => setRatings({ ...ratings, preis: value || 0 })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("label_activity_opportunities")} />
                <Rating
                  name="aktiv"
                  value={ratings.aktiv}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(e, value) => setRatings({ ...ratings, aktiv: value || 0 })}
                />
              </ListItem>
            </List>
            <FormControl fullWidth>
              <TextField
                label={t("label_comment")}
                multiline
                rows={4}
                variant="outlined"
                onChange={(event) => setContent(event.target.value)}
                value={content}
              />
            </FormControl>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              variant="contained"
              color="primary"
              onClick={commentSend}
              endIcon={<SendIcon />}
              disableElevation
            >
              {t("label_submit_review")}{" "}
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

const CommentArea = withTranslation()(CommentAreaImpl);
export default CommentArea;
