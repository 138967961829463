import React, { useState, createContext } from "react";

import { IBookingsContext } from "interfaces/context";
import { IBooking } from "interfaces/booking";

const BookingsContext = createContext<IBookingsContext>({} as IBookingsContext);

export const useBookings = () => {
  const context = React.useContext(BookingsContext);
  if (!context) {
    throw new Error(`useBookings must be used within a BookmarksProvider`);
  }
  return context;
};

export const BookingsProvider = ({ children }) => {
  const [zoom, setZoom] = useState(4);
  const [selectedPlace, setSelectedPlace] = useState<IBooking>();
  const [infoOpen, setInfoOpen] = useState(false);
  const [centerlng, setCenterlng] = useState(15.3547264);
  const [centerlat, setCenterlat] = useState(47.1681664);

  return (
    <BookingsContext.Provider
      value={{
        zoom,
        setZoom,
        selectedPlace,
        setSelectedPlace,
        infoOpen,
        setInfoOpen,
        centerlng,
        setCenterlng,
        centerlat,
        setCenterlat,
      }}
    >
      {children}
    </BookingsContext.Provider>
  );
};
