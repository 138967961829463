import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "@material-ui/core/Link";

import { AppBarMain } from "components/AppBar";
import { useCreateFairMail } from "rest";

import useStyles from "./style";

type FormInputTypes = {
  name: string;
  email: string;
};

const FairPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:959px)");

  const [info, setInfo] = useState(false);
  const [circular, setCircular] = useState(false);

  const { mutate: createFairMail } = useCreateFairMail({
    onSuccess: () => {
      setCircular(false);
      setInfo(true);
    },
    onError: () => {
      setCircular(false);
      console.log("Problem Occurred!");
    },
  });

  const { register, handleSubmit, errors } = useForm<FormInputTypes>();
  const onSubmit = (data) => {
    setCircular(true);
    createFairMail({ name: data.name, email: data.email });
  };

  return (
    <>
      <CssBaseline />
      <AppBarMain location={"fair"} />
      <div className={matches ? classes.search : classes.searchMobile}>
        <Container className={!info ? classes.info : classes.infoHidden}>
          <h1 className={matches ? classes.mainTitle : classes.mainTitleMobile}>{t("fair_secure_ticket")}</h1>
          <div className={classes.searchForm} style={{ paddingTop: "7px", paddingBottom: "3px" }}>
            <form
              className={matches ? classes.form : classes.formMobile}
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={0}>
                {circular ? (
                  <Grid>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12} md={5}>
                      <TextField
                        inputRef={register({ required: true, minLength: 5 })}
                        name="name"
                        label={t("label_search_firstname") + "&" + t("label_search_lastname")}
                        style={{ width: "-webkit-fill-available", marginLeft: "5px" }}
                        error={errors.name ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        inputRef={register({ required: true, pattern: /^\S+@\S+$/i })}
                        name="email"
                        error={errors.email ? true : false}
                        label={+t("label_search_email")}
                        style={{ width: "-webkit-fill-available", marginLeft: "5px" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} style={{ marginTop: "-7px", marginBottom: "-3px" }}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        disableElevation
                        fullWidth
                        type="submit"
                      >
                        {t("confirm")}
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </form>
          </div>
        </Container>
        {info && (
          <React.Fragment>
            <Grid container spacing={5} className={matches ? classes.mainTitle : classes.mainTitleMobile}>
              <Grid item xs={12} md={12}>
                <Container>
                  <div className={classes.searchForm}>
                    <div className={matches ? classes.form : classes.formMobile}>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          <Typography variant="h5" color="primary">
                            {t("fair_thanks_for_interest")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Container>
              </Grid>
              <Grid item xs={12} md={12}>
                <Container>
                  <div className={classes.searchForm}>
                    <div className={matches ? classes.form : classes.formMobile}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} md={12} style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                          <Typography variant="body1" color="primary" style={{ textAlign: "center" }}>
                            {t("fair_free_ticket_mail")}:&nbsp;
                            <Link
                              href="http://www.caravan-salon.de/gutschein"
                              color="textPrimary"
                              target="_blank"
                            >
                              www.caravan-salon.de/gutschein&nbsp;
                            </Link>
                            {t("fair_caravan_salon_contact")}:&nbsp;
                            <Link href="mailto: info@camp24checkin.de" color="textPrimary" target="_blank">
                              info@camp24checkin.de
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
                          <Typography variant="body1" color="primary" style={{ textAlign: "center" }}>
                            {t("fair_free_transport_message")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Container>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export const Fair = withTranslation()(FairPage);
