import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Button, Hidden, TextField, Typography } from "@material-ui/core";
import showRotateDeviceText from "components/showRotateDevicetext";
import { verifyPhone } from "rest/AdminRestClient";

import { useUserData } from "context";

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
    lineHeight: 1,
  },
  search: {
    height: 350,
    margin: "64px 0px 24px",
    position: "relative",
    textAlign: "center",
  },
  borderBottom: {
    borderBottom: "none",
  },
  borderTop: {
    borderTop: "solid",
  },
  buttonGreen: {
    color: "#fff",
    backgroundColor: "#00713C",
    "&:hover": {
      backgroundColor: "#00913C",
    },
  },
  buttonRed: {
    color: "#fff",
    backgroundColor: "crimson",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  button: {
    color: "#fff",
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#f57c00",
    },
  },
});

function ccyFormat(num: number) {
  return `${num?.toFixed(2)?.replace(/\./g, ",")}`;
}

export default function PreBookingBillTable(params: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getUserData } = useUserData();
  const { baseInfo } = getUserData;
  const [orientation, setOrientation] = React.useState<any>(false);
  const [disableButton, setDisableButton] = React.useState(false);

  useEffect(() => {
    if (typeof window.screen.orientation !== "undefined") {
      setOrientation(true);
    }
  }, [params]);

  const verify = (e: any) => {
    const obj: any = {};
    let error = false;
    Object.keys(baseInfo).forEach((key) => {
      // console.log(key);
      const value = baseInfo[key];
      // console.log(value);
      if (!value) {
        obj[key] = true;
        error = true;
      } else {
        obj[key] = false;
      }
    });
    // setBaseInfoErrors(obj);
    if (error) {
      // params.setPreBookingError({
      //   ...params.preBookingError,
      //   ...obj,
      // });
      params.dialogRef.current.scrollIntoView();
    } else {
      verifyPhone(baseInfo.phoneCode.toString() + baseInfo.phone, params.parkAreal._id)
        .then((result: any) => {
          if (result.data.ok) {
            params.setCodeInput(true);
          } else if (result.data.ok === false && result.data.preBooking === true) {
            params.setPreBookingExist(true);
          }
          setDisableButton(true);
        })
        .catch((error) => console.error(error));
    }
  };

  const verifyForce = (e: any) => {
    verifyPhone(baseInfo.phoneCode.toString() + baseInfo.phone, params.parkAreal._id, true)
      .then((result: any) => {
        params.setPreBookingExist(false);
        params.setCodeInput(true);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <CssBaseline />
      {params?.parkAreal?.name && (
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <>
            <Grid item>
              <Hidden mdUp>
                {orientation === true
                  ? window.screen.orientation.type.includes("landscape")
                    ? ""
                    : showRotateDeviceText(t)
                  : showRotateDeviceText(t)}
              </Hidden>
            </Grid>

            <Grid item>
              <Paper elevation={0}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{ fontSize: "x-large", fontWeight: "bold", borderBottom: "none" }}
                      >
                        {t("label_price_list") + ":"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{
                          fontSize: "large",
                          fontWeight: "bold",
                          borderBottom: "none",
                          color: "crimson",
                        }}
                      >
                        Unsere Berechnung basiert auf Ihren aktuellen Angaben.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{ fontSize: "large", borderBottom: "none" }}
                      >
                        {params.parkAreal.name +
                          " - " +
                          params.parkAreal.address.streetAndHouseNumber +
                          " - " +
                          params.parkAreal.address.areaCode +
                          " " +
                          params.parkAreal.address.area}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{ fontSize: "x-large", fontWeight: "bold", borderBottom: "none" }}
                      >
                        {`Platznummer: ${params.priceParams.spot} - Kennzeichen: ${params.priceParams.vehicleNumberPlate}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size={"small"} align="center" colSpan={7} style={{ fontSize: "large" }}>
                        {`Datum: ` +
                          ("0" + new Date().getDate()).slice(-2) +
                          "/" +
                          ("0" + (new Date().getMonth() + 1)).slice(-2) +
                          "/" +
                          new Date().getFullYear()}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"start"} style={{ borderTop: "solid" }}>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {t("date")}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"Leistung"}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"Anz."}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"Einzelpreis"}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"summe"}
                      </TableCell>
                      {/* <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("date")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("label_price_extra_person")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("child")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("dog")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("tourist_tax")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("parking_space")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("total")}
                    </TableCell> */}
                    </TableRow>
                    {params.priceParams.tablePrice.map((row: any, ind: any) => (
                      <TableRow key={ind}>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.date}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.count}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.perItem}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.gross}
                        </TableCell>
                        {/* <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(row.price)}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(row.sum)}
                      </TableCell> */}
                      </TableRow>
                    ))}
                    <TableRow style={{ borderBottom: "solid" }} />
                    {params.priceParams.tax.map((row: any, ind: any) => (
                      <>
                        <TableRow style={{ borderBottom: "ridge", lineHeight: "8px" }}>
                          <TableCell style={{ fontWeight: "bold" }} />
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {`${row.tax} ,0%`} {t("vat")}
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {row.diff}
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {row.net}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                    <TableRow style={{ borderBottom: "solid", borderTop: "ridge" }}>
                      <TableCell style={{ fontSize: "large", fontWeight: "bold" }}>
                        {t("total_amount")}:
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}></TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}></TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}></TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}>
                        {params.priceParams.gross}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {params.parkAreal.isPrePayment && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ paddingTop: "20px", textAlign: "center", color: "crimson" }}
                  >
                    Wir bitten Sie, uns eine Anzahlung in Höhe von 8,00 Euro zu leisten.
                  </Typography>
                )}
              </Paper>
              {/* <Grid item xs={4}>
                <Button
                  onClick={verify}
                  // disabled={params.codeInput || params.preBookingExist}
                  disabled={disableButton}
                  disableElevation
                  variant="contained"
                  className={classes.button}
                  // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                  fullWidth
                >
                  {t("sms_check")}
                </Button>
              </Grid>
              {params.codeInput && (
                <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      style={{ color: "#00713C", fontWeight: "bold", textAlign: "center" }}
                    >
                      {t("label_code_input")}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {params.preBookingExist && (
                <>
                  <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        style={{ color: "crimson", fontWeight: "bold", textAlign: "center" }}
                      >
                        {t("label_prebooking_exists")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} spacing={3} justifyContent="center" style={{ paddingTop: "20px" }}>
                    <Grid item xs={2}>
                      <Button
                        onClick={() => {
                          params.handleReset();
                          params.situation();
                          params.setPreBookingExist(false);
                        }}
                        // disabled={params.codeInput}
                        disableElevation
                        variant="contained"
                        className={classes.buttonRed}
                        // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                        fullWidth
                      >
                        {t("label_no")}
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        onClick={verifyForce}
                        // disabled={params.codeInput}
                        disableElevation
                        variant="contained"
                        className={classes.buttonGreen}
                        // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                        fullWidth
                      >
                        {t("label_yes")}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}

              {params.codeInput && (
                <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      id="code"
                      label="Code"
                      // onChange={(e) => handlePrePaymentForm(e)}
                      value={params.preBookingData.code}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )} */}
            </Grid>
          </>
        </Grid>
      )}
    </>
  );
}
