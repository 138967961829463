export const FittingsList = [
  "Strom",
  "Wasser",
  "WC",
  "Dusche",
  "Mietbäder",
  "WLAN",
  "Barrierefrei",
  "Hunde erlaubt",
  "Hundedusche",
  "Müllentsorgung",
  "Kassettenentsorgung",
  "Bodenentsorgung",
  "Entsorgungsautomat",
  "Wasser am Platz",
  "Abwasser am Platz",
  "TV/Anschluss",
  "Kinderspielplatz",
  "Kinderspielraum",
  "Kinder/Animation",
  "Wickelraum",
  "Aufenthaltsraum",
  "Spülmaschine",
  "Waschmaschine",
  "Wäschetrockner",
  "Trockner/Raum",
  "Wellness",
  "Sauna",
  "Massage",
  "Restaurant",
  "Kiosk",
  "telmarkt",
  "Bäckerei",
  "Kaffee",
  "Grillplatz",
  "Wintercamping",
  "Mehrsprachig",
];

export const ActivitiesList = [
  "Radfahren",
  "Mountainbiken",
  "Schwimmen",
  "Schwimmbad",
  "Wandern",
  "Angeln",
  "Paddeln",
  "Rafting",
  "Segeln",
  "Tauchen",
  "Golf",
  "Tennis",
  "Skateboard",
  "Volleyball",
  "Reiten",
  "Strand",
  "Federball",
  "Boccia",
  "Tischtennis",
  "Skifahren",
  "Langlauf",
  "Klettern",
  "Seilbahn",
  "Zahnradbahn",
  "Schifffahrt",
  "Paragleiten",
  "Ballonfahrt",
  "Safaritour",
  "FKK",
  "Therme",
];

export const SightseeingList = [
  "Aussichtspunkt",
  "Burg/Festung",
  "Historische Gebäude",
  "Museum",
  "Kirche",
  "Moschee",
  "Synagoge",
  "Fußgängerzone",
  "Einkaufszentrum",
  "Kino/Theater",
  "Zoo",
  "Stadion",
];

export const TransportList = ["Bus", "Strassenbahn", "Taxi", "Zug", "Schiff"];

export const ServiceList = [
  "Brötchenservice",
  "Tourist/Info",
  "Gas",
  "Friseur",
  "Apotheke",
  "Post",
  "Geldautomat",
  "Wäscheservice",
  "Fahrradverleih",
  "Fahrradreparatur",
  "Autoverleih",
  "Werkstatt",
  "Bootsverleih",
  "Slipanlage",
  "Tankstelle",
  "Autofrei",
];

export const RentList = ["Mobilheim", "Appartements", "Campingfass", "Hütte", "Wohnwagen", "Zelt"];

export const ActivitiesListEn = [
  "cycling",
  "mountainBiking",
  "swimmingPool",
  "swimming",
  "hiking",
  "fishing",
  "paddling",
  "rafting",
  "sailing",
  "diving",
  "golf",
  "tennis",
  "skateboarding",
  "volleyball",
  "horsebackRiding",
  "beach",
  "badminton",
  "boccia",
  "tableTennis",
  "skiing",
  "crossCountrySkiing",
  "climbing",
  "cableCar",
  "cogRailway",
  "boatTrip",
  "paragliding",
  "balloonRide",
  "safariTour",
  "naturist",
  "thermalBath",
];

export const FittingsListEn = [
  "electricity",
  "water",
  "WC",
  "shower",
  "rentaBathrooms",
  "WLAN",
  "barrierFree",
  "dogsAllowed",
  "dogShower",
  "wasteDisposal",
  "cassetteDisposal",
  "floorDisposal",
  "wasteDisposalMachine",
  "waterOnSite",
  "sewageOnSite",
  "TVconnection",
  "childrenPlayground",
  "childrenPlayroom",
  "childrenAnimation",
  "babyChangingRoom",
  "recreationRoom",
  "dishwasher",
  "washingMachine",
  "tumbleDryer",
  "dryerRoom",
  "wellness",
  "sauna",
  "massage",
  "restaurant",
  "kiosk",
  "telmarkt",
  "bakery",
  "coffee",
  "barbecue",
  "winterCamping",
  "multilingual",
];

export const RentListEn = ["mobilehome", "flats", "campingBarrel", "cabin", "caravan", "tent"];

export const SightseeingListEn = [
  "sightseeingList",
  "castleFortress",
  "HistoricalBuildings",
  "museum",
  "church",
  "mosque",
  "synagogue",
  "pedestrianZone",
  "shoppingCentre",
  "cinemaTheatre",
  "zoo",
  "stadium",
];

export const TransportListEn = ["bus", "tram", "taxi", "train", "ship"];

export const ServiceListEn = [
  "breadService",
  "touristInfo",
  "gas",
  "hairdresser",
  "pharmacy",
  "postOffice",
  "cashDispenser",
  "LaundryService",
  "BicycleRental",
  "bicycleRepair",
  "carRental",
  "workshop",
  "boatHire",
  "slipway",
  "petrolStation",
  "carFree",
];
