import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { season } from "./Data/dropdowns";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { formStore } from "./State/state";
import { IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { generalLoad } from "./State/models";
import { useTranslation } from "react-i18next";

const priceLoadStatus = {
  season: true,
};

export default function General() {
  const { t } = useTranslation();
  const [price, setPrice] = React.useState(generalLoad);
  const [status, setStatus] = React.useState(priceLoadStatus);
  const { updateGeneral } = formStore();

  useEffect(() => {
    setPrice(generalLoad);
    setStatus(priceLoadStatus);
  }, []);

  const minWidth = "120px";

  const reloadState = (id: any) => {
    const data = id.data as string;
    setStatus({ ...status, [data]: true });
    setPrice({ ...price, [data]: generalLoad[data] as string });
    updateGeneral(data, generalLoad[data] as string);
  };

  const BackButton = (data: any) => (
    <IconButton onClick={() => reloadState(data)}>
      <ReplayIcon />
    </IconButton>
  );

  const handleChange = (event: { target: { value: string | number; name: string } }) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    switch (event.target.name) {
      case "season":
        if (event.target.value !== "Ganzjährig") {
          setStatus({ ...status, season: false });
        }
        setPrice({ ...price, season: event.target.value.toString() });
        updateGeneral("season", event.target.value.toString());
        break;
      case "parkplaceCount":
        setPrice({ ...price, parkplaceCount: event.target.value.toString() });
        updateGeneral("parkplaceCount", event.target.value.toString());
        break;
      case "maximumLength":
        setPrice({ ...price, maximumLength: event.target.value.toString() });
        updateGeneral("maximumLength", event.target.value.toString());
        break;
      case "maximumStay":
        setPrice({ ...price, maximumStay: event.target.value.toString() });
        updateGeneral("maximumStay", event.target.value.toString());
        break;
      default:
        console.log("No such day exists!");
        break;
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Allgemeine Informationen:
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6} sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="parkplaceCount"
            label={t("number_of_parking_lots") + ":"}
            type={"number"}
            value={price.parkplaceCount}
            name="parkplaceCount"
            onChange={handleChange}
          ></TextField>
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="maximumLength"
            type={"number"}
            label={t("maximum_length") + ":"}
            value={price.maximumLength}
            name="maximumLength"
            onChange={handleChange}
          ></TextField>
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="maximumStay"
            label={t("maximum_stay") + ":"}
            type={"number"}
            value={price.maximumStay}
            name="maximumStay"
            onChange={handleChange}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="season"
            select={status.season}
            label={t("label_season") + ":"}
            value={price.season}
            name="season"
            onChange={handleChange}
            InputProps={status.season === false ? { endAdornment: <BackButton data="season" /> } : {}}
          >
            {season.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  );
}
