import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { useHistory } from "react-router";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

const PaymentStatus = (params: any) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [steps, setSteps] = React.useState([]);
  const history = useHistory();
  const handleClose = () => {
    if (params.status.status === "success") {
      history.push("#");
      params.setPaymentStatus({ isOpen: false, status: "success" });
    } else {
      if (params.pageUrl !== "") {
        window.location.href = params.pageUrl;
      } else {
        history.push("#");
        params.setPaymentStatus({ isOpen: false, status: "failed" });
      }
      console.log("error occurred while payment process. Please try again.");
    }
  };
  const { t } = useTranslation();
  function getSteps() {
    return [
      t("vehicle_length"),
      t("label_booking_select_spot"),
      t("pre_booking_form"),
      t("label_price_list_short"),
      t("label_payment"),
    ];
  }

  // const steps = getSteps();

  React.useEffect(() => {
    if (params.pp == "n") {
      setSteps([
        t("vehicle_length"),
        t("label_booking_select_spot"),
        t("pre_booking_form"),
        t("label_price_list_short"),
        t("label_payment"),
      ]);
    } else {
      setSteps([t("label_booking_select_spot"), t("label_booking_info"), t("label_payment")]);
    }
  }, [params.pp]);
  const isStepOptional = (step: number) => {
    return step === 5;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" spacing={4} style={{ paddingTop: "20px" }}>
        <Grid item xs={12} sm={4} md={6} lg={6}>
          <Stepper activeStep={4}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        style={{ paddingTop: "180px" }}
      >
        <Grid item xs={6}>
          <Typography variant="h5" component="p">
            {params.pp == "n"
              ? params.status.status === "success"
                ? t("prebooking_save_onprocess")
                : t("prebooking_payment_failed")
              : params.status.status === "success"
              ? t("booking_saved")
              : t("booking_payment_failed")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {params.status.status === "success" ? (
            <CheckCircleOutlinedIcon style={{ fontSize: 60, color: green[500] }} />
          ) : (
            <HighlightOffIcon style={{ fontSize: 60, color: "red" }} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" component="p">
            {params.status.status === "success"
              ? params.pp == "n"
                ? t("sms_verified_shortly")
                : t("booking_payment_success")
              : t("prebooking_try_payment_again")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" disableElevation color="primary" onClick={handleClose}>
            {params.status.status === "success" ? t("label_ok") : t("try_again")}
          </Button>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
};

export default PaymentStatus;
