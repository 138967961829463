import { FittingsList, FittingsListEn } from "../Data/tooltips";
import { Checkbox, Tooltip, Grid, Typography, Zoom } from "@mui/material";
import { FittingsImages } from "../../../helpers/images";
import { formStore } from "../State/state";
import { useTranslation } from "react-i18next";

export default function Fittings() {
  const { t } = useTranslation();
  const { updateFittings, fittings } = formStore();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateFittings(FittingsListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          {t("label_equipment")}:
        </Typography>
      </Grid>
      {FittingsList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={fittings[FittingsListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img src={FittingsImages["Gray/" + v + ".png"].default} height={48} width={48} alt="fittings" />
            }
            checkedIcon={
              <img
                src={FittingsImages["Green/" + v + ".png"].default}
                height={48}
                width={48}
                alt="fittings"
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
