import create, { State } from "zustand";
import {
  contactLoad,
  priceLoad,
  generalLoad,
  ActivitiesLoad,
  FittingsListLoad,
  RentListLoad,
  ServiceListLoad,
  SightseeingListLoad,
  TransportListLoad,
} from "./models";
import {
  price,
  contact,
  general,
  priceLoadStateType,
  ActivitiesListType,
  FittingsListType,
  RentListType,
  ServiceListType,
  SightseeingListType,
  TransportListType,
  FileDescriptionType,
} from "../Types/types";

interface Bears {
  bears: number;
  increasePopulation: () => void;
}

interface Todo {
  id: string;
  description: string;
  completed: boolean;
}

interface TodoState {
  todos: Todo[];
  contact: {};
  addContact: (description: string) => void;
  addTodo: (description: string) => void;
  removeTodo: (id: string) => void;
  toggleCompletedState: (id: string) => void;
}

export const useStore = create<Bears>((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
}));

export interface FormState {
  files: any[];
  fileDescription: FileDescriptionType[];
  contact: contact;
  price: price;
  general: general;
  activities: ActivitiesListType;
  fittings: FittingsListType;
  rent: RentListType;
  service: ServiceListType;
  sightseeing: SightseeingListType;
  transport: TransportListType;
  setFiles: (file: any) => void;
  addFiles: (file: any) => void;
  removeFile: (id: string) => void;
  setFileDescription: (description: string, id: string, order: string) => void;
  updateFileDescription: (description: string, id: string) => void;
  updateFileOrder: (order: string, id: string) => void;
  removeFileDescription: (id: string) => void;
  updateRent: (key: string, value: boolean) => void;
  updateService: (key: string, value: boolean) => void;
  updateSightseeing: (key: string, value: boolean) => void;
  updateTransport: (key: string, value: boolean) => void;
  updateFittings: (key: string, value: boolean) => void;
  updateActivities: (key: string, value: boolean) => void;
  updateContact: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  updatePrice: (key: string, value: string) => void;
  updateGeneral: (key: string, value: string) => void;
}

export const formStore = create<FormState>((set) => ({
  files: [],
  fileDescription: [],
  contact: contactLoad as contact,
  price: priceLoad as price,
  general: generalLoad as general,
  activities: ActivitiesLoad as ActivitiesListType,
  fittings: FittingsListLoad as FittingsListType,
  rent: RentListLoad as RentListType,
  service: ServiceListLoad as ServiceListType,
  sightseeing: SightseeingListLoad as SightseeingListType,
  transport: TransportListLoad as TransportListType,
  setFileDescription: (description: string, id: string, order: string) => {
    set((state) => ({
      fileDescription: [
        ...state.fileDescription,
        {
          id: id,
          order: order,
          description,
        } as FileDescriptionType,
      ],
    }));
  },
  updateFileDescription: (description: string, id: string) => {
    set((state) => ({
      fileDescription: state.fileDescription.map((file) =>
        file.id === id ? ({ ...file, description: description } as FileDescriptionType) : file
      ),
    }));
  },
  updateFileOrder: (order: string, id: string) => {
    set((state) => ({
      fileDescription: state.fileDescription.map((file) =>
        file.id === id ? ({ ...file, order: order } as FileDescriptionType) : file
      ),
    }));
  },
  removeFileDescription: (id: string) => {
    let i = 0;
    set((state) => ({
      fileDescription: state.fileDescription.filter((fileDescription) => {
        if (fileDescription.id !== id) {
          i = i + 1;
          return (fileDescription.order = i.toString());
        }
      }),
    }));
  },
  addFiles: (file: any) => {
    set((state) => ({
      files: [...state.files, ...file],
    }));
  },
  setFiles: (file: any) => {
    set(() => ({
      files: file,
    }));
  },
  removeFile: (id: string) => {
    set((state) => ({
      files: state.files.filter((file) => file.name !== id),
    }));
  },
  updateRent: (key: string, value: boolean) => {
    set((state) => ({
      rent: {
        ...state.rent,
        ...{ [key]: value },
      } as RentListType,
    }));
  },
  updateService: (key: string, value: boolean) => {
    set((state) => ({
      service: {
        ...state.service,
        ...{ [key]: value },
      } as ServiceListType,
    }));
  },
  updateSightseeing: (key: string, value: boolean) => {
    set((state) => ({
      sightseeing: {
        ...state.sightseeing,
        ...{ [key]: value },
      } as SightseeingListType,
    }));
  },
  updateTransport: (key: string, value: boolean) => {
    set((state) => ({
      transport: {
        ...state.transport,
        ...{ [key]: value },
      } as TransportListType,
    }));
  },
  updateFittings: (key: string, value: boolean) => {
    set((state) => ({
      fittings: {
        ...state.fittings,
        ...{ [key]: value },
      } as FittingsListType,
    }));
  },
  updateActivities: (key: string, value: boolean) => {
    set((state) => ({
      activities: {
        ...state.activities,
        ...{ [key]: value },
      } as ActivitiesListType,
    }));
  },
  updateGeneral: (key: string, value: string) => {
    set((state) => ({
      general: {
        ...state.general,
        ...{ [key]: value },
      } as general,
    }));
  },
  updatePrice: (key: string, value: string) => {
    set((state) => ({
      price: {
        ...state.price,
        ...{ [key]: value },
      } as price,
    }));
  },
  updateContact: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    set((state) => ({
      contact: {
        ...state.contact,
        ...{ [event.target.id]: event.target.value },
      } as contact,
    }));
  },
}));

export const useStoreX = create<TodoState>((set) => ({
  // initial state
  todos: [],
  contact: {},
  // methods for manipulating state
  addTodo: (description: string) => {
    set((state) => ({
      todos: [
        ...state.todos,
        {
          // id: uuidv4(),
          description,
          completed: false,
        } as Todo,
      ],
    }));
  },
  addContact: (description: string) => {
    set((state) => ({
      contact: {
        // id: uuidv4(),
        description,
        completed: false,
      },
    }));
  },
  removeTodo: (id) => {
    set((state) => ({
      todos: state.todos.filter((todo) => todo.id !== id),
    }));
  },
  toggleCompletedState: (id) => {
    set((state) => ({
      todos: state.todos.map((todo) =>
        todo.id === id ? ({ ...todo, completed: !todo.completed } as Todo) : todo
      ),
    }));
  },
}));
