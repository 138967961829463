import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { formStore, FormState } from "./State/state";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export const ImageCard = () => {
  const { t } = useTranslation();
  const files: FormState["files"] = formStore((state) => state.files);
  const fileDescription: FormState["fileDescription"] = formStore((state) => state.fileDescription);
  const updateFileDescription: FormState["updateFileDescription"] = formStore(
    (state) => state.updateFileDescription
  );

  const updateFileOrder: FormState["updateFileOrder"] = formStore((state) => state.updateFileOrder);
  const removeFileDescription: FormState["removeFileDescription"] = formStore(
    (state) => state.removeFileDescription
  );
  const removeFile: FormState["removeFile"] = formStore((state) => state.removeFile);

  const handleChangeState = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: string
  ) => {
    updateFileDescription(event.target.value, id);
  };

  const handleDelete = (id: string) => {
    removeFileDescription(id);
    removeFile(id);
  };
  const handleUpdateOrder = (e: any, id: string) => {
    updateFileOrder(e.target.value, id);
  };

  useEffect(() => {}, [fileDescription]);

  const findValue = (id: string) => {
    const found: any = fileDescription.filter((fileDescription) => fileDescription.id === id)[0];
    return found !== undefined ? found.order : "";
  };

  const findDescription = (id: string) => {
    const found: any = fileDescription.filter((fileDescription) => fileDescription.id === id)[0];
    return found !== undefined ? found.description : "";
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {files.map((file: any, v: number) => (
          <Grid item xs={12} md={6} xl={4} key={file.name + v}>
            <Card sx={{ maxWidth: 600 }}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} style={{ padding: "20px 10px 20px 15px" }}>
                  <CardMedia
                    component="img"
                    width="120"
                    height="150"
                    src={file.preview}
                    alt="green iguana"
                    style={{ borderRadius: "7px" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={12} md={12} style={{ paddingBottom: "5px" }}>
                        {/* <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                          color="secondary"
                        >
                          Bildbeschreibung
                        </Typography> */}
                        <TextField
                          multiline
                          placeholder={t("picture_writing")}
                          maxRows={4}
                          minRows={4}
                          fullWidth={true}
                          size="small"
                          value={findDescription(file.name)}
                          onBlur={(e) => handleChangeState(e, file.name)}
                        />
                      </Grid>
                      <Grid item xs={5} md={5}>
                        <Typography
                          style={{ paddingTop: "8px", paddingRight: "5px" }}
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                          color="secondary"
                        >
                          {t("series")}:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Select
                          labelId="simple-select-label"
                          id="simple-select"
                          fullWidth={true}
                          size="small"
                          value={findValue(file.name)}
                          //   label="Reihenfolge"
                          //   onChange={(e) => handleUpdateOrder(e, file.name)}
                        >
                          {fileDescription.map((fileDescription: any, v: number) => (
                            <MenuItem
                              value={fileDescription.order.toString()}
                              key={fileDescription.order + v}
                            >
                              {fileDescription.order}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          onClick={() => handleDelete(file.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};
