import React, { useEffect } from "react";
import { Circle, GoogleMap, useJsApiLoader, useGoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Button, Card } from "@material-ui/core";
import { useHomeState } from "context/Home";
import { CampCard } from "./CampCard";
import { SafetyCheck } from "@mui/icons-material";

const options = {
  zoomControlOptions: {
    position: 7.0, //google.maps.ControlPosition.RIGHT_CENTER,
    // ...otherOptions
  },
};

export function SearchMap({ center, mapGetData }) {
  const { moveBox, setMoveBox, infoOpen, setInfoOpen, selectedPlace, setSelectedPlace, getParkingData } =
    useHomeState();
  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: "YOUR_API_KEY", // ,
  //   // ...otherOptions
  // });
  const [zoom, setZoom] = React.useState(10);
  const [map, setMap] = React.useState<any>();
  const [trigger, setTrigger] = React.useState(false);
  // const [moveBox, setMoveBox] = React.useState(true);
  const [markerMap, setMarkerMap] = React.useState({});
  const [circleCenter, setCircleCenter] = React.useState(center);
  // wrapping to a function is useful in case you want to access `window.google`
  // to eg. setup options or create latLng object, it won't be available otherwise
  // feel free to render directly if you don't need that
  const onLoaded = React.useCallback(function onLoad(mapInstance) {
    // console.log(mapInstance);
    // do something with map Instance
    // setMap(mapInstance);
    // setCircleCenter(mapInstance.getCenter());
    // @ts-ignore
    // var mapDiv = document.getElementByClass("gm-style").getElementsByTagName("div")[0];
    // console.log(mapDiv);
    // console.log(mapInstance);
  }, []);

  useEffect(() => {
    if (map) {
      let doc = document.getElementsByClassName("gm-style")[0];
      let searchBut = document.getElementById("searchButton");
      doc !== undefined && doc.appendChild(searchBut !== null ? searchBut : document.createElement("div"));
    }
  });

  const cırcleOptions = {
    strokeColor: "#fff",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#fff",
    fillOpacity: 0.1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 34000,
    zIndex: 1,
  };

  enum Icon {
    BC = "/assets/images/marker/markerBookableCamp.png",
    BP = "/assets/images/marker/markerBookablePark.png",
    C = "/assets/images/marker/markerCamp.png",
    P = "/assets/images/marker/markerPark.png",
  }

  const icon = (item) => {
    if (item.park) {
      if (item.bookable) {
        return iconBookablePark;
      } else {
        return iconPark;
      }
    }
    if (item.camp) {
      if (item.bookable) {
        return iconBookableCamp;
      } else {
        return iconCamp;
      }
    }
  };

  const iconBookableCamp = {
    url: "/assets/images/marker/markerBookableCamp.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };

  const iconBookablePark = {
    url: "/assets/images/marker/markerBookablePark.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };
  const iconCamp = {
    url: "/assets/images/marker/markerCamp.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };
  const iconPark = {
    url: "/assets/images/marker/markerPark.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };

  const mapContainerStyle = {
    width: "98vw",
    height: "68vh",
  };
  const markerLoadHandler = (marker: any, item: any) => {
    // console.log(marker);
    return setMarkerMap((prevState) => {
      return { ...prevState, [item.id]: marker };
    });
  };

  const markerClickHandler = (event: any, item: any) => {
    setSelectedPlace(item);

    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    if (zoom < 13) {
      setZoom(10);
    }
  };

  const openMapAndCard = (event: any, item: any) => {
    // setMapOpen(true);
    // setMoveBox(true);
    setTimeout(function () {
      setSelectedPlace(item);
      setInfoOpen(true);
      setZoom(10);
    }, 1250);
  };
  return (
    <Grid container spacing={0} direction="column" alignItems="center">
      <Button
        variant="contained"
        color="primary"
        id="searchButton"
        // disableElevation
        style={{
          position: "absolute",
          zIndex: 1,
          marginTop: "10px",
          backgroundColor: "#BCCF00",
          color: "#004121",
          left: "45%",
        }}
        onClick={() => mapGetData(map)}
      >
        In diesem Breich suchen
      </Button>
      <Card elevation={2} style={{ marginTop: "2px" }}>
        <GoogleMap
          options={options}
          zoom={zoom}
          center={center}
          // onLoad={onLoaded}
          // onLoad={onLoad}
          onLoad={(map) => setMap(map)}
          mapContainerStyle={mapContainerStyle}
          // Save the current center position in state
          onCenterChanged={() => {
            moveBox == true && map && setCircleCenter(map.getCenter());
            //  centerChanged(mapRef);
          }}
          // Save the user's map click position
          //@ts-ignore
          onClick={(e) => {
            if (e.latLng) {
              console.log("clickedLatLng: ", e.latLng.toJSON());
            }
          }}
          //  center={{ lat: centerlat, lng: centerlng }}
          //  onDragStart={() => setMoveBox(false)}
          onDragEnd={() => {
            setTrigger(!trigger);
            // setCircleCenter(map.getCenter());
            // mapGetData(mapRef);
          }}
        >
          <Circle
            //@ts-ignore
            // center={
            //   moveBox === false
            //     ? //@ts-ignore
            //       { lat: mapRef.getCenter().toJSON().lat, lng: mapRef.getCenter().toJSON().lng }
            //     : { lat: centerlat, lng: centerlng }
            // }
            center={circleCenter}
            // required
            options={cırcleOptions}
            radius={1000}
          />
          <Marker icon="/assets/images/marker/marker4.png" position={circleCenter} />
          {getParkingData &&
            getParkingData.map((item: any, index: number) => (
              <Marker
                position={{ lat: parseFloat(item.location.lat), lng: parseFloat(item.location.lon) }}
                onLoad={(marker) => markerLoadHandler(marker, item)}
                onClick={(event) => {
                  markerClickHandler(event, item);
                  setMoveBox(false);
                }}
                icon={icon(item)}
                key={item.id}
              />
            ))}
          {infoOpen && selectedPlace && (
            <InfoWindow
              //@ts-ignore
              anchor={markerMap[selectedPlace.id]}
              onCloseClick={() => {
                setInfoOpen(false);
                setMoveBox(true);
              }}
            >
              <div>
                <CampCard item={selectedPlace} openMapAndCard={openMapAndCard} />
              </div>
            </InfoWindow>
          )}
          {
            // ...Your map components
          }
        </GoogleMap>
      </Card>
    </Grid>
  );
}
