import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { useProfile } from "context";
import {
  Card,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
  People as PeopleIcon,
  DirectionsBus as DirectionsBusIcon,
  Bookmark as BookmarkIcon,
  EventAvailable as EventAvailableIcon,
  DateRange as DateRangeIcon,
  Chat as ChatIcon,
  LockOpen as LockOpenIcon,
  DeleteForever as DeleteForeverIcon,
} from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { FirebaseAuth } from "services/firebase/Firebase";
import { StornierenIcon, PdfIcon } from "components";
import { AuthContext } from "context/Auth";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContents: {
      width: "-webkit-fill-available",
      paddingBottom: "11px",
    },
  })
);

interface IFormInput {
  currentPass: string;
  newPass: string;
  newPass2: string;
  email: string;
  pass: string;
  confirm: string;
}

export type SiderbarProps = {
  user: any;
  fade: any;
};

export const Sidebar: React.FC<SiderbarProps> = ({ user, fade }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser }: any = useContext(AuthContext);
  const { handleSubmit, register, errors, getValues, setError } = useForm<IFormInput>();

  // const [selectedIndex, setSelectedIndex] = useState(1);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const [showChangePassDialog, setShowChangePassDialog] = useState(false);
  const [showAccountDeleteDialog, setShowAccountDeleteDialog] = useState(false);
  const { selectedIndex, setSelectedIndex } = useProfile();
  const signOut = () => {
    return FirebaseAuth.signOut()
      .then((_) => history.push("/"))
      .catch((reason) => console.log(reason));
  };

  const onSubmitToPasswordChangeForm: SubmitHandler<IFormInput> = (data) => {
    FirebaseAuth.signInWithEmailAndPassword(currentUser.email, data.currentPass)
      .then((_) => {
        if (FirebaseAuth.currentUser !== null) {
          FirebaseAuth.currentUser
            .updatePassword(data.newPass)
            .then(() => {
              setShowChangePassDialog(false);
              setShowSuccessAlert(true);
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 5000);
            })
            .catch((error) => {
              console.log(error);
              setShowChangePassDialog(false);
              setShowErrorAlert(true);
              setTimeout(() => {
                setShowErrorAlert(false);
              }, 5000);
            });
        }
      })
      .catch((reason) => {
        if (reason.code === "auth/wrong-password") {
          setError("currentPass", {
            types: {
              required: t("this_is_required") + "",
            },
          });
        }
        console.log(reason);
      });
  };

  const onSubmitToAccountDeleteForm: SubmitHandler<IFormInput> = (data) => {
    FirebaseAuth.signInWithEmailAndPassword(data.email, data.pass)
      .then((_) => {
        if (FirebaseAuth.currentUser !== null && data.confirm === "LÖSCHEN") {
          FirebaseAuth.currentUser
            .delete()
            .then(() => {
              setShowChangePassDialog(false);
              setShowSuccessAlert(true);
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 5000);
            })
            .catch((error) => {
              console.log(error);
              setShowChangePassDialog(false);
              setShowErrorAlert(true);
              setTimeout(() => {
                setShowErrorAlert(false);
              }, 5000);
            });
        }
      })
      .catch((reason) => {
        if (reason.code === "auth/wrong-password") {
          setError("pass", {
            types: {
              required: t("this_is_required") + "",
            },
          });
        }
        if (reason.code === "auth/wrong-email") {
          setError("email", {
            types: {
              required: t("this_is_required") + "",
            },
          });
        }
        console.log(reason);
      });
  };

  return (
    <>
      <Snackbar open={showSuccessAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="success">{t("your_password_send_successfully")}</Alert>
      </Snackbar>
      <Snackbar open={showErrorAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="error">{t("your_password_failed_send")}</Alert>
      </Snackbar>

      {/* Password Change Dialog */}
      <Dialog
        open={showChangePassDialog}
        onClose={() => setShowChangePassDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("change_password")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmitToPasswordChangeForm)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  name="currentPass"
                  variant="outlined"
                  label={t("current_password")}
                  required
                  type="password"
                  error={errors.currentPass ? true : false}
                  inputRef={register({ required: true, pattern: /(?=.*[0-9a-zA-Z]).{6,}/i })}
                  className={classes.formContents}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  name="newPass"
                  variant="outlined"
                  label={t("new_password")}
                  type="password"
                  required
                  error={errors.newPass ? true : false}
                  inputRef={register({ required: true, pattern: /(?=.*[0-9a-zA-Z]).{6,}/i })}
                  className={classes.formContents}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  name="newPass2"
                  variant="outlined"
                  label={t("new_password")}
                  type="password"
                  required
                  error={errors.newPass2 ? true : false}
                  inputRef={register({
                    required: true,
                    pattern: /(?=.*[0-9a-zA-Z]).{6,}/i,
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { newPass } = getValues();
                        return newPass === value;
                      },
                    },
                  })}
                  className={classes.formContents}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowChangePassDialog(false)} color="primary">
              {t("label_cancel")}
            </Button>
            <Button variant="contained" color="primary" disableElevation type="submit">
              {t("button_save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* End Password Change Dialog */}

      {/* Account Delete Dialog */}
      <Dialog
        open={showAccountDeleteDialog}
        onClose={() => setShowAccountDeleteDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("permanently_delete_account")}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmitToAccountDeleteForm)}>
          <DialogContentText style={{ marginLeft: "25px" }}>{t("press_delete_to_confirm")}</DialogContentText>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  name="email"
                  variant="outlined"
                  label={t("label_email")}
                  required
                  error={!!errors.email}
                  inputRef={register({ required: true, pattern: /^\S+@\S+$/i })}
                  className={classes.formContents}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  name="pass"
                  variant="outlined"
                  label={t("label_password")}
                  type="password"
                  required
                  error={!!errors.pass}
                  inputRef={register({ required: true, pattern: /(?=.*[0-9a-zA-Z]).{6,}/i })}
                  className={classes.formContents}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  name="confirm"
                  variant="outlined"
                  label={t("confirm")}
                  required
                  error={!!errors.confirm}
                  inputRef={register({
                    required: true,
                    pattern: /[A-Z]/i,
                    validate: {
                      matchesPreviousPassword: (value) => "LÖSCHEN" === value,
                    },
                  })}
                  className={classes.formContents}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAccountDeleteDialog(false)} color="primary">
              {t("label_cancel")}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "red", color: "white" }}
              disableElevation
              type="submit"
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* End Account Delete Dialog */}

      {user != null && fade && (
        <Card style={{ marginBottom: 18 }}>
          <List
            subheader={
              <ListSubheader color="primary">
                <b>{t("welcome")}</b>
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt={user.person.firstName + " " + user.person.lastName}
                  src={user.profileImage.url}
                />
              </ListItemAvatar>
              <ListItemText primary={user.person.firstName + " " + user.person.lastName} />
            </ListItem>
            <ListItem button onClick={() => setShowChangePassDialog(true)}>
              <ListItemIcon>
                <LockOpenIcon />
              </ListItemIcon>
              <ListItemText primary={t("change_password")} />
            </ListItem>
            <ListItem button onClick={() => setShowAccountDeleteDialog(true)}>
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
              <ListItemText primary={t("delete_account")} />
            </ListItem>
            <ListItem button onClick={signOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={t("hover_logout")} />
            </ListItem>
          </List>
        </Card>
      )}

      <Card>
        <List>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/profile", state: { user } }}
            onClick={() => setSelectedIndex(1)}
            selected={selectedIndex === 1}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t("profile")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/travellers", state: { user } }}
            onClick={() => setSelectedIndex(2)}
            selected={selectedIndex === 2}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={t("traveler")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/vehicles", state: { user } }}
            onClick={() => setSelectedIndex(3)}
            selected={selectedIndex === 3}
          >
            <ListItemIcon>
              <DirectionsBusIcon />
            </ListItemIcon>
            <ListItemText primary={t("vehicles")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/bookmarks", state: { user } }}
            onClick={() => setSelectedIndex(4)}
            selected={selectedIndex === 4}
          >
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary={t("favourites")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/bookings", state: { user } }}
            onClick={() => setSelectedIndex(5)}
            selected={selectedIndex === 5}
          >
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText primary={t("bookings")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/bookings-past", state: { user } }}
            onClick={() => setSelectedIndex(6)}
            selected={selectedIndex === 6}
          >
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary={t("history")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/reviews", state: { user } }}
            onClick={() => setSelectedIndex(7)}
            selected={selectedIndex === 7}
          >
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary={t("label_reviews")} />
          </ListItem>
          {/* <ListItem
            button
            component={Link}
            to={{ pathname: "/user/stornieren", state: { user } }}
            onClick={() => setSelectedIndex(9)}
            selected={selectedIndex === 9}
          >
            <ListItemIcon>
              <StornierenIcon />
            </ListItemIcon>
            <ListItemText primary={t("sidebar_cancel")} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={{ pathname: "/user/rechnungen", state: { user } }}
            onClick={() => setSelectedIndex(10)}
            selected={selectedIndex === 10}
          >
            <ListItemIcon>
              <PdfIcon />
            </ListItemIcon>
            <ListItemText primary={t("bills")} />
          </ListItem> */}
        </List>
      </Card>
    </>
  );
};
