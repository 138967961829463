import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function MiddleHint() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("hint_click_icons_to_apply_pitch")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" align="left" color="secondary">
          {t("hint_selected_icons_will_appear")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" align="left" color="secondary">
          {t("hint_camp_display")}
        </Typography>
      </Grid>
    </Grid>
  );
}
