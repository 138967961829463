import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { CaravanSizeComponent } from "./CaravanSizeComponent";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

const VehicleSelect = (params: any) => {
  const [color, setColor] = useState({
    S: "#DFF2FD",
    M: "#BCCDEB",
    L: "#FFF9C7",
    XL: "#FFE67F",
    XXL: "#F9D3E6",
  });
  //   const [selectedSize, setSelectedSize] = useState(0);
  const vehicleSizes = [
    {
      label: "S",
      description: "6 Meter",
      background: "#DCFFFF",
      price: " 9,00 €",
      priceValue: 9.0,
    },
    {
      label: "M",
      description: "8 Meter",
      background: "#B4DCFF",
      price: " 9,00 €",
      priceValue: 9.5,
    },
    {
      label: "L",
      description: "9 Meter",
      background: "#FFFFB4",
      price: "9,50 €",
      priceValue: 10,
    },
    {
      label: "XL",
      description: "10 Meter",
      background: "#FAE678",
      price: "10,00 €",
      priceValue: 11,
    },
    {
      label: "XXL",
      description: "12 Meter",
      background: "#FADCFA",
      price: "10,00 €",
      priceValue: 12,
    },
  ];
  const tentSizes = [
    {
      label: "M",
      description: "bis 3 Personen",
      background: "#B4DCFF",
      price: " 9,00 €",
      priceValue: 9.5,
    },
    {
      label: "L",
      description: "ab 3 Personen",
      background: "#FFFFB4",
      price: "9,50 €",
      priceValue: 10,
    },
  ];
  const cabinSizes = [
    {
      label: "M",
      description: "bis 4 Personen",
      background: "#B4DCFF",
      price: " 9,00 €",
      priceValue: 9.5,
    },
    {
      label: "L",
      description: "bis 6 Personen",
      background: "#FFFFB4",
      price: "9,50 €",
      priceValue: 10,
    },
  ];

  const setVehicle = (size: string) => {
    params.setVehicleType(size);
    params.setActiveSize("vehicle");
  };

  const setTent = (size: string) => {
    params.setVehicleType(size);
    params.setActiveSize("tent");
  };

  const setCabin = (size: string) => {
    params.setVehicleType(size);
    params.setActiveSize("cabin");
  };

  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      //   style={{ height: "1500px" }}
    >
      <Grid container justifyContent="center">
        <Grid item>
          <Grid item>
            {params.parkAreal._id == "u31r3jyzbygx" ? (
              <h2>{t("vehicle_length_stellplatz")}</h2>
            ) : (
              <h2>{t("vehicle_length")}</h2>
            )}
          </Grid>
          <Grid id={"StepOne"} style={{ marginTop: "20px", marginBottom: "40px" }} item>
            {params.parkAreal.types.B && (
              <div id={"small"} onClick={() => setVehicle("B")}>
                <CaravanSizeComponent
                  selected={params.vehicleType === "B" && params.activeSize === "vehicle"}
                  caravanSize={vehicleSizes[0]}
                  color={color["S"]}
                />
              </div>
            )}
            {params.parkAreal.types.C && (
              <div id={"medium"} onClick={() => setVehicle("C")}>
                <CaravanSizeComponent
                  selected={params.vehicleType === "C" && params.activeSize === "vehicle"}
                  caravanSize={vehicleSizes[1]}
                  color={color["M"]}
                />
              </div>
            )}

            {params.parkAreal.types.D && (
              <div id={"large"} onClick={() => setVehicle("D")}>
                <CaravanSizeComponent
                  selected={params.vehicleType === "D" && params.activeSize === "vehicle"}
                  caravanSize={vehicleSizes[2]}
                  color={color["L"]}
                />
              </div>
            )}
            {params.parkAreal.types.E && (
              <div id={"x_large"} onClick={() => setVehicle("E")}>
                <CaravanSizeComponent
                  selected={params.vehicleType === "E" && params.activeSize === "vehicle"}
                  caravanSize={vehicleSizes[3]}
                  color={color["XL"]}
                />
              </div>
            )}
            {params.parkAreal.types.F && (
              <div id={"xx_large"} onClick={() => setVehicle("F")}>
                <CaravanSizeComponent
                  selected={params.vehicleType === "F" && params.activeSize === "vehicle"}
                  caravanSize={vehicleSizes[4]}
                  color={color["XXL"]}
                />
              </div>
            )}
          </Grid>
          {params?.parkAreal?.hasOwnProperty("tent") && params?.parkAreal?.tent && (
            <>
              <Grid item>
                {params.parkAreal._id == "u31r3jyzbygx" ? (
                  <h2>{t("tent_size_zeltplatz")}</h2>
                ) : (
                  <h2>{t("tent_size")}:</h2>
                )}
              </Grid>
              <Grid id={"StepOne"} style={{ marginTop: "20px", marginBottom: "40px" }} item>
                <div id={"small"} onClick={() => setTent("P")}>
                  <CaravanSizeComponent
                    selected={params.vehicleType === "P" && params.activeSize === "tent"}
                    caravanSize={tentSizes[0]}
                    color={color["M"]}
                  />
                </div>
                <div id={"medium"} onClick={() => setTent("Q")}>
                  <CaravanSizeComponent
                    selected={params.vehicleType === "Q" && params.activeSize === "tent"}
                    caravanSize={tentSizes[1]}
                    color={color["L"]}
                  />
                </div>
              </Grid>
            </>
          )}
          {params?.parkAreal?.hasOwnProperty("cabin") && params?.parkAreal?.cabin && (
            <>
              <Grid item>
                <h2>{t("cabin_size")}:</h2>
              </Grid>
              <Grid id={"StepOne"} style={{ marginTop: "20px" }} item>
                <div id={"small"} onClick={() => setCabin("V")}>
                  <CaravanSizeComponent
                    selected={params.vehicleType === "V" && params.activeSize === "cabin"}
                    caravanSize={cabinSizes[0]}
                    color={color["M"]}
                  />
                </div>
                <div id={"medium"} onClick={() => setCabin("W")}>
                  <CaravanSizeComponent
                    selected={params.vehicleType === "W" && params.activeSize === "cabin"}
                    caravanSize={cabinSizes[1]}
                    color={color["L"]}
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleSelect;
