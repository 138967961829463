import { SightseeingList, SightseeingListEn } from "../Data/tooltips";
import { Checkbox, Tooltip, Zoom, Grid, Typography } from "@mui/material";
import { SightseeingImages } from "../../../helpers/images";
import { formStore } from "../State/state";
import { useTranslation } from "react-i18next";

export default function Sightseeing() {
  const { t } = useTranslation();
  const { updateSightseeing, sightseeing } = formStore();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateSightseeing(SightseeingListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          {t("sightseeing_features")}:
        </Typography>
      </Grid>
      {SightseeingList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={sightseeing[SightseeingListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img
                src={SightseeingImages["Gray/" + v + ".png"].default}
                height={48}
                width={48}
                alt="sightseeing"
              />
            }
            checkedIcon={
              <img
                src={SightseeingImages["Green/" + v + ".png"].default}
                height={48}
                width={48}
                alt="sightseeing"
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
