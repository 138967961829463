import React from "react";
import { withTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { AppBarMain } from "components/AppBar";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: 80,
      textAlign: "justify",
    },
  })
);

const AgbPage: React.FC = () => {
  const classes = useStyles();
  const date = new Date();

  return (
    <>
      <CssBaseline />
      <AppBarMain location={"privacy"} />
      <Container maxWidth="lg" className={classes.container}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" color="primary" align="center">
              Allgemeine Geschäftsbedingungen der Camp24 Checkin GmbH
            </Typography>
            <h3>1. Geltungsbereich</h3>
            <p>
              Die Gesellschaft Camp24 Checkin GmbH mit Sitz in D-78239 Rielasingen-Worblingen, Singener Str.
              25 (nachfolgend „Vermittler“ genannt) ist Betreiber des Buchungsportals Camp24Checkin für die
              Vermittlung von Beherbergungen im eigenen Reisemobil oder Wohnwagen des Reisenden (nachfolgend
              „Camper“ genannt) auf Reisemobilstellplätzen oder Campingplätzen (nachfolgend „Stellplätze“ oder
              „Stellplatz“ genannt) von ausgewählten Partnern des Vermittlers (nachfolgend „Betreiber“
              genannt).
            </p>
            <p>
              Diese Allgemeinen Geschäftsbedingungen gelten sowohl für alle unverbindlichen Anfragen beim
              Vermittler als auch für verbindliche Buchungen von Stellplätzen über das Buchungsportal des
              Vermittlers. Die Vermittlung einzelner Stellplätze, die über das Buchungsportal des Vermittlers
              gebucht werden können, erfolgt ggf. über die von den Partnern des Vermittlers betriebenen
              Online-Reservierungssysteme.
            </p>
            <p>
              Diese Allgemeinen Geschäftsbedingungen gelten auch für den Fall entgegenstehender allgemeiner
              Geschäftsbedingungen von Campern, die die Buchung einer Beherbergung im eigenen Reisemobil oder
              Wohnwagen des Reisenden auf Reisemobilstellplätzen oder Campingplätzen beabsichtigen. Diese
              werden auch dann nicht angenommen, wenn der Vermittler in Kenntnis der Allgemeinen
              Geschäftsbedingungen des Campers Leistungen erbringt, es sei denn, der Vermittler und der Camper
              haben ausdrücklich etwas anderes vereinbart.
            </p>

            <h3>2. Leistungsgegenstand</h3>
            <h4>2.1. Einrichtung Nutzung des Camp24Checkin Account Kündigung</h4>
            <p>
              Der Camper hat die Möglichkeit sich auf dem Buchungsportal einen Nutzeraccount (nachfolgend
              „C24C-Account“ genannt) einzurichten. Dieser Account ermöglicht dem Kunden die Inanspruchnahme
              u.a. folgender Vorteile:
              <ul>
                <li>Schnelles Buchen, Änderungen der Buchung, Stornierung der Buchung</li>
                <li>
                  Bei Camp24Checkin einlösbare Gutschriften in € sammeln mit jedem hochgeladenen und
                  freigeschalteten Stellplatzfoto des Campers
                </li>
                <li>Eingabe von Positiv-/Negativbewertungen des Campers zum Stellplatz.</li>
              </ul>
              Eine abschließende Übersicht über sämtliche Vorteile und Funktionalitäten des C24C-Accounts wird
              dem Camper im Rahmen der Einrichtung seines C24C-Account zur Kenntnisnahme eingestellt.
            </p>
            <p>
              Um den C24C-Account einzurichten, muss der Camper seinen Namen sowie seine E-Mail-Adresse
              angeben und ein eigenes Passwort festlegen. Mit Klick auf den Button „Jetzt registrieren“
              akzeptiert der Camper diese Allgemeinen Geschäftsbedingungen der Camp24 Checkin GmbH
              (Vermittler) und gibt ein Angebot auf Abschluss des Vertrages über den C24C-Account ab. Der
              Vermittler nimmt dieses Angebot durch Bestätigung in elektronischer Form an.
            </p>
            <p>
              Der zustande gekommene Vertrag über die Nutzung des C24C-Accounts ist für den Camper kostenfrei,
              wird für unbestimmte Zeit geschlossen und kann von dem Camper jederzeit ohne Einhaltung einer
              Kündigungsfrist gekündigt werden. Der Vermittler kann den Vertrag über die Nutzung des
              C24C-Accounts jederzeit mit einer Kündigungsfrist von 14 Tagen kündigen. Der Vermittler ist
              berechtigt, eine Kündigung dem Stellplatzbetreiber/Campingplatzbetreiber unverzüglich
              mitzuteilen.
            </p>

            <h4>2.2. Vermittlung und Abschluss des Beherbergungsvertrages</h4>

            <p>
              Die Leistung des Vermittlers besteht in der für den Camper unentgeltlichen Vermittlung des
              Abschlusses von Beherbergungsverträgen zwischen dem Camper und dem Stellplatzbetreiber/
              Campingplatzbetreiber (nachfolgend „Betreiber“ genannt).
            </p>
            <p>
              Der Beherbergungsvertrag kommt durch die Buchung über den C24CAccount unmittelbar zwischen dem
              Camper und dem Betreiber über den vom Camper ausgewählten Stellplatz zustande. Zu diesem Zweck
              übermittelt der Vermittler dem Betreiber die angegebenen beim Vermittler hinterlegten
              personenbezogenen Daten des Campers. Weitere Einzelheiten zum Umgang mit personenbezogenen Daten
              sind unter Ziff. 10 sowie in der Datenschutzerklärung des Vermittlers enthalten.
            </p>
            <p>
              Sämtliche sich aus dem Beherbergungsvertrag zwischen Camper und Betreiber ergebenden Rechte und
              Pflichten bestehen ausschließlich nur zwischen dem Camper, der die Leistungen des Betreibers in
              Anspruch nehmen möchte, und dem Betreiber. Hingegen kommt in keinem Fall ein
              Beherbergungsvertrag zwischen dem Vermittler selbst und dem Camper zustande und kann weder der
              Vermittler noch der Camper aus dem genannten Beherbergungsvertrag Rechte oder Ansprüche
              gegenüber dem anderen herleiten, soweit sich aus den nachfolgenden Bestimmungen nichts anderes
              ergibt.
            </p>
            <p>
              Ausschließlicher Leistungserbringer der Reisemobil-/Wohnwagenstellplätze ist nicht der
              Vermittler, sondern der über den C24C-Account vom Camper ausgewählte Betreiber. Werden mehrere
              Stellplätze über das Buchungsportal des Vermittlers gebucht, erfolgen Auswahl und
              Zusammenstellung der einzelnen Stellplätze nicht durch den Vermittler, sondern ausschließlich in
              eigener Verantwortung und Entscheidung des Campers. Die im Buchungsportal vom Vermittler
              aufgelisteten Stellplätze sollen mittels ihrer Örtlichkeit, Lage, Preis, Zeitraum, Größe usw.
              dem Camper die Auswahl gem. seinen Wünschen und Vorstellungen erleichtern, sollen jedoch nicht
              so verstanden werden, dass es daneben keine weiteren Stellplätze gäbe, die den Wünschen und
              Vorstellungen des Campers besser entsprechen könnten. Die im Buchungsportal zu den vom
              Vermittler aufgelisteten Stellplätzen gemachten Angaben, Beschreibungen und Ausstattungen
              beruhen auf den Mitteilungen, die der Betreiber dem Vermittler diesbezüglich zur Verfügung
              gestellt hat. Der Vermittler überprüft diese nicht und haftet nicht für deren Richtigkeit,
              Aktualität und Vollständigkeit. Der Vermittler steht auch nicht dafür ein, dass der Betreiber
              seine Vertragspflichten und –leistungen gem. dem mit ihm abgeschlossenen Beherbergungsvertrag
              gegenüber dem Camper vollständig und mangelfrei erbringt.
            </p>
            <p>
              Auf Wunsch des Campers wird der Vermittler allerdings bei der Abwicklung oder Behebung von
              berechtigten Reklamationen des Campers gegenüber dem Betreiber behilflich sein. Eine
              diesbezügliche Rechtspflicht zum Tätigwerden trifft den Vermittler jedoch nicht.
            </p>
            <h3>3. Eingaben des Campers in das Buchungsportal</h3>
            <h4>3.1. Stellplatzbewertungen</h4>
            <p>
              Der Vermittler bietet dem Camper über das Buchungsportal die Möglichkeit, von Dritten bzw.
              anderen Campern mitgeteilte Bewertungen oder ergänzende Inhalte oder Bilder zu den angebotenen
              Leistungen des Betreibers abzurufen. Diese geben jeweils nur eine persönliche und zumeist auf
              eine bestimmte Reisezeit bezogene Beurteilung des Dritten bzw. Campers wieder und sind von den
              persönlichen Erwartungen und Vorstellungen des jeweils Berichtenden geprägt. Sie können auch
              nicht berücksichtigen, dass sich die vom Betreiber angebotenen Leistungen zwischenzeitlich
              verändert, beschränkt oder erweitert bzw. verbessert haben könnten. Der Camper sollte auch
              bedenken, dass eine einzelne Meinung oft weniger aussagekräftig ist als die Zusammenschau einer
              Vielzahl von Bewertungen und die Aktualität der Bewertung vom Zeitpunkt ihrer Eingabe in das
              Buchungsportal abhängen kann.
            </p>
            <h4>3.2. Einreichung von Bewertungen</h4>
            <p>
              Der Vermittler bietet dem Camper oder auch Dritten die Möglichkeit, Bewertungen und Meinungen
              iSv Ziff. 3.1. oder ergänzende Inhalte und Bilder auf dem Buchungsportal zur weiteren
              Veröffentlichung einzureichen. Der Camper ist hierbei allein für die von ihm eingereichten
              Inhalte (z.B. Bilder, Berichte, Unterlagen) verantwortlich. Der Vermittler überprüft diese
              Einreichungen nicht auf deren Richtigkeit oder Aktualität. Mit der Einreichung erklärt sich der
              Einreichende mit der Verwertung entsprechend Ziff. 3.1. einverstanden. Es steht dem Vermittler
              jederzeit frei zu entscheiden, ob, wie lange und wann eingereichte Bewertungen, Meinungen,
              Bilder usw. in das Buchungsportal aufgenommen werden. Der Einreichende hat für seine
              eingereichten Sendungen, Unterlagen, Bilder usw. keinen Anspruch auf auszahlbare Vergütung oder
              Auslagenersatz. Der Vermittler kann aber nach seinem freien Ermessen dem Einreichenden bei
              Camp24Checkin einlösbare Gutschriften in € für jedes hochgeladene freigeschaltete Stellplatzfoto
              sowie für die Eingabe von Positiv-/Negativbewertungen zum Stellplatz gewähren (siehe Ziff. 9).
            </p>
            <h4>3.3. Veröffentlichungsverbote</h4>
            <p>
              Jedem Nutzer des Buchungsportals ist es untersagt, Inhalte in das Buchungsportal einzustellen,
            </p>
            <ul>
              <li>
                die unwahr oder irreführend sind, insb. falsche oder unsachliche Bewertungen zu Stellplätzen,
                dort anwesenden Personen, Freizeitmöglichkeiten und sonstigen dortigen Gegebenheiten/
                Ereignissen enthalten,
              </li>
              <li>
                die herabwürdigend, beleidigend, anstößig, bedrohend, pornographisch oder rassistisch sind,
              </li>
              <li>
                die Schutz- oder Persönlichkeitsrechte Dritter verletzen oder in sonstiger Hinsicht
                rechtswidrig sind,
              </li>
              <li>
                die offen oder verdeckt für Produkte, Dienstleistungen oder Unternehmen werben, auch insb.
                Bewertungen wiedergeben, für die dem Camper eine Vergütung angeboten wird oder versprochen
                wird oder Bewertungen von Stellplätzen vorgeben, für deren Betreiber der Camper selbst im
                Anstellungsverhältnis oder freischaffend tätig ist,
              </li>
              <li>
                die Links oder ähnliche Verweise enthalten, die geeignet sind, die Funktionsweise fremder
                Datenverarbeitungsanlagen zu beeinträchtigen.
              </li>
            </ul>
            <p>
              Verstößt ein Camper gegen eine dieser Bestimmungen zur Einstellung von Inhalten, ist der
              Vermittler befugt, diese Inhalte ganz oder teilweise zu ändern oder zu entfernen oder deren
              Veröffentlichung im Portal zu verhindern. Den Vermittler trifft allerdings keine Pflicht
              gegenüber dem Einreichenden, zum Buchungsportal eingereichte Inhalte auf etwaige Verstöße gegen
              diese Ziffer zu überprüfen oder den Einreichenden darauf hinzuweisen.
            </p>
            <h3>4. Buchung und Reservierung</h3>
            <h4>4.1. Ablauf</h4>
            <p>
              Jede Reservierung wird über den Vermittler als Erklärungsbote im Auftrag des Campers an den
              jeweiligen ausgewählten Betreiber weiterübermittelt. Für die reibungslose Abwicklung der Buchung
              ist daher erforderlich, dass die vom Camper angegebenen Daten (z.B. Übernachtungsdaten,
              Kontaktdaten) korrekt, verständlich und vollständig sind.
            </p>
            <p>
              Die Reservierung des Stellplatzes erfolgt bereits mit der Buchung und ist ohne
              Reservierungsbestätigung gültig. Die Reservierungsbestätigung erscheint auf dem Bildschirm und
              wird dem Camper zusätzlich per E-Mail und ggf. auch per SMS oder auf anderem elektronischen Wege
              als Kurzinformation übermittelt. Der Camper ist dafür verantwortlich, die Voraussetzungen dafür
              zu schaffen, dass die Bestätigung ihm auf entsprechendem Wege übermittelt werden kann. Die
              Wirksamkeit der Buchung bleibt davon unberührt, insb. auch bei Eingabe einer falschen EMail-
              Adresse oder Telefonnummer.
            </p>
            <h4>4.2. Abrechnung</h4>
            <p>
              Bei jeder Buchung muss der Camper im Rahmen des Buchungsvorgangs seine Kreditkartennummer und
              Angabe der Gültigkeitsdauer der Kreditkarte hinterlegen. Auf das Erfordernis der Hinterlegung
              einer Kreditkartennummer wird im Buchungsdialog hingewiesen. Die Kreditkartenangaben garantieren
              dem jeweils vom Camper ausgesuchten Betreiber die Bezahlung aller anfallenden Kosten für die
              gebuchte Beherbergung auf dem Stellplatz.
            </p>
            <p>
              Der Vermittler ist berechtigt, über die im Buchungsportal wie zuvor eingegebene Kreditkarte den
              vom Camper gebuchten Stellplatz sowie alle mit dessen Benutzung angefallenen Nebenkosten
              abzurechnen und dem Camper zu belasten, sofern der Beherbergungsvertrag zwischen dem Camper und
              Betreiber dies vorsieht.
            </p>
            <h4>4.3. Darstellung der Buchungsabwicklung</h4>
            <p>
              Im Anhang zu diesen Allgemeinen Geschäftsbedingungen befindet sich zur Verdeutlichung eine
              Darstellung zum weiteren Ablauf der Buchung und Stellplatznutzung.
            </p>
            <h3>5. Änderungen und Stornierungen</h3>
            <h4>5.1. Allgemeines</h4>
            <p>
              Je nach den Bestimmungen des vom Camper mit dem Betreiber abgeschlossenen Beherbergungsvertrages
              kann der Camper nach der Reservierung zur Zahlung des vertraglich vereinbarten Entgelts für den
              Stellplatz oder eines Teilbetrages davon auch verpflichtet sein, wenn die Reservierung nicht
              gem. den Bestimmungen des Vertrages rechtzeitig von ihm storniert wird oder der Stellplatz ohne
              Stornierung nicht in Anspruch genommen wird. Die jeweils gültigen Stornierungsbestimmungen
              werden dem Camper deshalb im Buchungsdialog mitgeteilt und werden vom Camper mit Abschluss des
              Beherbergungsvertrages als bindend angenommen.
            </p>
            <p>
              Es gelten für Änderungen/ Stornierungen die Bestimmungen des vom Camper mit dem Betreiber
              abgeschlossenen Beherbergungsvertrages. Der Camper ist mit der Buchung über das Buchungsportal
              des Vermittlers damit einverstanden und dazu verpflichtet, alle Änderungen und Stornierungen
              über das genannte Portal vorzunehmen, soweit nicht anders mit dem Betreiber und/oder Vermittler
              vereinbart. Ist nichts anderes vereinbart, erfordert die Änderung/ Stornierung eine Eingabe
              unmittelbar über das Buchungsportal des Vermittlers. Maßgeblich für die Rechtzeitigkeit der
              Änderung oder Stornierung ist dann der fristgerechte Zugang der Änderung/ Stornierung beim
              Vermittler.
            </p>
            <p>
              Soweit der abgeschlossene Beherbergungsvertrag nichts anderes vorsieht und die
              Stellplatzbelegung es zulässt, können Änderungen auch während des Aufenthaltes des Campers auf
              der Anlage des Betreibers getätigt werden.
            </p>
            <h4>5.2. Missbrauch des Buchungsportals</h4>
            <p>
              Um einen Missbrauch des Buchungsportals vorzubeugen und den Betreiber nicht unnötig mit Fehl-
              oder Falschbuchungen zu belasten, behält sich der Vermittler das Recht vor, eine Reservierung im
              Einzelfall zu stornieren, wenn eine Rückfrage unter den vom Camper hinterlegten Kontaktdaten
              nicht möglich ist. Besteht für einen fest bestimmten Zeitraum bereits eine Reservierung für
              einen Stellplatz kann der Camper einen anderen Stellplatz für denselben Zeitraum nur buchen,
              wenn er die bereits bestehende Reservierung storniert. Für die Stornierung gelten die
              Bestimmungen gem. Ziff. 5.1. Sollte der Camper die Stornierung des zuerst reservierten
              Stellplatzes nicht vornehmen, erfolgt eine automatische Stornierung der zuerst getätigten
              Reservierung binnen 30 Minuten nach der neuen Reservierung des anderen Stellplatzes. Darüber
              wird der Camper vom Vermittler auf elektronischem Wege, per SMS oder E-Mail unterrichtet.
            </p>
            <h3>6. Kategorien an Stellplätzen und Informationen über die Stellplätze</h3>
            <p>
              Alle Informationen zu und Beschreibungen über Stellplätze beruhen auf eigenen Angaben der
              Betreiber oder Dritter. Der Vermittler hat keinen Einfluss auf diese Angaben und übernimmt für
              deren Richtigkeit, Aktualität und Vollständigkeit keine Verantwortung (siehe auch Ziff. 2.2.).
            </p>
            <p>
              Die Einteilung der Stellplätze in Kategorien beruht auf der Einstufung nach Stellplatzgrößen.
              Die Klassifizierung gibt somit lediglich einen unverbindlichen Hinweis auf die Größe des
              Stellplatzes.
            </p>
            <h3>Liste der Stellplätze</h3>
            <p>
              Nach einer Suchanfrage via Webseite oder App werden dem Camper in der ersten Darstellung nur die
              Stellplätze vorgeschlagen, die die gestellten Kriterien erfüllen.
            </p>
            <h3>Preise</h3>
            <h4>8.1. Preise des Vermittlers</h4>
            <p>
              Die Leistung des Vermittlers für den Camper als Vermittlung des Abschlusses von
              Beherbergungsverträgen zwischen dem Camper und dem Stellplatzbetreiber/ Campingplatzbetreiber
              (siehe Ziff. 2.2.) sowie alle sonstigen in diesen Allgemeinen Geschäftsbedingungen beschriebenen
              Leistungen des Vermittlers gegenüber dem Camper sind unentgeltlich.
            </p>
            <h4>8.2. Preise für den Stellplatz und für Nebenleistungen</h4>
            <p>
              Soweit in dem Buchungsportal des Betreibers nicht anders ausgewiesen, gelten alle Preise pro
              Stellplatz und pro Tag incl. der bei Buchung gültigen Mehrwertsteuer. Die angebotenen
              Nebenleistungen wie z.B. für Strom oder Wasserversorgung oder Brauchwasserentsorgung hängen vom
              jeweiligen Betreiber und dem mit diesem geschlossenen Beherbergungsvertrag ab und sind separat
              zu vergüten nach dort gültiger Preisliste.
            </p>
            <p>
              Der Betreiber ist verpflichtet, den jeweiligen Endpreis incl. MWSt. anzeigen zu lassen. Die
              Preisangaben stammen ausschließlich vom Betreiber selbst. Die Rechnungsstellung und deren
              Zustellung erfolgt über den Vermittler im Auftrag des Betreibers (siehe auch Ziff. 4.2.). Welche
              Steuern und sonstige Abgaben bei Stellplätzen im In- und Ausland anfallen, richtet sich im
              Übrigen nach den jeweiligen lokalen Gegebenheiten und Bestimmungen.
            </p>
            <p>
              Die Möglichkeit zur ständigen Aktualisierung von Preisen kann vereinzelt dazu führen, dass die
              Preise auf der Detailliste des Buchungsportals zum ausgewählten Stellplatz von den zuvor
              angezeigten Preisen in der allgemeinen Stellplatzliste abweichen. Für den Camper ist dann der
              bei Abschluss des Buchungsvorgangs angegebene Endpreis maßgeblich.
            </p>
            <h4>Bestimmungen zu bei Camp24Checkin einlösbaren Gutschriften (Ziff. 2.1.)</h4>
            <h4>Vorerst freibleibend Bitte noch selbst einfügen und festlegen</h4>
            <h4>10. Datenschutz</h4>
            <p>
              Der Vermittler erhebt, verarbeitet und nutzt die personenbezogenen Daten des Campers nur, sofern
              und soweit eine diesbezügliche Einwilligung des Campers nach den gesetzlichen Bestimmungen
              vorliegt oder gesetzlich die Erhebung, Verarbeitung oder Nutzung der Daten gestattet ist. Dabei
              erhebt, verarbeitet und nutzt der Vermittler nur solche Daten, die für die Erbringung seiner
              Leistungen sowie die Nutzung und den Betrieb seines Buchungsportals oder der auf dem Portal
              angebotenen Leistungen erforderlich sind.
            </p>
            <p>
              In keinem Fall ist der Vermittler berechtigt, Daten des Campers an Dritte zu veräußern. Eine
              Übermittlung von Daten an Dritte, insb. den Betreiber, erfolgt lediglich in dem Umfang, wie es
              für eine Buchung bzw. Reservierung, deren Änderung oder Stornierung bei dem vom Camper
              ausgewählten Betreiber oder für die Erbringung der sonstigen vom Vermittler geschuldeten
              Leistungen erforderlich ist. Dies schließt ausdrücklich ein die Weitergabe an mit dem Vermittler
              verbundene Unternehmen oder sonstige Unterauftragnehmer zum Zwecke der Durchführung der Buchung
              im Rahmen entsprechender vertraglicher Strukturen unter Beachtung der geltenden gesetzlichen,
              insb. datenrechtlichen Vorschriften.
            </p>
            <p>
              Weitere Informationen zum Datenschutz und zum Umgang mit personenbezogenen Daten sind in der
              Datenschutzerklärung enthalten.
            </p>
            <h3>11. Marketing</h3>
            <h4>11.1. Allgemeines</h4>
            <p>
              Soweit der Vermittler im Rahmen der Buchung oder durch Registrierung für den geschlossenen durch
              individuellen Kunden-Log-In geschützten Bereich die E-Mail-Adresse des Campers erhalten hat,
              verwendet er diese unter den Voraussetzungen des § 7 Absatz 3 UWG für Zwecke der elektronischen
              Werbung für eigene ähnliche Leistungen. Dabei ist der Vermittler bemüht, dem Camper auf seine
              Interessen zugeschnittene Werbematerialien zu übermitteln.
            </p>
            <h4>11.2. Widerspruchsrechte</h4>
            <p>
              Der Camper kann dem Erhalt elektronischer Werbung jederzeit durch EMail an
              widerspruch@camp24checkin.de widersprechen, ohne dass dafür andere als die Übermittlungskosten
              nach den Basistarifen entstehen. Unabhängig davon behält sich der Vermittler vor, für Zwecke der
              Werbung, der Marktforschung und zur Aktualisierung der Gestaltung des Buchungsportals gem. dem
              Bedarf Nutzungsprofile unter Verwendung von Pseudonymen im Sinne des § 15 Absatz 3 TMG zu
              erstellen. Der Erstellung eines solchen Nutzungsprofils kann der Camper jederzeit durch einfache
              E-Mail an widerspruch@camp24checkin.de widersprechen.
            </p>
            <h3>12. Gewährleistung und Haftung</h3>
            <h4>12.1. Allgemeines</h4>
            <p>
              Alle im Buchungsportal des Vermittlers enthaltenen Angaben wurden mit größter Sorgfalt
              zusammengestellt. Für etwaige Fehler bei der Datenerfassung oder der Datenübertragung kann
              jedoch keine Gewährleistung übernommen werden.
            </p>
            <p>
              Die Übernahme, auch teilweise Übernahme von Daten in andere Datenträger oder die Verwendung zu
              anderen als den hier vorgesehenen Zwecken ist ausdrücklich nur mit Genehmigung des Vermittlers
              zulässig.
            </p>
            <p>
              Es wird ausdrücklich darauf hingewiesen, dass die auf dem Buchungsportal des Vermittlers
              zusammengestellten Informationen (siehe Absatz 1) überwiegend von den jeweiligen
              Stellplatzbetreibern, anderen Campern oder Dritten zur Verfügung gestellt werden. Jeder
              Betreiber, Camper oder Dritte, der solche Informationen dem Vermittler zur Verfügung stellt,
              trägt die alleinige Verantwortung für deren Richtigkeit, Aktualität und Vollständigkeit sowie
              Rechtmäßigkeit zur Verwendung; dies gilt ebenso für Angaben zu Preisen und Verfügbarkeiten. Der
              Vermittler kann Informationen durch diese genannten Dritten nicht überprüfen und übernimmt daher
              für deren Richtigkeit, Aktualität, Vollständigkeit, Qualität sowie Rechtmäßigkeit zur Verwendung
              keinerlei Gewährleistung (siehe auch Ziff. 2.2. Absatz 4) und Überprüfungspflicht.
            </p>
            <p>
              Der Vermittler macht sich weder die Inhalte der unter Absatz 3 genannten Informationen noch
              etwaige Camperbewertungen zu Eigen. Vielmehr geben Bewertungen der Camper ausschließlich deren
              persönliche Meinung und Beurteilung wieder. Für Form, Inhalt und die verwendeten User-ID dieser
              Bewertungen übernimmt der Vermittler keine Verantwortung und Haftung; dies gilt selbst dann,
              wenn er darauf hätte Einfluss nehmen können.
            </p>
            <p>
              Soweit Dritte Ansprüche aus den in Absatz 3 genannten Risiken gegenüber dem Vermittler geltend
              machen, ist der Camper verpflichtet, auf Nachfrage des Vermittlers alle zur Abwehr solcher
              Ansprüche ihm zugegangenen notwendigen Informationen dem Vermittler zur Verfügung zu stellen
              bzw. auszuhändigen.
            </p>
            <h4>12.2. Ausfall der Vermittlung</h4>
            <p>
              Die Vermittlung der Stellplätze über das Buchungsportal ist gem. Ziff. 8.1. für den Camper
              kostenlos.
            </p>
            <p>
              Der Camper hat jedoch keinen Anspruch auf die zeitliche und räumliche Verfügbarkeit der Dienste
              und auf deren Vollständigkeit. Der Vermittler haftet nicht für vollständige oder teilweise
              Unterbrechungen und/oder Ausfälle seiner Dienste wegen Reparatur, Instandhaltung oder
              Aktualisierung oder aus Gründen, die entweder nicht in seinem Einflussbereich liegen oder die
              Nutzung nur unerheblich behindern oder mindern.
            </p>
            <p>
              Der Vermittler haftet auch nicht für das Zustandekommen einer Buchung, gleich, aus welchem
              Grunde die Buchung ausfällt.
            </p>
            <h4>12.3. Weitere Gewährleistungsausschlüsse oder –beschränkungen</h4>
            <p>
              Der Vermittler haftet nicht für Mängel, Schäden oder Mangelfolgeschäden, die im unmittelbaren
              oder mittelbaren Zusammenhang mit der Leistungserbringung oder vertraglichen Leistungspflicht
              der Beherbergung bzw. Stellplatzbereitstellung durch den Betreiber entstehen. Dieser
              Haftungsausschluss gilt auch für Ansprüche aus Verschulden bei Vertragsschluss.
            </p>
            <p>
              Die Haftung des Vermittlers, gleich aus welchem Rechtsgrund, auf Schadensersatz ist darüber
              hinaus ausgeschlossen für alle Schäden, die nicht
            </p>
            <ul>
              <li>
                auf einer schuldhaften Verletzung von wesentlichen Vertragspflichten beruhen, das heißt
                Verpflichtungen, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst
                ermöglicht und auf deren Einhaltung der Vertragspartner deshalb regelmäßig vertrauen darf,
                oder
              </li>
              <li>durch grobe Fahrlässigkeit oder Vorsatz seitens des Vermittlers verursacht wurden oder</li>
              <li>
                in einer Verletzung des Lebens, des Körpers oder der Gesundheit oder des
                Produkthaftungsgesetzes bestehen oder aufgrund gesetzlich zwingend vorgeschriebener Haftung zu
                ersetzen sind.
              </li>
            </ul>
            <p>
              Tritt eine Haftung des Vermittlers nach den vorstehenden Bestimmungen ein, ohne dass grobe
              Fahrlässigkeit oder Vorsatz vorliegen, ist die Haftung des Vermittlers jedoch beschränkt auf
              solche Schäden und einen solchen Schadensumfang, deren Eintritt der Vermittler nach den ihm bei
              Vertragsschluss bekannten Umständen typischerweise voraussehen konnte oder hätte voraussehen
              müssen.
            </p>
            <p>
              Gewährleistungs- und Schadensersatzansprüche nach den vorstehenden Bedingungen verjähren nach
              den gesetzlichen Bestimmungen.
            </p>
            <h3>13. Erfüllungsort, anwendbares Recht, Nutzung des Buchungsportals</h3>
            <p>
              Erfüllungsort ist Rielasingen-Worblingen. Für Personen, die keinen allgemeinen deutschen
              Gerichtsstand haben, wird als Gerichtsstand Freiburg im Breisgau vereinbart. Anderenfalls gilt
              der nach dem anwendbaren Recht gültige Gerichtsstand.
            </p>
            <p>
              Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts sowie der Verweisungsnormen des
              internationalen Privatrechts. Unter dem Link
              https://ec.europa.eu//consumers/odr/main/index.cfm?event=main.home.show &Ing=DE kann die
              Online-Streitbeilegung Plattform der Europäischen Kommission erreicht werden. Der Vermittler
              nimmt nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil und
              ist hierzu auch nicht verpflichtet.
            </p>
            <p>
              Die Nutzung des Buchungsportals des Vermittlers und deren Inhalte sind ausdrücklich nur zur
              individuellen Abfrage von Stellplatzbuchungen gestattet. Automatisierte Abfragen sind nicht
              gestattet. Das Datenmaterial ist urheberrechtlich geschützt, weshalb eine Vervielfältigung, auch
              durch automatisiertes Auslegen (sog. Scraping), untersagt ist. Ausdrücklich nicht gestattet ist
              auch die Nutzung des Datenmaterials sowie der darin enthaltenen Abfrageergebnisse zum Zwecke der
              Weitervermittlung.
            </p>
            <p>
              Auf dieser Webseite enthaltene Verknüpfungen mit Websites (Links) anderer Unternehmen
              (Drittbetreiber) werden lediglich im Interesse des Campers bereitgestellt. Wenn dieser eine
              solche Verknüpfung anklickt, wird die Website des Vermittlers verlassen. Auf die Inhalte der
              Websites von Drittbetreibern hat der Vermittler keinen Einfluss, weshalb er keine Gewähr für
              deren Richtigkeit, Vollständigkeit, Aktualität oder Rechtmäßigkeit übernehmen kann.
            </p>
            <h3>14. Sonstiges</h3>
            <p>
              Der Vermittler behält sich vor, diese Allgemeinen Geschäftsbedingungen für Inhaber eines
              C24C-Accounts mit Wirkung für die Zukunft zu ändern. Der Vermittler wird dem Camper mit dem
              C24C-Account die neue Fassung seiner Allgemeinen Geschäftsbedingungen spätestens 4 Wochen vor
              deren Inkrafttreten auf der ihm vom Camper angegebenen E-Mail-Adresse bekanntgeben und den
              Camper auf diesem Wege auf die beabsichtigten Änderungen hinweisen. Widerspricht der Camper
              nicht binnen 4 Wochen ab Zugang der vorgenannten Mitteilung auf der wie zuvor angegebenen
              E-Mail- Adresse, gilt die neue Fassung der Allgemeinen Geschäftsbedingungen als akzeptiert und
              verbindlich. Auf das beiderseitige Kündigungsrecht von Camper und Vermittler nach Ziff. 2.1.
              wird hingewiesen.
            </p>
            <p>
              Der Vermittler ist verpflichtet, den Camper auf die beschriebene Möglichkeit zum Widerspruch
              sowie auf die Konsequenzen des Ausbleibens eines Widerspruchs hinzuweisen.
            </p>
            <p>
              Falls eine Bestimmung dieser Allgemeinen Geschäftsbedingungen unwirksam ist oder wird oder nicht
              durchsetzbar ist oder wird, soll diese Bestimmung so verstanden und ausgelegt werden, dass die
              Bestimmung mit dem auf sie anwendbaren Recht vereinbar ist und den mit der Bestimmung von den
              Vertragsparteien verfolgten Absichten weitestmöglich entspricht. Die übrigen Bestimmungen sollen
              hiervon unberührt bleiben.
            </p>
            <h3>Anhang gem. Ziff. 4.3. der Allgemeinen Geschäftsbedingungen</h3>
            <h3>Buchungsabwicklung CAMP/24 check-In:</h3>
            <p>
              Über die CAMP/24 check-In APP und Webseite können sich Campinggäste registrieren und für den
              gewünschten Zeitraum ihren Stellplatz vorab buchen. Grundvoraussetzung für die Reservierbarkeit
              der Stellplätze ist die Zufahrtskontrolle zum Stellplatz. Diese wird durch ein Schrankensystem
              mit integrierter Kennzeichenerkennung erreicht. Die eingebauten Kameras erkennen das Kennzeichen
              eines Fahrzeugs, gleichen dieses mit der Buchungsdatenbank ab und prüfen, ob eine Buchung
              vorliegt. Bei Bestätigung öffnet sich die Schranke. Gleichzeitig wird die Versorgungssäule
              (optional) am gebuchten Platz aktiviert und die Strom- und Wasserversorgung freigeschaltet.
              Während der gebuchten Aufenthaltsdauer kann ein Gast das Gelände nach Belieben verlassen und
              auch wieder anfahren. Die Versorgungssäule wird dann jeweils auf Wunsch ab- und wieder
              eingeschaltet. Beim Verlassen des Geländes nach Ablauf des gebuchten Aufenthalts registriert die
              Kamera das Auschecken des Gastes. Gäste, die nicht vorab gebucht haben, können über die
              Buchungsstation vor Ort einchecken und verfügbare, freie Plätze buchen. Nach der Zufahrt zum
              Gelände können Gäste ihren gebuchten Stellplatz anfahren. Über die große digitale Nummerierung
              unserer Versorgungssäulen können diese leicht lokalisiert werden. Die Versorgungssäulen bieten
              eine gleichzeitige Strom- und Frischwasserversorgung in einer Säule. Der exakte Verbrauch kann
              jeweils erfasst und nach Ende des Aufenthaltes automatisch abgerechnet werden. Der Gast erhält
              nach dem Aufenthalt eine detaillierte Abrechnung seines Verbrauchs. Eine pauschale Abrechnung
              für Strom und Wasser ist seitens der Stellplatzbetreiber auch weiterhin möglich. Über die reine
              Check-In Funktion hinaus bietet die Buchungsstation vor Ort weitere Optionen. Alle Buchungen
              können verlängert oder der Platz bei Nichtgefallen gewechselt werden (falls die Platzbelegung es
              erlaubt). Für Stellplätze, die sich in einer Kurregion befinden, gibt es einen eingebauten
              Gästekartendrucker, mit dem sich Gäste ihre Karte direkt am Platz ausdrucken lassen können.
              Unsere Dienstleistung umfasst die Bereitstellung der Plattform für die Buchungsabwicklung
              zwischen Stellplatzbetreibern und Campinggästen. Dies beinhaltet die Erfassung der Gästedaten
              für Meldescheine (Beherbergungsmeldedatenverordnung - BeherbMeldV), die automatisierte
              Rechnungserstellung für Gast und Betreiber und die (ausschließlich) bargeldlose Abrechnung, der
              vom Gast beanspruchten Dienstleistungen (Strom, Wasser, Übernachtungskosten etc.)
            </p>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export const Agb = withTranslation()(AgbPage);
