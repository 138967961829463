import { Grid, FormControlLabel, Checkbox, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    filterOption: {
      fontWeight: 400,
      fontSize: 18,
      color: "#484848",
      display: "flex",
      textTransform: "capitalize",
      "& img": {
        width: 18,
        height: 18,
        marginTop: 3,
        marginRight: 9,
        display: "block",
      },
    },
  })
);

export function FilterItem({ getFilters, handleFilterChange, name, text }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <FormControlLabel
        control={
          <Checkbox
            checked={getFilters[name] !== undefined ? getFilters[name] : false}
            onChange={handleFilterChange}
            name={name}
            color="primary"
            icon={<img src={`../../../assets/images/newfacilities/${name}.png`} alt="Form icon" />}
            checkedIcon={
              <img src={`../../../assets/images/newfacilities/${name}-green.png`} alt="form check icon" />
            }
          />
        }
        label={<span className={classes.filterOption}>{text}</span>}
        style={{ width: "100%" }}
      />
    </Grid>
  );
}
