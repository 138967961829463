import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";
import ReactCountryFlag from "react-country-flag";
import {
  Grid,
  FormControl,
  TextField,
  Collapse,
  Button,
  Typography,
  makeStyles,
  IconButton,
  Card,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CountriesDe } from "constants/countries";

const initialTravellerData = {
  firstName: "",
  lastName: "",
  birthDate: "",
  birthPlace: "",
  identityCardNo: "",
  validUntil: "",
  nationality: "",
};

export type TravellerCardProps = {
  item: any;
  index: number;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  // refreshUser?: any;
  // setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  // setCheckedE: React.Dispatch<React.SetStateAction<boolean>>;
  // setCheckedC: React.Dispatch<React.SetStateAction<boolean>>;
  // userId?: any;
  // onScanClick: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  title: {
    display: "flex",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
    margin: "0 20px",
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  saveButton: {
    marginTop: 5,
    float: "right",
  },
}));

export const FormTravellerCard: React.FC<TravellerCardProps> = ({
  item,
  index,
  setUserData,
  // setChecked,
  // setCheckedE,
  // setCheckedC,
  // userId,
  // onScanClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [traveller, setTraveller] = useState(item || initialTravellerData);
  const formRef = useRef(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setTimeout(() => {
        // @ts-ignore
        formRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 400);
    }
  };

  const handleChangeTraveller = (e: any) => {
    setTraveller((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleTravellerDate = (date: Date | null, type) => {
    setTraveller((prev) => ({
      ...prev,
      [type]: date,
    }));
  };

  const handleNationalityChange = (e: any, value: any) => {
    setTraveller((prev) => ({
      ...prev,
      nationality: value !== null ? value.code : "",
    }));
  };

  const saveTraveller = () => {
    setUserData((prev) => ({
      ...prev,
      travellers: [...prev.travellers.slice(0, index), traveller, ...prev.travellers.slice(index + 1)],
    }));
    setExpanded(false);
  };

  const fullName = traveller?.firstName + " " + traveller?.lastName;

  return (
    <div ref={formRef}>
      <Card className={classes.root}>
        <div className={classes.title} onClick={handleExpandClick}>
          <Typography variant="h6">{traveller?.firstName ? fullName : t("new_traveller")}</Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"firstName"}
                  value={traveller.firstName}
                  // defaultValue={person.firstName}
                  onChange={handleChangeTraveller}
                  variant="outlined"
                  label={t("label_search_firstname")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"lastName"}
                  value={traveller.lastName}
                  // defaultValue={person.firstName}
                  onChange={handleChangeTraveller}
                  variant="outlined"
                  label={t("label_search_lastname")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DatePicker
                    id={"birthDate"}
                    value={traveller.birthDate === "" ? null : traveller.birthDate}
                    onChange={(e) => handleTravellerDate(e, "birthDate")}
                    openTo="year"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    maxDate={new Date().toISOString().slice(0, 10)}
                    label={t("birthdate")}
                    views={["year", "month", "date"]}
                    disableFuture
                    clearLabel="ok"
                    cancelLabel={t("label_cancel")}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"birthPlace"}
                  value={traveller.birthPlace}
                  onChange={handleChangeTraveller}
                  variant="outlined"
                  label={t("birth_place")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"identityCardNo"}
                  value={traveller.identityCardNo}
                  onChange={handleChangeTraveller}
                  variant="outlined"
                  label={t("valid_id_or_passport_number")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DatePicker
                    id={"validUntil"}
                    value={traveller.validUntil === "" ? null : traveller.validUntil}
                    onChange={(date) => handleTravellerDate(date, "validUntil")}
                    openTo="year"
                    inputVariant="outlined"
                    format="dd.MM.yyyy"
                    // maxDate={addYears(new Date(), 20)}
                    label={t("valid_until")}
                    views={["year", "month", "date"]}
                    disablePast
                    clearLabel="ok"
                    cancelLabel={t("label_cancel")}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <Grid container alignItems="flex-end">
                  <Grid item>
                    {traveller.nationality !== "" && (
                      <ReactCountryFlag
                        countryCode={
                          // @ts-ignore
                          CountriesDe.find((x) => x.code === traveller?.nationality).code
                        }
                        svg
                        style={{
                          width: "70px",
                          height: "50px",
                          marginRight: 10,
                        }}
                        // width="60"
                        // style={{ paddingBottom: "4px" }}
                      />
                    )}
                  </Grid>

                  <Grid item xs>
                    <Autocomplete
                      id={"nationality"}
                      options={CountriesDe}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => {
                        return (
                          <>
                            <ReactCountryFlag
                              countryCode={option.code}
                              svg
                              style={{
                                width: "26",
                                paddingRight: "6px",
                              }}
                              // width="26"
                              // style={{ paddingRight: "6px" }}
                            />
                            {option.label}
                          </>
                        );
                      }}
                      value={{
                        code: traveller.nationality,
                        label: traveller.nationality
                          ? CountriesDe.find((x) => x.code === traveller.nationality)!.label
                          : "",
                        phone: "",
                      }}
                      onChange={(event: object, value: any) => handleNationalityChange(event, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={traveller.nationality}
                          label={t("nationality")}
                          variant="outlined"
                          style={{ width: "-webkit-fill-available" }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            onClick={saveTraveller}
            variant="contained"
            color="primary"
            disableElevation
            className={classes.saveButton}
          >
            {t("button_save")}
          </Button>
        </Collapse>
      </Card>
    </div>
  );
};
