import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Alert, Autocomplete } from "@material-ui/lab";
import MuiPhoneNumber from "material-ui-phone-number";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import { CountriesDe } from "constants/countries";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { getPriceForPreBooking, verifyPhone } from "rest/AdminRestClient";
import { useUserData } from "context";
import { FormTravellerCard } from "../Booking/TravellerCard";
import { InformationIcon } from "components";
import PreBookingBillTable from "./BillTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      justifyContent: "center",
    },
    addressBoxTitle: {
      marginBottom: "10px",
      marginRight: "-2px",
      textAlign: "center",
      fontSize: "1.6rem",
    },
    addressBoxTitle3: {
      marginBottom: "10px",
      textAlign: "center",
      fontSize: "1.6rem",
    },
    addressBoxTitle2: {
      marginBottom: "10px",
      textAlign: "center",
      fontSize: "larger",
    },
    button: {
      color: "#fff",
      backgroundColor: "#ff9800",
      "&:hover": {
        backgroundColor: "#f57c00",
      },
    },
    priceButton: {
      color: "#fff",
      backgroundColor: "#1976d2",
      "&:hover": {
        backgroundColor: "#1976f2",
      },
    },
    buttonGreen: {
      color: "#fff",
      backgroundColor: "#00713C",
      "&:hover": {
        backgroundColor: "#00913C",
      },
    },
    buttonRed: {
      color: "#fff",
      backgroundColor: "crimson",
      "&:hover": {
        backgroundColor: "red",
      },
    },
    nestedList: {
      paddingLeft: "16px", // Add padding for nested items
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  })
);

const initialTravellerData = {
  firstName: "",
  lastName: "",
  birthDate: null,
  birthPlace: "",
  identityCardNo: "",
  validUntil: null,
  nationality: "",
};

export const PreBookingForm = (params: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getUserData,
    setUserData,
    // setVerifyCodeStatus,
    // setChecked,
    setOpenImageBackdrop,
  } = useUserData();
  const [disableButton, setDisableButton] = useState(false);
  const [disablePriceButton, setDisablePriceButton] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [priceTable, setPriceTable] = useState(false);
  const [data, setData] = useState([
    {
      title: "Warum brauchen wir Ihre Daten?",
      content: [
        "Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten stets vertraulich und entsprechend der gesetzlichen EU Datenschutzvorschriften. Daher möchten wir Sie an dieser Stelle darüber informieren, warum wir bestimmte Daten von Ihnen erheben, verarbeiten und welche Rechte Ihnen zustehen.",
      ],
    },
    {
      title: "Meldepflicht",
      content: [
        "Ein Camping- und Reisemobilstellplatz ist ein Beherbergungsbetrieb im Sinne des Bundesmeldegesetzes (BMG). Die Gäste haben daher am Tag der Ankunft (Check-In) einen besonderen Meldeschein auszufüllen, der die in § 30 Abs. 2 BMG genannten Daten enthält.",
      ],
    },
    {
      title: "Meldebogen",
      content: [
        "Dieser Meldebogen muss grundsätzlich enthalten:",

        "* Ankunfts- und voraussichtliches Abreisedatum",
        "* Familienname",
        "* Vornamen",
        "* Geburtsdatum",
        "* Staatsangehörigkeit",
        "* Anschrift",
        "* Anzahl der Mitreisenden und deren Staatsangehörigkeit",
        "* Seriennummer des anerkannten und gültigen Passes oder Passersatzes bei Ausländern",
      ],
    },
    {
      title: "Zusätzlich erforderlich",
      content: [
        "* E-Mail-Adresse (zur Bestätigung Ihrer Identität)",
        "* Handynummer (für die Kommunikation von unterwegs)",
      ],
    },
    {
      title: "Datenverarbeitung",
      content: [
        "Die Datenverarbeitung auf dieser Website erfolgt durch die Camp24 Check-in GmbH. Ihre Daten werden automatisch erfasst, wenn Sie uns diese übermitteln. Dies können z.B. Daten sein, die Sie in ein Kontaktformular eingeben oder ein Ausweisdokument, das Sie uns fotografisch bereitstellen. Wir werden dann Ihre Daten in Ihr Profil übertragen und Ihre Fotos löschen.",

        "Die Daten können sofort manuell geändert werden, wenn sie nicht korrekt gelesen oder falsch erkannt wurden.",

        "Die Meldedaten müssen vor der Vernichtung für eine gewisse Zeit für den Fall der Einsichtnahme durch die Polizei aufbewahrt werden. Die Aufbewahrungsfrist beträgt ein Jahr ab dem Tag der Abreise der beherbergten Person. Nach Ablauf der Aufbewahrungsfrist werden die Meldedaten innerhalb von drei Monaten gelöscht.",
      ],
    },
    {
      title: "Rechte der Betroffenen",
      content: [
        "Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten Daten zu erhalten. Sie haben außerdem das Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Ihre Einwilligung zur Datenverarbeitung können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Darüber hinaus haben Sie unter bestimmten Voraussetzungen das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Außerdem besteht ein Beschwerderecht bei der zuständigen Aufsichtsbehörde.",
      ],
    },
  ]);
  const [information, setInformation] = useState<boolean>(false);
  const { baseInfoErrors } = params;
  const { address, baseInfo, travellers } = getUserData;

  useEffect(() => {
    console.log(params);
    const { vehicleType, startDate, endDate, spotName } = params;
    if (vehicleType) {
      setUserData((prev) => ({
        ...prev,
        baseInfo: {
          ...prev.baseInfo,
          vehicleSize: vehicleType,
          spotName: spotName,
        },
      }));
    }
    if (startDate && endDate) {
      setUserData((prev) => ({
        ...prev,
        baseInfo: {
          ...prev.baseInfo,
          arrivalDate: startDate,
          departureDate: endDate,
        },
      }));
    }
  }, []);

  const handleBaseInfoDate = (date: Date | null, type) => {
    setUserData((prev) => ({
      ...prev,
      baseInfo: {
        ...prev.baseInfo,
        [type]: date,
      },
    }));
  };

  const handleChangeBaseInfoSelect = (event: any) => {
    setUserData((prev) => ({
      ...prev,
      baseInfo: {
        ...prev.baseInfo,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeBaseInfo = (e: any) => {
    setUserData((prev) => ({
      ...prev,
      baseInfo: {
        ...prev.baseInfo,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const handlePrePaymentForm = (e: any) => {
    if (e.target.id === "description") {
      if (e.target.value.length < 200) {
        params.setPreBookingData({
          ...params.preBookingData,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      if (e.target.value.length < 50) {
        params.setPreBookingData({
          ...params.preBookingData,
          [e.target.id]: e.target.value,
        });
      }
    }
    // }
  };

  const handleChangePhone = (e: any, value: any) => {
    let phone = e.split(" ").join("");
    const count = value.dialCode.length;
    let phoneNumber = phone.substring(count + 1);
    let phoneCode = value.dialCode;

    if (phone[count + 1] === "0") {
      setUserData((prev) => ({
        ...prev,
        baseInfo: {
          ...prev.baseInfo,
          phone: "",
        },
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        baseInfo: {
          ...prev.baseInfo,
          phone: phoneNumber,
          phoneCode: "+" + phoneCode,
        },
      }));
    }
  };

  const handleAdressDate = (date: Date | null, type) => {
    setUserData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [type]: date,
      },
    }));
  };

  const handleChangeAddress = (e: any) => {
    setUserData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const handleCountryChange = (e: any, value: any) => {
    setUserData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        countryCode: value.code || "",
      },
    }));
  };

  const handleNationalityChange = (e: any, value: any) => {
    setUserData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        nationality: value !== null ? value.code : "",
      },
    }));
  };

  const addNewTraveller = () => {
    setUserData((prev) => ({
      ...prev,
      travellers: [...prev.travellers, initialTravellerData],
    }));
  };

  const handleMobileChange = (e: any, value: any) => {
    params.setPreBookingData({
      ...params.preBookingData,
      mobile: value,
    });
    let phone = e.split(" ").join("");
    const count = value.dialCode.length;
    let phoneNumber = phone.substring(count + 1);
    let phoneCode = value.dialCode;

    if (phone[count + 1] === "0") {
      setUserData((prev) => ({
        ...prev,
        baseInfo: {
          ...prev.baseInfo,
          phone: "",
        },
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        baseInfo: {
          ...prev.baseInfo,
          phone: phoneNumber,
          phoneCode: "+" + phoneCode,
        },
      }));
    }
  };

  const verify = (e: any) => {
    const obj: any = {};
    let error = false;
    Object.keys(baseInfo).forEach((key) => {
      // console.log(key);
      const value = baseInfo[key];
      // console.log(value);
      if (!value) {
        obj[key] = true;
        error = true;
      } else {
        obj[key] = false;
      }
    });
    // setBaseInfoErrors(obj);
    if (error) {
      // params.setPreBookingError({
      //   ...params.preBookingError,
      //   ...obj,
      // });
      params.dialogRef.current.scrollIntoView();
    } else {
      verifyPhone(baseInfo.phoneCode.toString() + baseInfo.phone, params.parkAreal._id)
        .then((result: any) => {
          if (result.data.ok) {
            params.setCodeInput(true);
          } else if (result.data.ok === false && result.data.preBooking === true) {
            params.setPreBookingExist(true);
          }
          setDisableButton(true);
        })
        .catch((error) => console.error(error));
    }
  };

  const verifyForce = (e: any) => {
    verifyPhone(baseInfo.phoneCode.toString() + baseInfo.phone, params.parkAreal._id, true)
      .then((result: any) => {
        params.setPreBookingExist(false);
        params.setCodeInput(true);
      })
      .catch((error) => console.error(error));
  };

  const openInformation = () => {
    setInformation(true);
  };
  const closeInformation = () => {
    setInformation(false);
  };

  const closePriceTable = () => {
    setPriceTable(false);
  };
  const openPriceTable = () => {
    setPriceTable(true);
  };

  return (
    <Box className={classes.mainContainer}>
      <Dialog
        open={information}
        onClose={closeInformation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogContent>
          <Typography variant="h4" gutterBottom>
            Datenschutzerklärung
          </Typography>
          <List>
            {data.map((item) => (
              <React.Fragment key={item.title}>
                <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <ListItemText
                    primary={item.title}
                    style={{ marginTop: "0px", marginBottom: "0px", paddingTop: "6px" }}
                  />
                </ListItem>
                <div className={classes.nestedList}>
                  {item.content.map((it, index) => (
                    <List component="div" key={`${item.title}-${index}`}>
                      <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <ListItemText secondary={it} style={{ marginTop: "0px", marginBottom: "0px" }} />
                      </ListItem>
                    </List>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeInformation}>Schließen</Button>
        </DialogActions>
      </Dialog>
      {/* write an dialog which has full width*/}
      {/* <Dialog
        open={priceTable}
        onClose={closePriceTable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogContent>
          <Grid container spacing={2} style={{ padding: "24px" }}>
            {priceParams.hasOwnProperty("gross") && <PreBookingBillTable params={priceParams} />}
          </Grid>
        </DialogContent>
      </Dialog> */}

      <Grid container xs={12} sm={8} lg={7} justifyContent="center" spacing={2}>
        <Grid container>
          {params.parkAreal._id && (
            <Grid item xs={2} style={{ paddingBottom: "0px", paddingTop: "18px" }}>
              <img
                src={`/assets/images/${params.parkAreal?._id}.png`}
                height={64}
                // width={140}
                alt="camp_logo"
              />
            </Grid>
          )}

          <Grid item xs={2} style={{ paddingBottom: "0px", paddingTop: "18px" }}>
            <img src={`/assets/images/reservation_logo.png`} height={64} width={160} alt="logo" />
          </Grid>
          <Grid item xs={1} style={{ paddingBottom: "0px" }} />
          <Grid
            container
            xs={2}
            // item
            // spacing={0}
            // direction="column"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs={12} style={{ marginRight: "4px", textAlign: "center" }}>
              <Typography variant="h6" className={classes.addressBoxTitle}>
                {t("prebooking")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={"spotName"}
                  value={baseInfo.spotName}
                  variant="outlined"
                  label={t("label_spotnumber")}
                  disabled
                  // required
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={2} style={{ paddingBottom: "0px" }} />
          <Grid
            container
            xs={3}
            justifyContent="flex-end"
            style={{ paddingBottom: "0px", paddingTop: "18px" }}
          >
            <Grid item>
              <img
                src={`/assets/images/online_reservation.png`}
                height={64}
                width={200}
                alt="online reservation"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container item spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"spotName"}
                value={baseInfo.spotName}
                variant="outlined"
                label={t("label_spotnumber")}
                disabled
                // required
              />
            </FormControl>
          </Grid>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <DatePicker
                id={"arrivalDate"}
                value={baseInfo.arrivalDate === "" ? null : baseInfo.arrivalDate}
                onChange={(date) => handleBaseInfoDate(date, "arrivalDate")}
                openTo="year"
                inputVariant="outlined"
                format="dd.MM.yyyy"
                // maxDate={addYears(new Date(), 20)}
                label={t("arrival_date")}
                views={["year", "month", "date"]}
                disablePast
                clearLabel="ok"
                cancelLabel={t("label_cancel")}
                disabled
                // required
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <DatePicker
                id={"departure_date"}
                value={baseInfo.departureDate === "" ? null : baseInfo.departureDate}
                onChange={(date) => handleBaseInfoDate(date, "departureDate")}
                openTo="year"
                inputVariant="outlined"
                format="dd.MM.yyyy"
                // maxDate={addYears(new Date(), 20)}
                label={t("departure_date")}
                views={["year", "month", "date"]}
                disablePast
                clearLabel="ok"
                cancelLabel={t("label_cancel")}
                disabled
                // required
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl required fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">{t("vehicle_size")}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="vehicleSize"
              value={baseInfo.vehicleSize}
              onChange={handleChangeBaseInfoSelect}
              label={t("vehicle_size")}
              disabled
              // required
              error={baseInfo.adultCount === "" && getUserData.status !== "new"}
            >
              <MenuItem value="B">{t("s_label")}</MenuItem>
              <MenuItem value="C">{t("m_label")}</MenuItem>
              <MenuItem value="D">{t("l_label")}</MenuItem>
              <MenuItem value="E">{t("xl_label")}</MenuItem>
              <MenuItem value="F">{t("xxl_label")}</MenuItem>
              <MenuItem value="P">{t("tent")}</MenuItem>
              <MenuItem value="Q">{t("tent")}</MenuItem>
              <MenuItem value="V">{t("cabin")}</MenuItem>
              <MenuItem value="W">{t("cabin")}</MenuItem>
            </Select>
            {baseInfo.vehicleSize === "" && getUserData.status !== "new" ? (
              <FormHelperText error>{t("mandatory_field")}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"numberplate"}
              value={baseInfo.numberplate}
              onChange={handleChangeBaseInfo}
              helperText={
                baseInfo.numberplate === "" && getUserData.status !== "new" ? t("mandatory_field") : ""
              }
              variant="outlined"
              label={t("label_numberPlate")}
              required
              error={baseInfoErrors["numberplate"]}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPhoneNumber
            // id={"phone"}
            // id="mobile"
            // value={getProfile.phoneCode + getProfile.phone}
            // onChange={handleChangePhone}
            defaultCountry="de"
            // helperText={
            //   getProfile.phone === "" && getProfile.status !== "new" ? t("mandatory_field") : ""
            // }
            // localization={{ Germany: "Deutschland" }}
            regions={"europe"}
            variant="outlined"
            // label={t("country_code_phone")}
            fullWidth
            // required
            disabled={disableButton}
            // disabled={params.codeInput || params.preBookingExist}
            autoFormat={true}
            countryCodeEditable={false}
            label={t("label_mobile_enter")}
            onChange={handleMobileChange}
            error={baseInfoErrors["phone"]}
            // onChange={(e) => handleMobileChange(e)}
            // error={getProfile.phone === "" && getProfile.status !== "new" ? true : false}
          />
          {/* <TextField
            variant="outlined"
            id="mobile"
            label={t("label_mobile_enter")}
            onChange={(e) => handlePrePaymentForm(e)}
            value={params.preBookingData.mobile}
            disabled={params.codeInput}
            type="text"
            fullWidth
          /> */}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <MuiPhoneNumber
              id={"phone"}
              value={baseInfo.phoneCode + baseInfo.phone}
              //@ts-ignore
              onChange={handleChangePhone}
              defaultCountry="de"
              // helperText={
              //   getProfile.phone === "" && getProfile.status !== "new"
              //     ? t("mandatory_field")
              //     : ""
              // }
              regions={"europe"}
              variant="outlined"
              label={t("country_code_phone")}
              required
              disableAreaCodes={false}
              // autoFormat={false}
              // countryCodeEditable={false}
              error={baseInfo.phone === "" && getUserData.status !== "new" ? true : false}
            />
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"mail"}
              defaultValue={baseInfo?.mail}
              onChange={handleChangeBaseInfo}
              variant="outlined"
              label={t("label_search_email")}
              required
              error={baseInfoErrors["mail"]}
              // error={baseInfo?.mail === "" && getUserData.status !== "new"}
              helperText={baseInfo.mail === "" && getUserData.status !== "new" ? t("mandatory_field") : ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"firstName"}
              value={baseInfo.firstName}
              // defaultValue={person.firstName}
              onChange={handleChangeBaseInfo}
              variant="outlined"
              label={t("label_search_firstname")}
              helperText={
                baseInfo.firstName === "" && getUserData.status !== "new" ? t("mandatory_field") : ""
              }
              required
              error={baseInfoErrors["firstName"]}
              // error={baseInfo.firstName === "" && getUserData.status !== "new" ? true : false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"lastName"}
              value={baseInfo.lastName}
              helperText={
                baseInfo.lastName === "" && getUserData.status !== "new" ? t("mandatory_field") : ""
              }
              onChange={handleChangeBaseInfo}
              variant="outlined"
              label={t("family_name")}
              required
              error={baseInfoErrors["lastName"]}
              // error={baseInfo.lastName === "" && getUserData.status !== "new"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel required id="demo-simple-select-outlined-label">
              {params.parkAreal._id == "u31r3jyzbygx" ? t("adult_count_14") : t("adult_count")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="adultCount"
              value={baseInfo.adultCount}
              onChange={handleChangeBaseInfoSelect}
              label={params.parkAreal._id == "u31r3jyzbygx" ? t("adult_count_14") : t("adult_count")}
              // label={t("adult_count")}
              required
              error={baseInfoErrors["adultCount"]}
              // error={baseInfo.adultCount === "" && getUserData.status !== "new"}
            >
              <MenuItem value="1">{t("adult_1")}</MenuItem>
              <MenuItem value="2">{t("adult_2")}</MenuItem>
              <MenuItem value="3">{t("adult_3")}</MenuItem>
              <MenuItem value="4">{t("adult_4")}</MenuItem>
            </Select>
            {baseInfo.adultCount === "" && getUserData.status !== "new" ? (
              <FormHelperText error>{t("mandatory_field")}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel required id="demo-simple-select-outlined-label">
              {params.parkAreal._id == "u31r3jyzbygx" ? t("child_count_14") : t("child_count")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="childCount"
              value={baseInfo.childCount}
              onChange={handleChangeBaseInfoSelect}
              label={params.parkAreal._id == "u31r3jyzbygx" ? t("child_count_14") : t("child_count")}
              required
              error={baseInfoErrors["childCount"]}
              // error={baseInfo.childCount === "" && getUserData.status !== "new"}
            >
              <MenuItem value="0">{t("none")}</MenuItem>
              <MenuItem value="1">{t("child_1")}</MenuItem>
              <MenuItem value="2">{t("child_2")}</MenuItem>
              <MenuItem value="3">{t("child_3")}</MenuItem>
              <MenuItem value="4">{t("child_4")}</MenuItem>
            </Select>
            {baseInfo.childCount === "" && getUserData.status !== "new" ? (
              <FormHelperText error>{t("mandatory_field")}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel required id="demo-simple-select-outlined-label">
              {t("pet_count")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="petCount"
              value={baseInfo.petCount}
              onChange={handleChangeBaseInfoSelect}
              label={t("pet_count")}
              required
              error={baseInfoErrors["petCount"]}
              // error={baseInfo.petCount === "" && getUserData.status !== "new"}
            >
              <MenuItem value="0">{t("none")}</MenuItem>
              <MenuItem value="1">{t("pet_1")}</MenuItem>
              <MenuItem value="2">{t("pet_2")}</MenuItem>
              <MenuItem value="3">{t("pet_3")}</MenuItem>
              <MenuItem value="4">{t("pet_4")}</MenuItem>
            </Select>
            {baseInfo.petCount === "" && getUserData.status !== "new" ? (
              <FormHelperText error>{t("mandatory_field")}</FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} style={{ paddingBottom: "0px" }}>
          <Typography variant="h6" className={classes.addressBoxTitle3}>
            {t("label_notice")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "0px", marginTop: "0px" }}>
          <Typography variant="h6" className={classes.addressBoxTitle2}>
            {t("label_why")}
            <IconButton color="primary" aria-label="add to shopping cart">
              <InformationIcon
                style={{ fill: "green", marginTop: "-5px" }}
                onClick={() => openInformation()}
              />
            </IconButton>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <DatePicker
                id={"birthDate"}
                value={address.birthDate === "" ? null : address.birthDate}
                onChange={(e) => handleAdressDate(e, "birthDate")}
                openTo="year"
                inputVariant="outlined"
                format="dd.MM.yyyy"
                maxDate={new Date().toISOString().slice(0, 10)}
                label={t("birthdate")}
                views={["year", "month", "date"]}
                disableFuture
                clearLabel="ok"
                cancelLabel={t("label_cancel")}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"birthPlace"}
              value={address.birthPlace}
              onChange={handleChangeAddress}
              variant="outlined"
              label={t("birth_place")}
              // required
              // error={
              //   person.birthPlace === "" && getProfile.status !== "new"
              //     ? true
              //     : false
              // }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"areaCode"}
              value={address.areaCode}
              onChange={handleChangeAddress}
              // helperText={
              //   address.areaCode === "" && getProfile.status !== "new"
              //     ? t("mandatory_field")
              //     : ""
              // }
              variant="outlined"
              label={t("postal_code")}
              // required
              // error={
              //   address.areaCode === "" && getProfile.status !== "new"
              //     ? true
              //     : false
              // }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"area"}
              value={address.area}
              onChange={handleChangeAddress}
              variant="outlined"
              label={t("residance")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"streetAndHouseNumber"}
              defaultValue={address.streetAndHouseNumber}
              onChange={handleChangeAddress}
              variant="outlined"
              label={t("street_housenumber")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <Grid container>
              <Grid item>
                {address.countryCode !== "" && (
                  <ReactCountryFlag
                    countryCode={address.countryCode || ""}
                    svg
                    style={{
                      width: "60px",
                      height: "50px",
                      marginRight: 10,
                    }}
                    // width="60" style={{ paddingBottom: "4px" }}
                  />
                )}
              </Grid>
              <Grid item xs>
                <Autocomplete
                  id={"nationality"}
                  options={CountriesDe}
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => {
                    return (
                      <>
                        <ReactCountryFlag
                          countryCode={option.code}
                          svg
                          style={{
                            width: "26",
                            paddingRight: "6px",
                          }}
                          // width="26" style={{ paddingRight: "6px" }}
                        />
                        {option.label}
                      </>
                    );
                  }}
                  value={{
                    code: address.countryCode,
                    label: address.countryCode
                      ? CountriesDe.find((x) => x.code === address.countryCode)!.label
                      : "",
                    phone: "",
                  }}
                  onChange={(event: object, value: any) => handleCountryChange(event, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={address.countryCode}
                      label={t("country_of_residence")}
                      variant="outlined"
                      style={{ width: "-webkit-fill-available" }}
                      // helperText={
                      //   address.countryCode === "" &&
                      //   getProfile.status !== "new"
                      //     ? t("mandatory_field")
                      //     : ""
                      // }
                      // error={
                      //   address.countryCode === "" &&
                      //   getProfile.status !== "new"
                      //     ? true
                      //     : false
                      // }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id={"identityCardNo"}
              value={address.identityCardNo}
              onChange={handleChangeAddress}
              variant="outlined"
              label={t("valid_id_or_passport_number")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <DatePicker
                id={"validUntil"}
                value={address.validUntil === "" ? null : address.validUntil}
                onChange={(date) => handleAdressDate(date, "validUntil")}
                openTo="year"
                inputVariant="outlined"
                format="dd.MM.yyyy"
                label={t("valid_until")}
                views={["year", "month", "date"]}
                disablePast
                clearLabel="ok"
                cancelLabel={t("label_cancel")}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
          <FormControl fullWidth variant="outlined">
            <Grid container alignItems="flex-end">
              <Grid item>
                {address.nationality !== "" && (
                  <ReactCountryFlag
                    countryCode={
                      // @ts-ignore
                      CountriesDe.find((x) => x.code === address.nationality).code
                    }
                    svg
                    style={{
                      width: "70px",
                      height: "50px",
                      marginRight: 10,
                    }}
                  />
                )}
              </Grid>

              <Grid item xs>
                <Autocomplete
                  id={"nationality"}
                  options={CountriesDe}
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => {
                    return (
                      <>
                        <ReactCountryFlag
                          countryCode={option.code}
                          svg
                          style={{
                            width: "26",
                            paddingRight: "6px",
                          }}
                        />
                        {option.label}
                      </>
                    );
                  }}
                  value={{
                    code: address.nationality,
                    label: address.nationality
                      ? CountriesDe.find((x) => x.code === address.nationality)!.label
                      : "",
                    phone: "",
                  }}
                  onChange={(event: object, value: any) => handleNationalityChange(event, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={address.nationality}
                      label={t("nationality")}
                      variant="outlined"
                      style={{ width: "-webkit-fill-available" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {travellers.map((item: any, index: number) => (
            <FormTravellerCard
              key={index + 9}
              item={item}
              index={index}
              setUserData={setUserData}
              // refreshUser={props.refreshUser}
              // setChecked={setChecked}
              // setCheckedE={setCheckedE}
              // setCheckedC={setCheckedC}
            />
          ))}
        </Grid>

        <Button
          onClick={addNewTraveller}
          variant="contained"
          color="primary"
          disableElevation
          style={{ margin: "auto" }}
        >
          {t("add_traveller")}
        </Button>

        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <TextField
            variant="outlined"
            // margin="dense"
            id="description"
            label={t("description")}
            onChange={(e) => handlePrePaymentForm(e)}
            value={params.preBookingData.description}
            type="text"
            // maxRows={4}
            rows={4}
            multiline
            fullWidth
            // variant="standard"
          />
        </Grid>
        {/* <Grid item xs={12} md={7}> */}
        {/* <MuiPhoneNumber
            // id={"phone"}
            // id="mobile"
            // value={getProfile.phoneCode + getProfile.phone}
            // onChange={handleChangePhone}
            defaultCountry="de"
            // helperText={
            //   getProfile.phone === "" && getProfile.status !== "new" ? t("mandatory_field") : ""
            // }
            // localization={{ Germany: "Deutschland" }}
            regions={"europe"}
            variant="outlined"
            // label={t("country_code_phone")}
            fullWidth
            // required
            disabled={disableButton}
            // disabled={params.codeInput || params.preBookingExist}
            autoFormat={true}
            countryCodeEditable={false}
            label={t("label_mobile_enter")}
            onChange={(e) => handleMobileChange(e)}
            // error={getProfile.phone === "" && getProfile.status !== "new" ? true : false}
          /> */}
        {/* <TextField
                variant="outlined"
                id="mobile"
                label={t("label_mobile_enter")}
                onChange={(e) => handlePrePaymentForm(e)}
                value={params.preBookingData.mobile}
                disabled={params.codeInput}
                type="text"
                fullWidth
              /> */}
        {/* </Grid> */}
        {/* <Grid item xs={4}>
          <Button
            onClick={() => getPrice()}
            // disabled={params.codeInput || params.preBookingExist}
            disabled={disablePriceButton}
            disableElevation
            variant="contained"
            className={classes.priceButton}
            // style={{ color: "#fff", backgroundColor: "#ff9800" }}
            fullWidth
          >
            {t("see_pricing")}
          </Button>
        </Grid> */}
        {/* <Grid item xs={4}>
          <Button
            onClick={verify}
            // disabled={params.codeInput || params.preBookingExist}
            disabled={disableButton}
            disableElevation
            variant="contained"
            className={classes.button}
            // style={{ color: "#fff", backgroundColor: "#ff9800" }}
            fullWidth
          >
            {t("sms_check")}
          </Button>
        </Grid> */}
        {params.codeInput && (
          <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                style={{ color: "#00713C", fontWeight: "bold", textAlign: "center" }}
              >
                {t("label_code_input")}
              </Typography>
            </Grid>
          </Grid>
        )}

        {params.preBookingExist && (
          <>
            <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
              <Grid item xs={8}>
                <Typography
                  variant="body1"
                  style={{ color: "crimson", fontWeight: "bold", textAlign: "center" }}
                >
                  {t("label_prebooking_exists")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={3} justifyContent="center" style={{ paddingTop: "20px" }}>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    params.handleReset();
                    params.situation();
                    params.setPreBookingExist(false);
                  }}
                  // disabled={params.codeInput}
                  disableElevation
                  variant="contained"
                  className={classes.buttonRed}
                  // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                  fullWidth
                >
                  {t("label_no")}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={verifyForce}
                  // disabled={params.codeInput}
                  disableElevation
                  variant="contained"
                  className={classes.buttonGreen}
                  // style={{ color: "#fff", backgroundColor: "#ff9800" }}
                  fullWidth
                >
                  {t("label_yes")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {params.codeInput && (
          <Grid container xs={12} justifyContent="center" style={{ paddingTop: "20px" }}>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                id="code"
                label="Code"
                onChange={(e) => handlePrePaymentForm(e)}
                value={params.preBookingData.code}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
