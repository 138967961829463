import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  CssBaseline,
  Typography,
  Grid,
  Card,
  CardActions,
  Button,
  Tooltip,
  Backdrop,
  CircularProgress,
  Fade,
  Snackbar,
  Chip,
  Paper,
  Hidden,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";

import { useVehicle as useGetVehicle, useCreateVehicle, useUpdateVehicle } from "rest";
import {
  VehicleForm,
  VehicleCard,
  PendantVehicleCard,
  PendantVehicleForm,
  CarVehicleCard,
  CarVehicleForm,
  TentCard,
} from "components/user/vehicles";
import { useVehicle } from "context";

const Logo = "/assets/images/info.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 12,
      fontSize: 20,
    },
    infoLogo: {
      height: 36,
    },
    description: {
      marginBottom: 12,
      fontSize: 14,
    },
    cardActions: {
      padding: 16,
    },
    media: {
      width: 200,
      height: 140,
    },
    details: {
      display: "flex",
    },
    green: {
      color: theme.palette.primary.main,
    },
    content: {
      padding: "16px !important",
      flex: "1 0 auto",
    },
    switchFormControl: {
      height: "100%",
      padding: "0 14px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
    },
    disabledDiv: {
      pointerEvents: "none",
      opacity: "0.7",
      transition: "0.5s",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
    chips: {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(1),
      margin: "auto",
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function UserVehicles(props: any) {
  const classes = useStyles();
  const location = useLocation<any>();

  const firstName = location.state.user.person.firstName;
  const lastName = location.state.user.person.lastName;

  const [pendantStatus, setPendantStatus] = useState(false);
  const [exist, setExist] = useState(false);
  const [openImageBackdrop, setOpenImageBackdrop] = useState(false);
  const [fieldError, setFieldError] = useState<any>();
  const [types, setTypes] = useState({
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
  });

  const {
    vehicles,
    setVehicles,
    checked,
    setChecked,
    checkedE,
    setCheckedE,
    checkedC,
    setCheckedC,
    vehicleForm,
    setVehicleForm,
    pendantVehicleForm,
    setPendantVehicleForm,
    carVehicleForm,
    setCarVehicleForm,
    prev,
    setPrev,
    setCard1,
    setCard2,
    setCard3,
    savedVehicle,
    setSavedVehicle,
  } = useVehicle();

  useEffect(() => {
    handleImageBackdropOpen();
  }, []);

  useGetVehicle({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      getVehicleHelper(data);
      handleImageBackdropClose();

      setVehicleForm(false);
      setPendantVehicleForm(false);
      setCarVehicleForm(false);
    },
  });

  const { mutate: createVehicle } = useCreateVehicle({
    onSuccess: () => {
      setSavedVehicle(false);
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: (err) => {
      setCheckedE(true);
      setFieldError(err?.response?.data);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: () => {
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const handleImageBackdropClose = () => {
    setOpenImageBackdrop(false);
  };
  const handleImageBackdropOpen = () => {
    setOpenImageBackdrop(true);
  };

  const getVehicleHelper = (data: any) => {
    let vehicle = {
      lengthInM: data.lengthInM !== "" ? data.lengthInM.toString().replace(".", ",") : "",
      numberplate: data.numberplate,
      // vehicleOwner: data.owner === "" ? firstName + " " + lastName : data.owner,
      hasVehicle: data.hasVehicle,
      isVehicleActive: data.isVehicleActive,
      isactive: data.hasPendant,
      hasPendant: data.hasPendant,
      pendant: {
        lengthInM: data.hasPendant ? data.pendantLengthInM.toString().replace(".", ",") : "",
        numberplate: data.hasPendant ? data.pendantNumberplate : "",
        isPendantActive: data.isPendantActive,
      },
      hasCar: data.hasCar,
      hasMoto: data.hasMoto,
      hasBike: data.hasBike,
      hasTent: data.hasTent,
      car: {
        lengthInM: data.hasCar ? data.carLengthInM.toString().replace(".", ",") : "",
        numberplate: data.hasCar ? data.carNumberplate : "",
        isCarActive: data.isCarActive,
      },
    };

    // let length = parseFloat(data.lengthInM);
    // if (data.isPendantActive && data.hasPendant) {
    //   length = parseFloat(data.pendantLengthInM) + parseFloat(data.lengthInM);
    // }

    let length = 0;
    if (data.isVehicleActive) {
      if (parseFloat(data.lengthInM) > length) {
        length = parseFloat(data.lengthInM);
      }
    }
    if (data.isCarActive) {
      if (parseFloat(data.carLengthInM) > length) {
        length = parseFloat(data.carLengthInM);
      }
    }
    if (data.isPendantActive) {
      if (parseFloat(data.pendantLengthInM) > length) {
        length = parseFloat(data.pendantLengthInM);
      }
    }

    setTypes({
      B: length < 6.1 ? true : false,
      C: length < 8.1 ? true : false,
      D: length < 9.1 ? true : false,
      E: length < 10.1 ? true : false,
      F: length <= 16.1 ? true : false,
    });

    setVehicles(vehicle);
    // setPendantStatus(data.hasPendant);
    // setExist(data.lengthInM !== "" && data.numberplate !== "" ? true : false);
    setExist(true);
    setPendantStatus(true);
    setPrev(JSON.parse(JSON.stringify(vehicle)));
  };

  const saveVehicles = () => {
    let first = Object.keys(vehicles).filter((key) => vehicles[key] === "");

    if (first.length > 0) {
      setSavedVehicle(true);
      setCheckedC(true);
      setTimeout(() => {
        setCheckedC(false);
      }, 5000);
    } else {
      let updateVehicleObject = {
        lengthInM: vehicles.lengthInM.replace(",", "."),
        numberplate: vehicles.numberplate.toString(),
        // owner: vehicles.vehicleOwner.toString(),
        hasPendant: vehicles.pendant.numberplate === "" ? false : true,
        pendantLengthInM: vehicles.pendant.lengthInM.replace(",", "."),
        pendantNumberplate: vehicles.pendant.numberplate.toString(),
        isPendantActive: vehicles.pendant.isPendantActive,
        carLengthInM: vehicles.car.lengthInM.replace(",", "."),
        carNumberplate: vehicles.car.numberplate.toString(),
        isCarActive: vehicles.car.isCarActive,
        isVehicleActive: vehicles?.isVehicleActive,
        hasMoto: vehicles?.hasMoto,
        hasBike: vehicles?.hasBike,
        hasTent: vehicles?.hasTent,
      };
      setCard1(1);
      setCard2(1);
      // if (!exist) {
      //   createVehicle(updateVehicleObject);
      // } else {
      updateVehicle(updateVehicleObject);
      // }
    }
  };

  const openVehicleForm = () => {
    setPrev(JSON.parse(JSON.stringify(vehicles)));
    setVehicleForm(true);
    setPendantVehicleForm(false);
    setCarVehicleForm(false);
    setCard1(5);
  };

  const openPendantVehicleForm = () => {
    setPrev(JSON.parse(JSON.stringify(vehicles)));
    setPendantVehicleForm(true);
    setVehicleForm(false);
    setCarVehicleForm(false);
    setCard2(5);
  };

  const openCarVehicleForm = () => {
    setPrev(JSON.parse(JSON.stringify(vehicles)));
    setCarVehicleForm(true);
    setVehicleForm(false);
    setPendantVehicleForm(false);
    setCard3(5);
  };

  if (vehicles != null && props.vehicle != null) {
    return (
      <Fade in={true}>
        <>
          <CssBaseline />
          <Snackbar open={checked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="success">Ihre Fahrzeuge wurden erfolgreich eingereicht</Alert>
          </Snackbar>
          <Snackbar open={checkedE} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="error">
              Bitte überprüfen Sie die Pflichtfelder:{" "}
              {fieldError && fieldError.map((item) => item?.msg).join(" ")}
            </Alert>
          </Snackbar>
          <Snackbar open={checkedC} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert severity="error"> Bitte überprüfen Sie die Pflichtfelder.</Alert>
          </Snackbar>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              {/* {exist === true && <VehicleCard openVehicleForm={openVehicleForm} />} */}
              <VehicleCard openVehicleForm={openVehicleForm} />
              <Hidden mdUp>
                {vehicleForm && (
                  <VehicleForm
                    saveVehicles={saveVehicles}
                    firstName={firstName}
                    lastName={lastName}
                    fieldError={fieldError}
                    setFieldError={setFieldError}
                  />
                )}
              </Hidden>
            </Grid>
            <Grid item xs={12} md={3}>
              {/* {pendantStatus === true && prev?.pendant && ( */}
              <CarVehicleCard openCarVehicleForm={openCarVehicleForm} saveVehicles={saveVehicles} />
              {/* )} */}
              <Hidden mdUp>{carVehicleForm && <CarVehicleForm savedVehicle={savedVehicle} />}</Hidden>
            </Grid>
            <Grid item xs={12} md={3}>
              {/* {pendantStatus === true && prev?.pendant && ( */}
              <PendantVehicleCard
                openPendantVehicleForm={openPendantVehicleForm}
                saveVehicles={saveVehicles}
              />

              <Hidden mdUp>{pendantVehicleForm && <PendantVehicleForm savedVehicle={savedVehicle} />}</Hidden>
              {/* )} */}
            </Grid>
            <Grid item xs={12} md={3}>
              {/* {pendantStatus === true && prev?.pendant && ( */}
              <TentCard
              // openPendantVehicleForm={openPendantVehicleForm}
              // saveVehicles={saveVehicles}
              />
              {/* )} */}
            </Grid>
          </Grid>
          {/* {exist === false && vehicleForm === false && (
            <Card style={{ marginTop: 18 }}>
              <CardActions className={classes.cardActions}>
                <Button onClick={openVehicleForm} variant="contained" color="primary">
                  Neues Fahrzeug hinzufügen +
                </Button>
              </CardActions>
            </Card>
          )} */}

          <br />

          <React.Fragment>
            <Paper className={classes.paper}>
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={1} style={{ paddingTop: "4px", paddingLeft: "10px" }}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <span>
                          <p>S ca. 5x6 meter 30qm</p>
                          <p>M ca. 5x8 meter 40qm</p>
                          <p>L ca. 5x9 meter 50qm</p>
                          <p>XL ca. 6x10 meter 72qm</p>
                          <p>XXL ca. 7x12 meter 98qm</p>
                        </span>
                      </React.Fragment>
                    }
                  >
                    <img src={Logo} alt="Camp 24" className={classes.infoLogo} />
                  </HtmlTooltip>
                </Grid>
                <Grid item sm={7}>
                  <Typography variant={"subtitle1"}>
                    Wir suchen aufgrund ihrer Angaben passende Stellplätze für Sie aus:
                  </Typography>
                </Grid>
                <Grid item sm={4} className={classes.chips}>
                  <Chip
                    label="S"
                    color={types.B === true ? "primary" : "default"}
                    style={{ height: "24px", marginTop: "16px" }}
                  />
                  <Chip
                    label="M"
                    color={types.C === true ? "primary" : "default"}
                    style={{ height: "28px", marginTop: "14px" }}
                  />
                  <Chip
                    label="L"
                    color={types.D === true ? "primary" : "default"}
                    style={{ height: "32px", marginTop: "12px" }}
                  />
                  <Chip
                    label="XL"
                    color={types.E === true ? "primary" : "default"}
                    style={{ height: "36px", marginTop: "10px" }}
                  />
                  <Chip
                    label="XXL"
                    color={types.F === true ? "primary" : "default"}
                    style={{ height: "40px" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>

          <Hidden smDown>
            {pendantVehicleForm && <PendantVehicleForm savedVehicle={savedVehicle} />}
            {carVehicleForm && <CarVehicleForm savedVehicle={savedVehicle} />}

            {vehicleForm && (
              <VehicleForm
                saveVehicles={saveVehicles}
                firstName={firstName}
                lastName={lastName}
                fieldError={fieldError}
                setFieldError={setFieldError}
              />
            )}
          </Hidden>

          {/* {pendantStatus === false &&
            pendantVehicleForm === false &&
            vehicleForm === false &&
            exist === true && (
              <Card style={{ marginTop: 18 }}>
                <CardActions className={classes.cardActions}>
                  <Button onClick={openPendantVehicleForm} variant="outlined" color="primary">
                    Anhänger hinzufügen +
                  </Button>
                </CardActions>
              </Card>
            )} */}
        </>
      </Fade>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={openImageBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

export const Vehicles = withTranslation()(UserVehicles);
