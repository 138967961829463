import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function TopHint() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("hint_thanks_for_register_camp_area")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("hint_fill_fields_below")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("hint_receive_link_for_approval")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("hint_for_questions_please_contact")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("hint_regards_campname")}
        </Typography>
      </Grid>
      <Grid container item>
        <Typography variant="subtitle1" gutterBottom component="div" color="secondary" align="left">
          {t("label_phone")}: 07731 90 72 610
        </Typography>
      </Grid>
    </Grid>
  );
}
