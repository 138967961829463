import * as React from "react";
import Contact from "./contact";
import Price from "./price";
import General from "./general";
import Fittings from "./Facilities/fittings";
import Activities from "./Facilities/activities";
import Sightseeing from "./Facilities/sightseeing";
import Transport from "./Facilities/transport";
import Service from "./Facilities/service";
import Rent from "./Facilities/rent";
import { DropPicture } from "./dropzone";
import TopHint from "./Hints/tophint";
import MiddleHint from "./Hints/middlehint";
import BottomHint from "./Hints/bottomhint";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, CardMedia, CardContent, Card, Grid, Box } from "@mui/material";
import { useStore, formStore } from "./State/state";
import { ImageCard } from "./card";
import { useTranslation, withTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7ED321",
    },
    secondary: {
      main: "#525252",
    },
  },
});
// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

function BearCounter() {
  const bears = useStore((state) => state.bears);

  const name = formStore((state) => state.contact.name);
  return <h1>{name} around here ...</h1>;
}

function Layout() {
  const state = formStore((state) => state.fileDescription);
  const { t } = useTranslation();
  const clickable = () => {
    console.log(state);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            paddingTop: 70,
            backgroundColor: "#fafafa",
            paddingBottom: "50px",
          }}
        >
          <Grid container>
            <Grid container spacing={3}>
              <Grid item xs={1} xl={2}>
                {/* <Item>xs</Item> */}
              </Grid>
              <Grid item xs={10} xl={8}>
                <Card>
                  <CardMedia
                    component="img"
                    height="360"
                    width="700"
                    image="/assets/images/title1.jpg"
                    alt="Camp24"
                  />
                  <CardContent>
                    <Grid item spacing={3} style={{ paddingBottom: "20px", paddingTop: "10px" }}>
                      <TopHint />
                    </Grid>
                    <Grid item spacing={3}>
                      <Contact />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Price />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <General />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <MiddleHint />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Fittings />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Activities />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Sightseeing />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Transport />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Service />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <Rent />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px" }}>
                      <BottomHint />
                    </Grid>
                    <Grid item spacing={3} style={{ paddingTop: "25px", paddingBottom: "15px" }}>
                      <Box
                        // component="span"
                        sx={{
                          p: 2,
                          border: "1px dashed lightGrey",
                          borderRadius: "5px",
                        }}
                      >
                        <DropPicture />
                      </Box>
                    </Grid>
                    <Grid item>
                      <ImageCard />
                    </Grid>
                    <Grid item container style={{ paddingTop: "50px" }} justifyContent="flex-start">
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        style={{ color: "#ffffff" }}
                        onClick={clickable}
                      >
                        {t("button_save")}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={1} xl={2}>
                {/* <Item>xs</Item> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}

const AutoGrid = withTranslation()(Layout);
export default AutoGrid;
