import type {
  IFetchParkingSpotsSvgParams,
  IFetchParkingSpotBookingPricesByDateParams,
  IFetchUserReceiptParams,
} from "./domain";

export const EntityType = {
  BOOKING_OF_USER: "booking_of_user",
  BOOKING_HISTORY_OF_USER: "booking_history_of_user",
  PARKING_SPOTS_SVG: "parking_spots_svg",
  PAST_PARKING_SPOTS_SVG: "past_parking_spots_svg",
  PARKING_SPOT_BOOKING_PRICES_BY_DATE: "parking_spot_booking_prices_by_date",
  USER_RECEIPT: "user_receipt",
  RECEIPTS_OF_USER: "receipts_of_user",
  PARKING_SPOTS_SVG_MOBILE: "parking_spots_svg_mobile",
  BOOKING_USER_DETAIL: "booking_detail_user",
};

export const QueryKeys = {
  bookingOfUser: () => [EntityType.BOOKING_OF_USER, "DETAIL"],
  bookingDetailOfUser: () => [EntityType.BOOKING_USER_DETAIL, "BOOKINGDETAIL"],
  bookingHistoryOfUser: () => [EntityType.BOOKING_HISTORY_OF_USER, "LIST"],
  parkingSpotsSvg: (params: IFetchParkingSpotsSvgParams) => [
    EntityType.PARKING_SPOTS_SVG,
    "LIST",
    JSON.stringify(params),
  ],
  pastParkingSpotsSvg: (params: IFetchParkingSpotsSvgParams) => [
    EntityType.PAST_PARKING_SPOTS_SVG,
    "LIST",
    JSON.stringify(params),
  ],
  parkingSpotBookingPricesByDate: (params: IFetchParkingSpotBookingPricesByDateParams) => [
    EntityType.PARKING_SPOT_BOOKING_PRICES_BY_DATE,
    "LIST",
    JSON.stringify(params),
  ],
  userReceipt: (params: IFetchUserReceiptParams) => [
    EntityType.USER_RECEIPT,
    "DETAIL",
    JSON.stringify(params),
  ],
  receiptsOfUser: () => [EntityType.RECEIPTS_OF_USER, "LIST"],
  getParkingSpotsSVGMobile: () => [EntityType.PARKING_SPOTS_SVG_MOBILE, "LIST"],
};
