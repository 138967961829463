import { ActivitiesList, ActivitiesListEn } from "../Data/tooltips";
import { ActivitiesImages } from "../../../helpers/images";
import { Checkbox, Tooltip, Typography, Zoom, Grid } from "@mui/material";
import { formStore, FormState } from "../State/state";
import { useTranslation } from "react-i18next";

export default function Activities() {
  const { t } = useTranslation();
  const { updateActivities, activities } = formStore();
  // ? this could have positive impact on performance
  // const updateActivities: FormState["updateActivities"] = formStore(
  //   (state) => state.updateActivities
  // );
  // const activities: FormState["activities"] = formStore(
  //   (state) => state.activities
  // );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateActivities(ActivitiesListEn[v], e.currentTarget.checked);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          {t("activities")}:
        </Typography>
      </Grid>
      {ActivitiesList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={activities[ActivitiesListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img
                src={ActivitiesImages["Gray/" + v + ".png"].default}
                height={48}
                width={48}
                alt="activities"
              />
            }
            checkedIcon={
              <img
                src={ActivitiesImages["Green/" + v + ".png"].default}
                height={48}
                width={48}
                alt="activities"
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
