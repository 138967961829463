import {
  Grid,
  TextField,
  IconButton,
  Button,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { useState, useContext } from "react";
import { DateRangePicker } from "react-dates";
import PlacesAutocomplete from "react-places-autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";
import { useHomeState } from "context/Home";
import { AuthContext } from "../../context/Auth";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchForm: {
      maxWidth: 900,
      margin: "auto",
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: "0px 0px 0px 10px rgba(255, 255, 255, 0.3)",
      filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.5))",
    },
    form: {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11.5px 16px",
          fontWeight: 200,
          fontSize: 19,
          lineHeight: "initial",
          color: "#484848",
        },
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent",
        },
      },
    },
    formMobile: {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11.5px 16px",
          fontWeight: 200,
          fontSize: 19,
          lineHeight: "initial",
          color: "#484848",
          textAlign: "center",
        },
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent",
        },
      },
    },
    autocomplete: {
      position: "relative",
      "& .autocomplete-dropdown-container": {
        width: "100%",
        position: "absolute",
        textAlign: "left",
        backgroundColor: "#fff",
        zIndex: 1,
        "& .suggestion-item": {
          padding: "4px 16px",
          color: "#484848",
          cursor: "pointer",
        },
        "& .suggestion-item--active": {
          padding: "4px 16px",
          color: "#fff",
          backgroundColor: "#00713C",
          cursor: "pointer",
        },
      },
    },
    reactDates: {
      "& .DateRangePickerInput__withBorder": {
        border: "none",
      },
    },
    button: {
      marginRight: -1,
      height: "100%",
      color: "#fff",
      backgroundColor: "#00713C",
      "&:hover": {
        backgroundColor: "#00713C",
      },
      "&:active": {
        backgroundColor: "#00913C",
      },
    },
    buttonWrapper: {
      [theme.breakpoints.down("sm")]: {
        margin: "10px auto",
        maxWidth: "284px",
      },
    },
    gridContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  })
);

export function SearchForm({
  handleAddressChange,
  handleAddressSelect,
  getPositionOf,
  isFiltersOpen,
  getData,
  own,
}) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:959px)");
  const { getAddress, setAddress, getStartDate, setStartDate, getEndDate, setEndDate } = useHomeState();
  const [getFocusedInput, setFocusedInput] = useState<any>(null);

  const { currentUser } = useContext<any>(AuthContext);
  const defaultBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(29.152161, -11.25),
    new google.maps.LatLng(68.847665, 43.417969)
  );

  const classes = useStyles();
  return (
    <div className={classes.searchForm}>
      <form className={matches ? classes.form : classes.formMobile} autoComplete="off" noValidate>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={8} sm={5} md={5}>
            <PlacesAutocomplete
              value={getAddress}
              onChange={handleAddressChange}
              onSelect={handleAddressSelect}
              key="places"
              searchOptions={{
                types: ["geocode"],
                bounds: defaultBounds,
                // @ts-ignore
                strictBounds: true,
              }}
              onError={() => setAddress("")}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className={classes.autocomplete}>
                  <TextField
                    {...getInputProps({
                      variant: "outlined",
                      placeholder: t("search_zip_or_city"),
                      fullWidth: true,
                    } as any)}
                  />
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          } as any)}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Grid>
          <Grid item xs={2} md={1}>
            <IconButton color="primary" aria-label="location" onClick={getPositionOf}>
              <LocationOnIcon />
            </IconButton>
          </Grid>
          {/* {matches ? ( */}
          <Grid item xs={12} md={4}>
            <div
              className={classes.reactDates}
              title={currentUser ? t("make_sure_vehicle_adress_profiles") : t("please_login_to_choose_date")}
            >
              <DateRangePicker
                startDate={getStartDate}
                startDateId="startDate"
                orientation={matches ? "horizontal" : "vertical"}
                endDate={getEndDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                focusedInput={getFocusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                disabled={!own}
                startDatePlaceholderText={t("label_startDate")}
                endDatePlaceholderText={t("label_endDate")}
              />
            </div>
          </Grid>
          {/* ) : (
            own && (
              <Grid item xs={12} md={4}>
                <div
                  className={classes.reactDates}
                  title="Bitte melden Sie sich an, um ein Datum wählen zu können"
                >
                  <DateRangePicker
                    startDate={getStartDate}
                    startDateId="startDate"
                    orientation={matches ? "horizontal" : "vertical"}
                    endDate={getEndDate}
                    endDateId="endDate"
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={getFocusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                    disabled={own}
                    startDatePlaceholderText="Anreise"
                    endDatePlaceholderText="Abreise"
                  />
                </div>
              </Grid>
            )
          )} */}
          <Grid item sm={6} md={2} xs={9} className={classes.buttonWrapper}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={getData}
              disableElevation
              fullWidth
              endIcon={<SearchIcon />}
              disabled={getAddress.length > 6 || isFiltersOpen ? false : true}
            >
              {t("label_search")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
