import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import "react-dates/lib/css/_datepicker.css";
import { getParkingSpotsSVG, getPreBookingPlan, getVehicle } from "rest/AdminRestClient";
import Paper from "@material-ui/core/Paper";
import SVG from "react-inlinesvg";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Hidden, Typography } from "@material-ui/core";
import showRotateDeviceText from "components/showRotateDevicetext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      "&:hover $focusHighlight": {
        opacity: 0,
      },
    },
    focusHighlight: {},
  })
);

export const ParkPlan = (params: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let tempEv;
  const svgElement = React.createRef();
  const [spot, setSpot] = React.useState<any>("");
  const [svg, setSvg] = React.useState<any>("");
  const [svgList, setSvgList] = React.useState<any>([]);
  const [baseSpot, setBaseSpot] = React.useState<any>("");
  const [conflict, setConflict] = React.useState<any>(false);
  const [orientation, setOrientation] = React.useState<any>(false);
  const [show, setShow] = React.useState<any>(false);

  useEffect(() => {
    if (typeof window.screen.orientation !== "undefined") {
      setOrientation(true);
      window.screen.orientation.onchange = function () {
        if (!window.screen.orientation.type.includes("landscape")) {
          setShow(true);
        } else {
          setShow(false);
        }
      };
      if (!window.screen.orientation.type.includes("landscape")) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, []);

  useEffect(() => {
    setConflict(false);

    getPreBookingPlan(
      params.startDate.toISOString(),
      params.endDate.toISOString(),
      params.parkAreal._id,
      params.vehicleType
    )
      .then((result: any) => {
        if (result.data === "Conflict") {
          setConflict(true);
        } else {
          setSvgList(result.data.circles);
          setSpot(
            result.data.circlesDet.sort((a: any, b: any) =>
              a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base",
              })
            )
          );
          setBaseSpot(JSON.parse(JSON.stringify(result.data.circlesDet)));
          setSvg(result.data.circles.join(""));
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const loaded = () => {
    // @ts-ignore
    svgElement.current?.addEventListener("click", (ev) => {
      if (ev.target.id) {
        changes(ev);
      }
    });
    // @ts-ignore
    svgElement.current?.addEventListener("touchend", (ev) => {
      if (ev.target.id) {
        changes(ev);
      }
    });
  };

  const changes = (ev: any) => {
    if (tempEv !== undefined) {
      tempEv.target.setAttribute("style", "fill: white; fill-opacity: 0.01; cursor: pointer;");
    }
    ev.target.setAttribute("style", "fill: #67B449; fill-opacity: 1; cursor: pointer;");
    tempEv = ev;
    params.setSelected(true);
    params.setSpotId(ev.target.id);
    // console.log(ev.target.getAttribute("data-name"));
    params.setSpotName(ev.target.getAttribute("data-name"));
    // console.log(ev.target.nextSibling);
  };

  if (conflict) {
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={0}>
          <Grid item xs={1} md={3} lg={4} />
          <Grid item xs={9} md={6} lg={4}>
            <Alert severity="warning">
              <AlertTitle>{t("warning")}</AlertTitle>
              {t("already_have_rez_warn")}
              <strong> {t("check_dates_before_rez")} </strong>
            </Alert>
          </Grid>
          <Grid item xs={1} md={3} lg={4} />
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        {svg && (
          <>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12} md={12} lg={8} xl={5}>
                <Paper elevation={0}>
                  <SVG
                    src={svg}
                    style={{
                      maxWidth: "1500px",
                      WebkitTouchCallout: "none",
                      WebkitUserSelect: "none",
                      KhtmlUserSelect: "none",
                      MozUserSelect: "none",
                      MsUserSelect: "none",
                      userSelect: "none",
                    }}
                    // @ts-ignore
                    innerRef={svgElement}
                    onLoad={loaded}
                  />
                </Paper>
              </Grid>
              <Hidden mdUp>
                {orientation === true ? (show ? showRotateDeviceText(t) : "") : showRotateDeviceText(t)}
              </Hidden>
            </Grid>
          </>
        )}
      </React.Fragment>
    );
  }
};

export default ParkPlan;
