import React from "react";
import {
  Card,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { truncate } from "fs";
import { RabattList } from "constants/campCardItems";
// import { AuthContext } from "context/Auth";

export function PriceInfoDefaultCard({ item, vehicleType }) {
  // const { currentUser }: any = useContext(AuthContext);
  const { t } = useTranslation();
  const [kurtaxe, setKurtaxe] = React.useState<any>({
    person: item.priceInformation.visitorTax !== "Nein" ? item.priceInformation.visitorTax : "",
    child: item.priceInformation.visitorTaxKind !== "Nein" ? item.priceInformation.visitorTaxKind : "",
    pet: item.priceInformation.visitorTaxHund !== "Nein" ? item.priceInformation.visitorTaxHund : "",
  });

  return (
    <Card style={{ marginBottom: 18 }}>
      <List dense={true} subheader={<ListSubheader color="primary">Preisinformationen</ListSubheader>}>
        {item.priceInformation.mainSeasonPriceForTwoAdults &&
        item.priceInformation.sideSeasonPriceForTwoAdults &&
        item.priceInformation.sideSeasonPriceForTwoAdults.toUpperCase() != "KOSTENLOS" ? (
          <ListItem>
            <ListItemAvatar key="transition-group-content">
              <Avatar variant="square" src={`/assets/images/PriceInformation/Blue/01.png`} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {t("peak_season") + ": "}
                    {item.priceInformation.mainSeasonPriceForTwoAdults.charAt(0).toUpperCase() +
                      item.priceInformation.mainSeasonPriceForTwoAdults.slice(1)}{" "}
                    {item.park ? " " + t("label_price_two_adults") : ""}
                  </Typography>
                  <Typography
                    display={"inline"}
                    style={{
                      fontWeight: 400,
                      fontSize: "revert",
                    }}
                  >
                    {t("side_season") + ": "}
                    {item.priceInformation.sideSeasonPriceForTwoAdults.charAt(0).toUpperCase() +
                      item.priceInformation.sideSeasonPriceForTwoAdults.slice(1)}{" "}
                    {item.park ? " " + t("label_price_two_adults") : ""}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ) : item.priceInformation.mainSeasonPriceForTwoAdults ? (
          <ListItem>
            <ListItemAvatar key="transition-group-content">
              <Avatar variant="square" src={`/assets/images/PriceInformation/Blue/01.png`} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                    {t("peak_season") + ": "}
                    {item.priceInformation.mainSeasonPriceForTwoAdults.charAt(0).toUpperCase() +
                      item.priceInformation.mainSeasonPriceForTwoAdults.slice(1)}{" "}
                    {item.park ? " " + t("label_price_two_adults") : ""}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ) : (
          ""
        )}

        {item.priceInformation.priceExtraAdult &&
        item.priceInformation.priceExtraAdult !== "Nein" &&
        item.priceInformation.priceChild &&
        item.priceInformation.priceChild !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="priceExtraAdult">
              <Avatar variant="square" src={`/assets/images/PriceInformation/Blue/02.png`} />
            </ListItemAvatar>
            <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
              {item.priceInformation.priceExtraAdult.split("/")[0]}
              <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                {" "}
                {t("extra_person")} {" / "}
              </Typography>{" "}
              <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                {item.priceInformation.priceChild.split("/")[0]}
              </Typography>
              <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                {" "}
                {t("label_price_child")}
              </Typography>
            </Typography>
          </ListItem>
        ) : item.priceInformation.priceExtraAdult !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="priceExtraAdult">
              <Avatar variant="square" src={`/assets/images/PriceInformation/Blue/02.png`} />
            </ListItemAvatar>
            {item.priceInformation.priceExtraAdult && item.priceInformation.priceExtraAdult !== "Nein" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.priceExtraAdult.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {t("extra_person")}
                </Typography>
              </Typography>
            ) : item.priceInformation.priceChild && item.priceInformation.priceChild !== "Nein" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.priceChild.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {t("label_price_child")}{" "}
                </Typography>
              </Typography>
            ) : (
              ""
            )}
          </ListItem>
        ) : item.priceInformation.priceChild !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="priceExtraAdult">
              <Avatar variant="square" src={`/assets/images/PriceInformation/Blue/02.png`} />
            </ListItemAvatar>
            {item.priceInformation.priceExtraAdult && item.priceInformation.priceExtraAdult !== "Nein" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.priceExtraAdult.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {t("extra_person")}
                </Typography>
              </Typography>
            ) : item.priceInformation.priceChild && item.priceInformation.priceChild !== "Nein" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.priceChild.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {t("label_price_child")}{" "}
                </Typography>
              </Typography>
            ) : (
              ""
            )}
          </ListItem>
        ) : (
          ""
        )}

        {kurtaxe.person !== "" || kurtaxe.pet !== "" || kurtaxe.child !== "" ? (
          <ListItem>
            <ListItemAvatar key="priceExtraAdult">
              <Avatar variant="square" src={`/assets/images/PriceInformation/Blue/03.png`} />
            </ListItemAvatar>

            {kurtaxe.person !== "" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {kurtaxe.person}
                <Typography
                  display={"inline"}
                  style={{ fontWeight: 400, fontSize: "revert", paddingRight: "4px" }}
                >
                  {" "}
                  {t("per_person")}
                </Typography>
              </Typography>
            ) : (
              ""
            )}

            {kurtaxe.child !== "" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {kurtaxe.person !== "" ? " / " + kurtaxe.child : kurtaxe.child}
                <Typography
                  display={"inline"}
                  style={{ fontWeight: 400, fontSize: "revert", paddingRight: "4px" }}
                >
                  {" "}
                  {t("label_price_child")}
                </Typography>
              </Typography>
            ) : (
              ""
            )}

            {kurtaxe.pet !== "" ? (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {kurtaxe.person !== "" || kurtaxe.child !== "" ? " / " + kurtaxe.pet : kurtaxe.pet}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {t("dog")}
                </Typography>
              </Typography>
            ) : (
              ""
            )}
          </ListItem>
        ) : (
          ""
        )}

        {item.priceInformation.electricityCosts &&
        item.priceInformation.electricityCosts !== "-" &&
        item.priceInformation.electricityCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="electricity48">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/0.png`} />
            </ListItemAvatar>

            {item.priceInformation.electricityCosts === "inklusive" ? (
              <ListItemText
                primary={item.priceInformation.electricityCosts}
                style={{ marginRight: "50px" }}
              />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.electricityCosts.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {item.priceInformation.electricityCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.waterCosts &&
        item.priceInformation.waterCosts !== "-" &&
        item.priceInformation.waterCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="water48">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/1.png`} />
            </ListItemAvatar>
            {item.priceInformation.waterCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.waterCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.waterCosts.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                  {item.priceInformation.waterCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.showerCosts &&
        item.priceInformation.showerCosts !== "-" &&
        item.priceInformation.showerCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="shower48">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/3.png`} />
            </ListItemAvatar>
            {item.priceInformation.showerCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.showerCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.showerCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${(10 - item.priceInformation.showerCosts.split("/")[0].length) * 8.5}px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.showerCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.toiletCosts &&
        item.priceInformation.toiletCosts !== "-" &&
        item.priceInformation.toiletCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="wifi48">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/2.png`} />
            </ListItemAvatar>
            {item.priceInformation.toiletCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.toiletCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.toiletCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${(10 - item.priceInformation.toiletCosts.split("/")[0].length) * 8.5}px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.toiletCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}

        {item.priceInformation.wifiCosts &&
        item.priceInformation.wifiCosts !== "-" &&
        item.priceInformation.wifiCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="wifi48">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/5.png`} />
            </ListItemAvatar>
            {item.priceInformation.wifiCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.wifiCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.wifiCosts.split("/")[0]}
                <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                  {" "}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.petCosts &&
        item.priceInformation.petCosts !== "-" &&
        item.priceInformation.petCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="hund48">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/7.png`} />
            </ListItemAvatar>
            {item.priceInformation.petCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.petCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.petCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${(10 - item.priceInformation.petCosts.split("/")[0].length) * 8.5}px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.petCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.chemistryWCCosts &&
        item.priceInformation.chemistryWCCosts !== "-" &&
        item.priceInformation.chemistryWCCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="chemistryWCCosts">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/10.png`} />
            </ListItemAvatar>
            {item.priceInformation.chemistryWCCosts === "inklusive" ? (
              <ListItemText
                primary={item.priceInformation.chemistryWCCosts}
                style={{ marginRight: "50px" }}
              />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.chemistryWCCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${
                      (10 - item.priceInformation.chemistryWCCosts.split("/")[0].length) * 8.5
                    }px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.chemistryWCCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}

        {item.priceInformation.automatCosts &&
        item.priceInformation.automatCosts !== "-" &&
        item.priceInformation.automatCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="automatCosts">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/12.png`} />
            </ListItemAvatar>
            {item.priceInformation.automatCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.automatCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.automatCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${(10 - item.priceInformation.automatCosts.split("/")[0].length) * 8.5}px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.automatCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.disposalCosts &&
        item.priceInformation.disposalCosts !== "-" &&
        item.priceInformation.disposalCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="disposalCosts">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/11.png`} />
            </ListItemAvatar>
            {item.priceInformation.disposalCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.disposalCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.disposalCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${(10 - item.priceInformation.disposalCosts.split("/")[0].length) * 8.5}px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.disposalCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.rubbishCosts &&
        item.priceInformation.rubbishCosts !== "-" &&
        item.priceInformation.rubbishCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="rubbishCosts">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/9.png`} />
            </ListItemAvatar>
            {item.priceInformation.rubbishCosts === "inklusive" ? (
              <ListItemText primary={item.priceInformation.rubbishCosts} style={{ marginRight: "50px" }} />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.rubbishCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${(10 - item.priceInformation.rubbishCosts.split("/")[0].length) * 8.5}px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.rubbishCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
        {item.priceInformation.washingmachineCosts &&
        item.priceInformation.washingmachineCosts !== "-" &&
        item.priceInformation.washingmachineCosts !== "Nein" ? (
          <ListItem>
            <ListItemAvatar key="rubbishCosts">
              <Avatar variant="square" src={`/assets/images/Fittings/Blue/22.png`} />
            </ListItemAvatar>
            {item.priceInformation.washingmachineCosts === "inklusive" ? (
              <ListItemText
                primary={item.priceInformation.washingmachineCosts}
                style={{ marginRight: "50px" }}
              />
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                {item.priceInformation.washingmachineCosts.split("/")[0]}
                <Typography
                  display={"inline"}
                  style={{
                    paddingLeft: `${
                      (10 - item.priceInformation.washingmachineCosts.split("/")[0].length) * 8.5
                    }px`,
                    fontSize: "revert",
                  }}
                >
                  {item.priceInformation.washingmachineCosts.split("/")[1]}
                </Typography>
              </Typography>
            )}
          </ListItem>
        ) : (
          ""
        )}
      </List>
    </Card>
  );
}
