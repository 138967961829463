import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { persons, includes, yesno } from "./Data/dropdowns";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { formStore } from "./State/state";
import { IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { priceLoadSecondDropdownType } from "./Types/types";
import { priceLoadSecondDropdown, priceLoad, priceLoadStatus } from "./State/models";
import { useTranslation } from "react-i18next";

export default function Price() {
  const { t } = useTranslation();
  const [price, setPrice] = React.useState(priceLoad);
  const [status, setStatus] = React.useState(priceLoadStatus);
  const { updatePrice } = formStore();
  const [secondDropdown, setSecondDropdown] = React.useState(priceLoadSecondDropdown);

  const minWidth = "120px";

  useEffect(() => {
    setPrice(priceLoad);
    setStatus(priceLoadStatus);
    setSecondDropdown(priceLoadSecondDropdown);
  }, []);

  const changeSix = (
    event: { target: { value: string; name: string } },
    ex: keyof priceLoadSecondDropdownType
  ) => {
    if (event.target.value === "Ja") {
      // ts-ignore
      if (secondDropdown[ex] === false) {
        setSecondDropdown({ ...secondDropdown, [ex]: true });
        setPrice({ ...price, [ex]: "inklusive" });
        updatePrice(ex, "inklusive");
      } else {
        setPrice({ ...price, [ex]: event.target.value });
        updatePrice(ex, event.target.value);
      }
    } else {
      setStatus({ ...status, [ex]: false });
      setPrice({ ...price, [ex]: event.target.value });
      updatePrice(ex, event.target.value);
    }
  };

  const handleChange = (event: { target: { value: string; name: string } }) => {
    switch (event.target.name) {
      case "zweipersonen":
        if (event.target.value !== "Kostenlos") {
          setStatus({ ...status, zweipersonen: false });
        }
        setPrice({ ...price, zweipersonen: event.target.value });
        updatePrice("zweipersonen", event.target.value);
        break;
      case "properson":
        if (event.target.value !== "inklusive") {
          setStatus({ ...status, proPerson: false });
        }
        setPrice({ ...price, proPerson: event.target.value });
        updatePrice("proPerson", event.target.value);
        break;
      case "prokid":
        if (event.target.value !== "inklusive") {
          setStatus({ ...status, proKid: false });
        }
        setPrice({ ...price, proKid: event.target.value });
        updatePrice("proKid", event.target.value);
        break;
      case "propet":
        changeSix(event, "proPet");
        break;
      case "taxperson":
        changeSix(event, "taxPerson");
        break;
      case "taxkid":
        changeSix(event, "taxKid");
        break;
      case "taxpet":
        changeSix(event, "taxPet");
        break;
      case "electricity":
        changeSix(event, "electricity");
        break;
      case "water":
        changeSix(event, "water");
        break;
      case "shower":
        changeSix(event, "shower");
        break;
      case "wc":
        changeSix(event, "wc");
        break;
      case "casset":
        changeSix(event, "casset");
        break;
      case "ground":
        changeSix(event, "ground");
        break;
      case "wlan":
        changeSix(event, "wlan");
        break;
      case "trashbin":
        changeSix(event, "trashbin");
        break;
      case "wash":
        changeSix(event, "wash");
        break;
      case "barrier":
        setPrice({ ...price, barrier: event.target.value });
        updatePrice("barrier", event.target.value);
        break;
      case "playground":
        setPrice({ ...price, playground: event.target.value });
        updatePrice("playground", event.target.value);
        break;
      default:
        console.log("No such day exists!");
        break;
    }
  };

  const reloadState = (id: any) => {
    const data = id.data as string;
    setStatus({ ...status, [data]: true });
    setPrice({ ...price, [data]: priceLoad[data] as string });
    updatePrice(data, priceLoad[data] as string);
    if (id.status && id.status === true) {
      setSecondDropdown({ ...secondDropdown, [data]: false });
    }
  };

  const BackButton = (data: any) => (
    <IconButton onClick={() => reloadState(data)}>
      <ReplayIcon />
    </IconButton>
  );
  return (
    <Grid container spacing={1}>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          {t("label_price_information")}:
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="zweipersonen"
            select={status.zweipersonen}
            placeholder="0,00 €"
            label={"2 " + t("persons") + ":"}
            value={price.zweipersonen}
            name="zweipersonen"
            onChange={handleChange}
            InputProps={
              status.zweipersonen === false ? { endAdornment: <BackButton data="zweipersonen" /> } : {}
            }
          >
            {persons.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="properson"
            select={status.proPerson}
            placeholder="0,00 €"
            label={t("per_additional_person") + ":"}
            value={price.proPerson}
            name="properson"
            onChange={handleChange}
            InputProps={status.proPerson === false ? { endAdornment: <BackButton data="proPerson" /> } : {}}
          >
            {includes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="prokid"
            select={status.proKid}
            label={t("label_price_child") + ":"}
            placeholder="0,00 €"
            value={price.proKid}
            name="prokid"
            onChange={handleChange}
            InputProps={status.proKid === false ? { endAdornment: <BackButton data="proKid" /> } : {}}
          >
            {includes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="propet"
            select={status.proPet}
            label={t("label_pet_cost") + ":"}
            value={price.proPet}
            placeholder="0,00 €"
            name="propet"
            onChange={handleChange}
            InputProps={
              status.proPet === false ? { endAdornment: <BackButton data="proPet" status={true} /> } : {}
            }
          >
            {secondDropdown.proPet === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={4}></Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={2} display={{ xs: "none", lg: "block" }}></Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="taxperson"
            select={status.taxPerson}
            label={t("label_visitor_tax") + ":"}
            value={price.taxPerson}
            name="taxperson"
            placeholder="0,00 €"
            onChange={handleChange}
            InputProps={
              status.taxPerson === false
                ? {
                    endAdornment: <BackButton data="taxPerson" status={true} />,
                  }
                : {}
            }
          >
            {secondDropdown.taxPerson === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="taxkid"
            select={status.taxKid}
            label={t("label_visitor_tax_kind") + ":"}
            placeholder="0,00 €"
            value={price.taxKid}
            name="taxkid"
            onChange={handleChange}
            InputProps={
              status.taxKid === false ? { endAdornment: <BackButton data="taxKid" status={true} /> } : {}
            }
          >
            {secondDropdown.taxKid === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="taxpet"
            select={status.taxPet}
            label={t("label_visitor_tax_hund") + ":"}
            placeholder="0,00 €"
            value={price.taxPet}
            name="taxpet"
            onChange={handleChange}
            InputProps={
              status.taxPet === false ? { endAdornment: <BackButton data="taxPet" status={true} /> } : {}
            }
          >
            {secondDropdown.taxPet === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={4}></Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="electricity"
            select={status.electricity}
            label={t("label_electricity") + ":"}
            placeholder="0,00 €"
            value={price.electricity}
            name="electricity"
            onChange={handleChange}
            InputProps={
              status.electricity === false
                ? {
                    endAdornment: <BackButton data="electricity" status={true} />,
                  }
                : {}
            }
          >
            {secondDropdown.electricity === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="water"
            select={status.water}
            placeholder="0,00 €"
            label={t("label_water") + ":"}
            value={price.water}
            name="water"
            onChange={handleChange}
            InputProps={
              status.water === false ? { endAdornment: <BackButton data="water" status={true} /> } : {}
            }
          >
            {secondDropdown.water === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="shower"
            select={status.shower}
            placeholder="0,00 €"
            label={t("label_shower_costs") + ":"}
            value={price.shower}
            name="shower"
            onChange={handleChange}
            InputProps={
              status.shower === false ? { endAdornment: <BackButton data="shower" status={true} /> } : {}
            }
          >
            {secondDropdown.shower === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="wc"
            select={status.wc}
            placeholder="0,00 €"
            label={t("label_toilet_cost") + ":"}
            value={price.wc}
            name="wc"
            onChange={handleChange}
            InputProps={status.wc === false ? { endAdornment: <BackButton data="wc" status={true} /> } : {}}
          >
            {secondDropdown.wc === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="casset"
            select={status.casset}
            label={t("casette_disposal") + ":"}
            value={price.casset}
            placeholder="0,00 €"
            name="casset"
            onChange={handleChange}
            InputProps={
              status.casset === false ? { endAdornment: <BackButton data="casset" status={true} /> } : {}
            }
          >
            {secondDropdown.casset === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="ground"
            select={status.ground}
            placeholder="0,00 €"
            label={t("label_disposal_cost") + ":"}
            value={price.ground}
            name="ground"
            onChange={handleChange}
            InputProps={
              status.ground === false ? { endAdornment: <BackButton data="ground" status={true} /> } : {}
            }
          >
            {secondDropdown.ground === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="wlan"
            select={status.wlan}
            label={t("label_wifi_cost") + ":"}
            value={price.wlan}
            placeholder="0,00 €"
            name="wlan"
            onChange={handleChange}
            InputProps={
              status.wlan === false ? { endAdornment: <BackButton data="wlan" status={true} /> } : {}
            }
          >
            {secondDropdown.wlan === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="trashbin"
            select={status.trashbin}
            label={t("label_rubbish") + ":"}
            placeholder="0,00 €"
            value={price.trashbin}
            name="trashbin"
            onChange={handleChange}
            InputProps={
              status.trashbin === false ? { endAdornment: <BackButton data="trashbin" status={true} /> } : {}
            }
          >
            {secondDropdown.trashbin === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="wash"
            select={status.wash}
            label={t("washing_machine") + ":"}
            value={price.wash}
            placeholder="0,00 €"
            name="wash"
            onChange={handleChange}
            InputProps={
              status.wash === false ? { endAdornment: <BackButton data="wash" status={true} /> } : {}
            }
          >
            {secondDropdown.wash === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="barrier"
            select={status.barrier}
            label={t("accessibility") + ":"}
            value={price.barrier}
            name="barrier"
            onChange={handleChange}
          >
            {yesno.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            style={{ minWidth: minWidth }}
            fullWidth
            id="playground"
            select={status.playground}
            label={t("playground") + ":"}
            value={price.playground}
            name="playground"
            onChange={handleChange}
          >
            {yesno.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}></Grid>
      </Grid>
    </Grid>
  );
}
