import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { formStore } from "./State/state";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const { updateContact } = formStore();
  const handleChangeState = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    updateContact(event);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div">
          {t("contact_info")}:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="name"
          fullWidth
          label={t("label_name") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="street"
          fullWidth
          label={t("label_street_and_house_number") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="phone"
          fullWidth
          label={t("label_phone") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="postCode"
          fullWidth
          label={t("label_areaCode") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="email"
          fullWidth
          label={t("label_search_email") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="city"
          fullWidth
          label={t("label_area") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="homepage"
          fullWidth
          label={t("label_homepage") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="state"
          fullWidth
          label={t("federal_state") + ":"}
          variant="outlined"
          onBlur={(e) => handleChangeState(e)}
        />
      </Grid>
    </Grid>
  );
}
