import * as React from "react";
import { Grid } from "@material-ui/core";

export interface CaravanSize {
  background: string;
  label: string;
  price: string;
  description: string;
  priceValue: number;
}

type CaravanSizeProps = {
  caravanSize: CaravanSize;
  selected?: boolean;
  isLegend?: boolean;
  // price: string;
  color: string;
};

export const CaravanSizeComponent: React.FC<CaravanSizeProps> = ({
  caravanSize,
  color,
  // price,
  isLegend,
  selected,
}) => {
  return (
    <Grid
      style={{
        border: selected ? "0.5px grey solid" : "0.5px white solid",
        background: selected ? "#00713C" : "",
        color: selected ? "white" : "",
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {!isLegend && (
        <Grid item>
          <div
            style={{
              margin: "5px",
              fontSize: isLegend ? "15px" : "24px",
              width: isLegend ? "75px" : "175px",
              height: "50px",
              lineHeight: "50px",
              fontWeight: "bold",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            {caravanSize.description}
          </div>
        </Grid>
      )}
      <Grid item>
        <div
          style={{
            background: color,
            color: "black",
            fontSize: "36px",
            width: isLegend ? "75px" : "125px",
            fontWeight: "bolder",
            border: "0.5px solid black",
            height: "40px",
            textAlign: "center",
            verticalAlign: "middle",
            lineHeight: "40px",
          }}
        >
          {caravanSize.label}
        </div>
      </Grid>
      {/* !! price removed */}
      {/* <Grid item>
        <div
          style={{
            fontSize: isLegend ? "15px" : "24px",
            width: isLegend ? "60px" : "125px",
            fontWeight: "bold",
            height: "40px",
            marginLeft: "5px",
            textAlign: "left",
            verticalAlign: "middle",
            lineHeight: "50px",
          }}
        >
          {price + " €"}
        </div>
      </Grid> */}
    </Grid>
  );
};
