import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Grid,
  Chip,
  Checkbox,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import {
  ActivitiesList,
  ActivitiesListEn,
  FittingsList,
  FittingsListEn,
  SightseeingList,
  SightseeingListEn,
  TransportList,
  TransportListEn,
} from "constants/campCardItems";
import { FittingsImages, ActivitiesImages, SightseeingImages, TransportImages } from "helpers";
import { campCardServices, campCardActivities } from "constants/campCardItems";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      minWidth: 320,
      height: 218,
    },
    facilitiesList: {
      marginTop: 8,
    },
    facilities: {
      marginRight: 4,
      marginBottom: 2,
    },
    chip: {
      margin: "4px 5px",
    },
  })
);

export const MarkerCampCard = ({ item }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card style={{ maxWidth: 350 }} key={item.id + "c"}>
      <CardActionArea component={Link} to={{ pathname: `/parkingAreal/${item.id}`, state: item }}>
        <CardMedia
          className={classes.media}
          image={
            item.image != ""
              ? item.image
              : `/assets/images/placeholders/${item.location.lat.toString().slice(-1)}.jpg`
          }
          title={item.name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={item.camp ? { color: "#0069B4", fontWeight: 500 } : { fontWeight: 500 }}
          >
            {item.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("city")}: {item.area}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("number_of_parking_lots")}: {item.amountOfSpots}
          </Typography>
          <div className={classes.facilitiesList}>
            {Object?.entries(item.fittingsList)?.map((option: any, v: any) =>
              item.fittingsList[FittingsListEn[v]] === true ? (
                <Tooltip
                  title={FittingsList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "2px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.fittingsList[FittingsListEn[v]]}
                    // onClick={(e) => console.log(FittingsImages)}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? FittingsImages["Blue/" + v + ".png"].default
                            : FittingsImages["Green/" + v + ".png"].default
                        }
                        height={24}
                        width={24}
                        alt="activities"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}

            <br />

            {Object.entries(item.activitiesList).map((option: any, v: any) =>
              item.activitiesList[ActivitiesListEn[v]] === true ? (
                <Tooltip
                  title={ActivitiesList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "6px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.activitiesList[ActivitiesListEn[v]]}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? ActivitiesImages["Blue/" + v + ".png"].default
                            : ActivitiesImages["Green/" + v + ".png"].default
                        }
                        height={24}
                        width={24}
                        alt="activities"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}

            <br />
            {Object.entries(item.sightseeingList).map((option: any, v: any) =>
              item.sightseeingList[SightseeingListEn[v]] === true ? (
                <Tooltip
                  title={SightseeingList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "6px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.sightseeingList[SightseeingListEn[v]]}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? SightseeingImages["Blue/" + v + ".png"].default
                            : SightseeingImages["Green/" + v + ".png"].default
                        }
                        height={24}
                        width={24}
                        alt="activities"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}

            <br />
            {Object.entries(item.transportList).map((option: any, v: any) =>
              item.transportList[TransportListEn[v]] === true ? (
                <Tooltip
                  title={TransportList[v]}
                  arrow
                  key={option}
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "2px",
                    paddingTop: "6px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={item.transportList[TransportListEn[v]]}
                    id={option + v}
                    color="primary"
                    disableRipple={true}
                    size="small"
                    checkedIcon={
                      <img
                        src={
                          !item.bookable
                            ? TransportImages["Blue/" + v + ".png"].default
                            : TransportImages["Green/" + v + ".png"].default
                        }
                        height={24}
                        width={24}
                        alt="activities"
                      />
                    }
                  />
                </Tooltip>
              ) : (
                ""
              )
            )}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container>
          <Grid item sm={6}>
            <Chip
              variant="outlined"
              className={classes.chip}
              label={
                <section>
                  <div color="textSecondary">
                    {t("price")?.toUpperCase()}:
                    <span style={{ color: "#d32121" }}>{"ab " + item.mainPriceUI}</span>
                  </div>
                </section>
              }
            />
          </Grid>
          <Grid item sm={4} />
          <Grid item sm={2}></Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
