import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { AuthContext } from "../context/Auth";

export type PrivateRouteProps = RouteProps & {};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext) as any;
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser && RouteComponent ? <RouteComponent {...routeProps} /> : <Redirect to={"/login"} />
      }
    />
  );
};
