import React, { useContext, useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { FirebaseAuth } from "services/firebase/Firebase";
import { useTranslation, withTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { DateRangePicker } from "react-dates";
import "../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { AppBarMain } from "components/AppBar";
import { useUserData } from "context";
import {
  useUserRole,
  useUserOwn,
  useParkingAreaById,
  useBookmarkFolderAll,
  useCreateBookmark,
  useCommentsOfParkingAreal,
  useCreateBookmarkFolder,
  useBookmarksAll,
} from "rest";
import { useParams } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { AuthContext } from "../../../context/Auth";
import HorizontalLabelPositionBelowStepper2 from "components/Booking/Stepper";
import {
  Map as MapIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Info as InfoIcon,
} from "@material-ui/icons/";
// import "./pstyles.css";
import moment from "moment";
import "moment/locale/de";
// import { Cookies } from "components";
import {
  MainCard,
  CommentArea,
  PriceInfoDefaultCard,
  PriceInfoBookableCard,
  PriceInfoBookableRevisionCard,
  UploadImage,
  GeneralInfoCard,
  ContactInfoCard,
  TouristInfoModal,
  PromotionInfoCard,
  ScoutInfoCard,
} from "components/AreaDetail";

import useStyles from "./style";
import {
  Dialog,
  Slide,
  useScrollTrigger,
  Zoom,
  useMediaQuery,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  CssBaseline,
  Drawer,
  Grid,
  Paper,
  Container,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  DialogContentText,
  ListSubheader,
  CardActions,
  Fab,
  MuiThemeProvider,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useAreaDetailState } from "context/AreaDetail";
import { ICampingAreaDetail } from "interfaces/areaDetail/ICampingAreaDetail";
import { TransitionProps } from "@material-ui/core/transitions";
import { useUserArealEditToken } from "rest/user-service/queries/user-areal-edit-token";
import { themeUnbookable, themeBookable } from "constants/theming";
import PreReserveStepper from "components/PreBooking";

import queryString from "query-string";
import { bookingPaymentConfirm, preBookingPaymentConfirm } from "rest/AdminRestClient";
import PaymentStatus from "components/PreBooking/PaymentStatus";

const StyledDialog = withStyles({
  paper: {
    overflowY: "auto",
  },
})(Dialog);

const dist = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515 * 1.609344;
  return dist.toFixed(2);
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

type own = {
  address_valid: boolean;
  has_traveller: boolean;
  person_valid: boolean;
  vehicle_valid: boolean;
};

function ScrollTop(props: Props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick2 = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick2} role="presentation" className={classes.rootx}>
        {children}
      </div>
    </Zoom>
  );
}

function ArealImp(props: any) {
  const { currentUser }: any = useContext(AuthContext);
  const location = useLocation<any>();
  const {
    setTotalRating,
    comment,
    setComment,
    galerie,
    setGalerie,
    uploadSuccess,
    uploadError,
    bookmarkForm,
    setBookmarkForm,
    setExpControl,
    setTotalComments,
    setClosestBakery,
    setInfos,
    touristInfoTab,
  } = useAreaDetailState();

  const history = useHistory();
  let { id }: any = useParams();
  const classes = useStyles();
  const [own, setOwn] = useState<own>({
    address_valid: false,
    has_traveller: false,
    person_valid: false,
    vehicle_valid: false,
  });

  const [token, setToken] = React.useState<any>("");
  const [pageUrl, setPageUrl] = React.useState<any>("");

  const [images, setImages] = React.useState<any>([]);
  const [item, setItem] = useState<any>(null);
  const [getEndDate, setEndDate] = useState<any>(null);
  const [getStartDate, setStartDate] = useState<any>(null);
  const [folders, setFolders] = React.useState<any>([]);
  const [dialog, setDialog] = React.useState<boolean>(false);
  const [dialogAlert, setDialogAlert] = React.useState<boolean>(false);
  const [dialogAlertB, setDialogAlertB] = React.useState<boolean>(false);
  const [preReserveDialog, setPreReserveDialog] = React.useState<boolean>(false);
  const [getOpenMap, setOpenMap] = useState<any>(false);
  const [getFocusedInput, setFocusedInput] = useState<any>(null);
  const [lat, setLat] = useState<String>("");
  const [lng, setLng] = useState<String>("");
  const [user, setUser] = React.useState<any>(null);
  const [bookmarkFolderId, setBookmarkFolderId] = React.useState<any>("");
  const [commentx, setCommentx] = React.useState<boolean>(false);
  const [copyOpen, setCopyOpen] = React.useState<boolean>(false);
  const [openImageBackdrop, setOpenImageBackdrop] = React.useState(false);
  const [vehicleType, setVehicleType] = React.useState("B");
  const [tentSize, setTentSize] = React.useState("P");
  const [cabinSize, setCabinSize] = React.useState("V");
  const [activeSize, setActiveSize] = React.useState("vehicle");
  const matches = useMediaQuery("(min-width:510px)");
  const getPosition = (position: any) => {
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);
  };
  const [loading, setLoading] = React.useState(true);
  const [hearth, setHearth] = React.useState(false);
  const [dialogOpenPhoto, setDialogOpenPhoto] = useState(false);
  const { t } = useTranslation();
  const mags = [
    {
      original: `/assets/images/placeholders/1.jpg`,
    },
    {
      original: `/assets/images/placeholders/2.jpg`,
    },
    {
      original: `/assets/images/placeholders/3.jpg`,
    },
    {
      original: `/assets/images/placeholders/4.jpg`,
    },
    {
      original: `/assets/images/placeholders/5.jpg`,
    },
  ];
  // PreBooking
  const [newFolderForm, setNewFolderForm] = useState(false);
  const [folderName, setFolderName] = useState<string>("");
  const [selected, setSelected] = useState<boolean>(false);
  const [spotId, setSpotId] = React.useState("");
  const [spotName, setSpotName] = React.useState("");
  const [codeInput, setCodeInput] = useState(false);
  const [preBookingExist, setPreBookingExist] = useState(false);
  const dialogRef = React.useRef(null);
  const [preBookingData, setPreBookingData] = useState<any>({
    firstName: "",
    lastName: "",
    numberPlate: "",
    description: "",
    mobile: "",
    email: "",
    code: "",
  });

  const [preBookingError, setPreBookingError] = useState<any>({
    firstName: false,
    lastName: false,
    numberPlate: false,
    email: false,
    mobile: false,
  });
  const [pp, setPp] = useState<any>("y");

  const [paymentStatuss, setPaymentStatuss] = useState<any>({ isOpen: false, status: "success" });

  const reducer = (accumulator, item) => {
    return (
      accumulator +
      (item.location + item.noiseLevel + item.facilities + item.cleanliness + item.price + item.activity) / 6
    );
  };

  const { refetch: getBookmarksInt } = useBookmarksAll({
    enabled: false,
    onSuccess: (results: Array<any>) => {
      for (let index = 0; index < results.length; index++) {
        results[index].bookmarks.some((e: any) => e.parkingArealId == id) && setHearth(true);
      }
    },
    onError: (error: any) => console.log(error),
  });

  const { getUserData, setUserData } = useUserData();

  const handleReset = () => {
    setPreBookingData({
      firstName: "",
      lastName: "",
      numberPlate: "",
      description: "",
      mobile: "",
      email: "",
      code: "",
    });
    setSpotId("");
    setSpotName("");
    setPreBookingError({
      firstName: false,
      lastName: false,
      numberPlate: false,
      email: false,
      mobile: false,
    });
    setCodeInput(false);
    const initialUserData = {
      baseInfo: {
        arrivalDate: "",
        departureDate: "",
        vehicleSize: "",
        spotName: "",
        numberplate: "",
        phone: "",
        phoneCode: "",
        mail: "",
        firstName: "",
        lastName: "",
        adultCount: "",
        childCount: "",
        petCount: "",
      },
      address: {
        area: "",
        areaCode: "",
        countryCode: "",
        streetAndHouseNumber: "",
        birthDate: null,
        birthPlace: "",
        identityCardNo: "",
        validUntil: null,
        nationality: "",
      },
      travellers: [],
      status: "new",
    };
    setUserData(initialUserData);
    // setActiveStep(0);
  };

  useEffect(() => {
    let query = queryString.parse(location.search);
    setPp(query.pp);
    if (query.status === "success") {
      if (query.pp == "n") {
        preBookingPaymentConfirm({ token: query.token, status: query.status })
          .then((result) => {
            if (result.data) {
              setPaymentStatuss({ isOpen: true, status: "success" });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        bookingPaymentConfirm({ token: query.token, status: query.status })
          .then((result) => {
            if (result.data) {
              setPaymentStatuss({ isOpen: true, status: "success" });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (query.status === "fail") {
      if (query.pp == "n") {
        preBookingPaymentConfirm({ token: query.token, status: query.status })
          .then((result) => {
            if (result.data) {
              setPaymentStatuss({ isOpen: true, status: "fail" });
              setPageUrl(result.data.pageUrl);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        bookingPaymentConfirm({ token: query.token, status: query.status })
          .then((result) => {
            if (result.data) {
              setPaymentStatuss({ isOpen: true, status: "fail" });
              setPageUrl(result.data.message.pageUrl);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, []);

  const openNewFolderForm = () => {
    setNewFolderForm(true);
  };

  const closeNewFolderForm = () => {
    setNewFolderForm(false);
    setFolderName("");
  };

  const { mutate: createBookmarkFolder } = useCreateBookmarkFolder({
    onSuccess: () => {
      setNewFolderForm(false);
      setFolderName("");
      getBookmarkFolderAll();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const createNewFolder = () => {
    createBookmarkFolder({ name: folderName });
  };

  const { mutate: createBookmark } = useCreateBookmark({
    onSuccess: () => {
      setBookmarkForm(false);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { refetch: getUserOwn } = useUserOwn({
    enabled: false,
    onSuccess: (data) => {
      setOwn(data);
    },
  });

  const { mutate: getUserArealEditToken } = useUserArealEditToken({
    onSuccess: (data) => {
      // window.location.href = data;
      window.location.href = `https://meinplatz.camp24checkin.de/orx/token=${data}`;
      // window.location.href = `http://localhost:3000/orx/token=${data}`;
      // setToken(data);
    },
  });

  const { refetch: getBookmarkFolderAll } = useBookmarkFolderAll({
    enabled: false,
    onSuccess: (data) => {
      setFolders(data);
    },
    onError: (error) => console.error(error),
  });

  const { refetch: getUserRole } = useUserRole({
    enabled: false,
    onSuccess: (data) => {
      setUser(data.user);
      setVehicleType(data.vehicleType);
      getBookmarkFolderAll();
      getBookmarksInt();
      getUserOwn();
    },
    onError: (error) => {
      FirebaseAuth.signOut()
        .then((_) => console.log("user failed"))
        .catch((reason) => console.log(reason));
      console.info(error);
    },
  });

  const { refetch: getParkingArealById } = useParkingAreaById<ICampingAreaDetail>(
    {
      id,
    },
    {
      enabled: !id,
      onSuccess: (parkingAreaDetailData) => {
        setItem(parkingAreaDetailData);
        if (parkingAreaDetailData.image) {
          let mages = parkingAreaDetailData.image.map((a: any) => {
            return { original: a.url, desc: a.description ? a.description : "" };
          });
          if (mages.length > 0) {
            setImages(mages);
          }
        }
        setLoading(false);
      },
      onError: (error) => console.error(error),
    }
  );

  const { refetch: getCommentsOfParkingAreal } = useCommentsOfParkingAreal(
    { id },
    {
      enabled: !id,
      onSuccess: (data) => {
        setComment(data);
        let arr: any = [];
        for (let i = 0; i < data.length; i++) {
          arr.push(false);
        }
        setExpControl(arr);
        const initialValue = 0;
        const total = data.reduce(reducer, initialValue) / data.length;

        setTotalRating(total);
        setTotalComments(data.length);
      },
      onError: (error) => console.error(error),
    }
  );

  useEffect(() => {
    if (currentUser) {
      if (currentUser.emailVerified === false) {
        history.push("/user/verify");
      } else {
        getUserRole();
      }
    }

    setImages(mags);

    getParkingArealById();

    if (location.state) {
      if (location.state.startDate) {
        const todaymoment = moment();
        todaymoment.set("hour", 9);
        if (moment(location.state.startDate).isBefore(todaymoment)) {
          setStartDate(todaymoment);
        } else {
          setStartDate(moment(location.state.startDate).set("hour", 9));
        }
      }
      if (location.state.endDate) {
        setEndDate(moment(location.state.endDate).set("hour", 9));
      }
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition);
    }

    getCommentsOfParkingAreal();
  }, []);

  const openCopyDialog = () => {
    setCopyOpen(true);
    navigator.clipboard.writeText(window.location.href);
  };

  const openDialog = () => {
    if (currentUser) {
      if (own.vehicle_valid) {
        setDialog(true);
      } else {
        setDialogAlert(true);
      }
    } else {
      // setDialogAlertB(true);
      setPreReserveDialog(true);
    }
  };

  const openClosestBakery = () => {
    setInfos(
      item.info.filter(function (el) {
        return el.mode === touristInfoTab;
      })
    );
    setClosestBakery(true);
  };

  const openMap = () => {
    setOpenMap(!getOpenMap);
  };

  const galerieHandle = (event: React.MouseEvent<HTMLDivElement>) => {
    setGalerie(true);
    setCommentx(false);
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#galerie");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const commentHandle = (event: React.MouseEvent<HTMLDivElement>) => {
    setCommentx(true);
    setGalerie(false);
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#comment");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const Map = withGoogleMap(() => (
    <GoogleMap defaultZoom={12} defaultCenter={item && { lat: +item.lat, lng: +item.lon }}>
      <Marker
        icon="/assets/images/marker/marker3.png"
        position={item && { lat: +item.lat, lng: +item.lon }}
      />
    </GoogleMap>
  ));

  const saveBookmarkForm = () => {
    let data = {
      bookmarkFolderId: bookmarkFolderId,
      name: item.name,
      parkingArealId: item.id,
    };
    createBookmark(data);
  };

  const isOutsideRange = (day) => {
    let dayIsBlocked = true;
    const dayOfYear = day.dayOfYear();
    if (dayOfYear > item.seasonStart.day) {
      dayIsBlocked = false;
    }
    if (dayOfYear > item.seasonEnd.day) {
      dayIsBlocked = true;
    }
    if (day.year() != item.seasonStart.year) {
      dayIsBlocked = true;
    }
    return dayIsBlocked;
  };

  return item ? (
    <MuiThemeProvider theme={item.bookable ? themeBookable : themeUnbookable}>
      <React.Fragment>
        <Backdrop
          className={classes.backdrop}
          open={openImageBackdrop}
          onClick={() => {
            setOpenImageBackdrop(false);
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <AppBarMain location={"parkingAreal"} />
        {/* <Cookies /> */}
        <Drawer
          anchor="top"
          open={getOpenMap}
          onClose={openMap}
          variant={"permanent"}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: getOpenMap,
            [classes.drawerClose]: !getOpenMap,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: getOpenMap,
              [classes.drawerClose]: !getOpenMap,
            }),
          }}
          id="back-to-top-anchor"
        >
          <Grid container spacing={0} style={{ marginTop: "auto" }}>
            {getOpenMap && (
              <Grid item xs={12} md={12}>
                <br />
                <br />
                <br />
                <Map
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
                <br />
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Paper elevation={0} style={{ marginBottom: "9px" }}>
                <Container maxWidth="lg">
                  <Grid container spacing={1} justifyContent="space-between" className={classes.topMenu}>
                    <Grid item xs={"auto"} sm={2} md={2} lg={2}>
                      <Button
                        variant="contained"
                        color={galerie ? "primary" : "secondary"}
                        disableElevation
                        size={!matches ? "small" : "medium"}
                        onClick={(event: any) => galerieHandle(event)}
                        style={{ marginRight: "10px" }}
                      >
                        {t("gallery")}
                      </Button>
                      <Button
                        variant="contained"
                        color={commentx ? "primary" : "secondary"}
                        disableElevation
                        size={!matches ? "small" : "medium"}
                        onClick={(event: any) => commentHandle(event)}
                      >
                        {/* setCommentx triggers inside commentHandle */}
                        {t("label_reviews")}
                      </Button>
                    </Grid>
                    <Grid item xs={"auto"} sm={6} md={6} lg={4} style={{ textAlign: "right" }}>
                      {item && item.info && item.info.length > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<InfoIcon />}
                          disableElevation
                          size={!matches ? "small" : "medium"}
                          onClick={openClosestBakery}
                          style={{ marginRight: "10px" }}
                        >
                          {t("turist_info")}
                        </Button>
                      )}
                      {/* Tourist Info Modal */}
                      <TouristInfoModal item={item} />

                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        size={!matches ? "small" : "medium"}
                        endIcon={<MapIcon />}
                        onClick={openMap}
                      >
                        {!getOpenMap === true ? t("show_map") : t("hide_map")}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Grid>
          </Grid>
        </Drawer>
        {item && comment && (
          <Container
            maxWidth="lg"
            className={clsx(classes.container, {
              [classes.containerShift]: getOpenMap,
            })}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <MainCard
                  images={images}
                  item={item}
                  commentHandle={commentHandle}
                  dist={dist}
                  openBookmarkForm={() => {
                    setBookmarkForm(true);
                  }}
                  openCopyDialog={openCopyDialog}
                  lat={lat}
                  lng={lng}
                  user={user}
                  hearth={hearth}
                  getUserArealEditToken={getUserArealEditToken}
                />
                <Dialog
                  open={copyOpen}
                  onClose={() => {
                    setCopyOpen(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title" style={{ color: "#434d47" }}>
                    {t("link_copied_clipboard")}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setCopyOpen(false);
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t("label_ok")}
                    </Button>
                  </DialogActions>
                </Dialog>
                {currentUser && user && (
                  <Dialog
                    open={bookmarkForm}
                    onClose={() => {
                      setBookmarkForm(false);
                    }}
                  >
                    <DialogContent>
                      <FormControl fullWidth>
                        <InputLabel id="select">{t("select_folder")}</InputLabel>
                        <Select
                          fullWidth
                          labelId="select"
                          onChange={(e) => {
                            setBookmarkFolderId(e.target.value);
                          }}
                        >
                          {user &&
                            folders.map((bookmark: any, index: number) => (
                              <MenuItem value={bookmark.id} key={index}>
                                {bookmark.name === "default" ? t("standard") : bookmark.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </DialogContent>

                    <DialogActions>
                      <Button color="primary" onClick={openNewFolderForm}>
                        {t("create_new_folder")}
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          setBookmarkForm(false);
                        }}
                      >
                        {t("label_cancel")}
                      </Button>
                      <Button onClick={saveBookmarkForm} disableElevation variant="contained" color="primary">
                        {t("add")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                {currentUser && user && (
                  <Dialog open={newFolderForm} onClose={closeNewFolderForm}>
                    <DialogContent style={{ width: 400 }}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          margin="dense"
                          label={t("favourites")}
                          onChange={(e) => setFolderName(e.target.value)}
                          autoFocus
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeNewFolderForm}>{t("label_cancel")}</Button>
                      <Button onClick={createNewFolder} color="primary">
                        {t("create")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}

                <CommentArea id={id} item={item} />

                <Collapse in={uploadSuccess}>
                  <Alert severity="success"> {t("label_file_upload_success")}</Alert>
                </Collapse>
                <Collapse in={uploadError}>
                  <Alert severity="error"> {t("label_file_upload_fail")}</Alert>
                </Collapse>
                {/* upload image dialog */}
                <Dialog
                  open={dialogOpenPhoto}
                  onClose={() => {
                    setDialogOpenPhoto(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("label_wrong_image_format_size_title")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("label_wrong_image_format_or_size", { sizeLimit: "10" })}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setDialogOpenPhoto(false);
                      }}
                      color="primary"
                    >
                      {t("label_ok")}
                    </Button>
                  </DialogActions>
                </Dialog>
                {currentUser && (
                  <UploadImage
                    id={id}
                    setDialogOpenPhoto={setDialogOpenPhoto}
                    setOpenImageBackdrop={setOpenImageBackdrop}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {item.bookable ? (
                  // <PriceInfoBookableCard item={item} vehicleType={vehicleType} />
                  <PriceInfoBookableRevisionCard item={item} vehicleType={vehicleType} />
                ) : (
                  <PriceInfoDefaultCard item={item} vehicleType={vehicleType} />
                )}
                {Object.keys(item.promotion).filter((i) => item.promotion[i] === true).length > 0 && (
                  <PromotionInfoCard item={item} />
                )}
                {item.bookable && (
                  <Card style={{ marginBottom: 18, overflow: "visible" }}>
                    <List
                      dense={true}
                      subheader={<ListSubheader color="primary">{t("book")}</ListSubheader>}
                    ></List>
                    <CardContent className={classes.dateCard}>
                      <div className={classes.dateRangePicker}>
                        <DateRangePicker
                          startDate={getStartDate}
                          startDateId="startDate"
                          endDate={getEndDate}
                          isOutsideRange={isOutsideRange}
                          endDateId="endDate"
                          onDatesChange={({ startDate, endDate }) => {
                            // @ts-ignore
                            setStartDate(startDate != null ? startDate.set("hour", 9) : startDate);
                            setEndDate(endDate != null ? endDate.set("hour", 9) : endDate);
                          }}
                          startDatePlaceholderText={t("label_startDate")}
                          endDatePlaceholderText={t("label_endDate")}
                          focusedInput={getFocusedInput}
                          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        />
                      </div>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={openDialog}
                        disabled={!getStartDate || (!getEndDate && true)}
                      >
                        {t("reserve_now")}
                      </Button>
                    </CardActions>
                  </Card>
                )}

                <Dialog
                  open={dialogAlert}
                  onClose={() => {
                    setDialogAlert(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{t("vehicle_needed_for_booking")}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("before_reservation_add_vehicle")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setDialogAlert(false);
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t("label_ok")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={dialogAlertB}
                  onClose={() => {
                    setDialogAlertB(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{t("login_required")}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("booking_reservation_needed")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setDialogAlertB(false);
                      }}
                      style={{ backgroundColor: "#ff9800", color: "#fff" }}
                      autoFocus
                      variant="contained"
                      disableElevation
                    >
                      {t("label_cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        history.push("/login");
                      }}
                      color="primary"
                      // color="warning"
                      autoFocus
                      variant="contained"
                      disableElevation
                    >
                      {t("label_login")}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={paymentStatuss.isOpen}
                  fullScreen
                  onClose={() => {
                    setPaymentStatuss({ isOpen: false, status: "success" });
                  }}
                  scroll={"body"}
                  maxWidth="xl"
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent ref={dialogRef}>
                    <PaymentStatus
                      status={paymentStatuss}
                      pageUrl={pageUrl}
                      setPaymentStatus={setPaymentStatuss}
                      pp={pp}
                    />
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={preReserveDialog}
                  fullScreen
                  onClose={() => {
                    setPreReserveDialog(false);
                  }}
                  scroll={"body"}
                  maxWidth="xl"
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent ref={dialogRef}>
                    <PreReserveStepper
                      setVehicleType={setVehicleType}
                      vehicleType={vehicleType}
                      parkAreal={item}
                      startDate={getStartDate}
                      endDate={getEndDate}
                      selected={selected}
                      setSelected={setSelected}
                      spotId={spotId}
                      setSpotId={setSpotId}
                      setSpotName={setSpotName}
                      spotName={spotName}
                      setPreBookingData={setPreBookingData}
                      getStartDate={getStartDate}
                      getEndDate={getEndDate}
                      preBookingData={preBookingData}
                      setPreBookingError={setPreBookingError}
                      preBookingError={preBookingError}
                      codeInput={codeInput}
                      setCodeInput={setCodeInput}
                      setPreBookingExist={setPreBookingExist}
                      preBookingExist={preBookingExist}
                      situation={() => {
                        setPreReserveDialog(false);
                      }}
                      handleReset={handleReset}
                      dialogRef={dialogRef}
                      setTentSize={setTentSize}
                      setCabinSize={setCabinSize}
                      tentSize={tentSize}
                      cabinSize={cabinSize}
                      activeSize={activeSize}
                      setActiveSize={setActiveSize}
                    />
                  </DialogContent>
                </Dialog>
                <StyledDialog
                  fullScreen
                  open={dialog}
                  TransitionComponent={Transition}
                  onClose={() => {
                    setDialog(false);
                  }}
                >
                  <DialogContent style={{ paddingTop: "5px" }}>
                    <HorizontalLabelPositionBelowStepper2
                      parkAreal={item}
                      startDate={getStartDate}
                      endDate={getEndDate}
                      user={user}
                      situation={() => {
                        setDialog(false);
                      }}
                    />
                  </DialogContent>
                </StyledDialog>
                <GeneralInfoCard item={item} />
                <ContactInfoCard item={item} />
                {"scout" in item && "updatedAt" in item && <ScoutInfoCard item={item} />}
              </Grid>
            </Grid>
          </Container>
        )}
        <ScrollTop {...props}>
          <Fab color="primary" size="small" aria-label="scroll back to top" style={{ borderRadius: "10%" }}>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </React.Fragment>
    </MuiThemeProvider>
  ) : (
    <React.Fragment>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

export const Areal = withTranslation()(ArealImp);
