import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    search: {
      height: 950,
      margin: "0px 0px 24px",
      padding: "30px 0px",
      position: "relative",
      textAlign: "center",
      backgroundImage: `url("/assets/images/main.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    searchMobile: {
      minHeight: 940,
      margin: "0px 0px 24px",
      padding: "30px 0px",
      position: "relative",
      textAlign: "center",
      backgroundImage: `url("/assets/images/main.jpg")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    mainTitle: {
      marginTop: 200,
      marginBottom: 40,
      fontSize: 44,
      color: "#fff",
    },
    mainTitleMobile: {
      marginTop: 0,
      marginBottom: 30,
      fontSize: 22,
      color: "#fff",
    },
    searchForm: {
      maxWidth: 900,
      margin: "auto",
      backgroundColor: "#fff",
      borderRadius: 4,
      boxShadow: "0px 0px 0px 10px rgba(255, 255, 255, 0.3)",
    },
    form: {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11.5px 16px",
          fontWeight: 200,
          fontSize: 19,
          lineHeight: "initial",
          color: "#484848",
        },
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent",
        },
      },
    },
    formMobile: {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "11.5px 16px",
          fontWeight: 200,
          fontSize: 19,
          lineHeight: "initial",
          color: "#484848",
          textAlign: "center",
        },
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent",
        },
      },
    },
  })
);

export default useStyles;
