import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { formStore, FormState } from "./State/state";
import { useTranslation } from "react-i18next";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export function DropPicture(props: any) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const setFiles: FormState["setFiles"] = formStore((state) => state.setFiles);
  const addFiles: FormState["addFiles"] = formStore((state) => state.addFiles);
  const setFileDescription: FormState["setFileDescription"] = formStore((state) => state.setFileDescription);
  const files: FormState["files"] = formStore((state) => state.files);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles: any) => {
      if (files.length == 10 || files.length + acceptedFiles.length > 10) {
        setShowAlert(true);
      } else {
        if (files.length > 0) {
          addFiles(
            acceptedFiles.map((file: any) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          acceptedFiles.map((e: any, i: number) =>
            setFileDescription("", e.name, (i + files.length + 1).toString())
          );
        } else {
          setFiles(
            acceptedFiles.map((file: any) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );

          acceptedFiles.map((e: any, i: number) => setFileDescription("", e.name, (i + 1).toString()));
        }

        setToggle(!toggle);
      }
    },
  });

  // const thumbs = files.map((file: any) => (
  //   <div style={thumb as any} key={file.name}>
  //     <div style={thumbInner}>
  //       <img src={file.preview} style={img} alt="photos" />
  //     </div>
  //   </div>
  // ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    // @ts-ignore
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    // @ts-ignore
    // console.log(files[0].preview);
  }, [toggle]);

  return (
    <React.Fragment>
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Box
            sx={{
              p: 2,
              borderRadius: "15px",
              backgroundColor: "#7ED321",
              color: "#fafafa",
              cursor: "pointer",
            }}
          >
            <Typography variant="h6">{t("dragzone_text_image_upload")}</Typography>
          </Box>
        </div>
        {/* <aside style={thumbsContainer as any}>{thumbs}</aside> */}
      </section>
      {/* <ImageCard /> */}
    </React.Fragment>
  );
}
