import React, { useState, useContext, useEffect } from "react";
import { FirebaseAuth } from "../../services/firebase/Firebase";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Circle,
  GroundOverlay,
  BicyclingLayer,
  TrafficLayer,
  HeatmapLayer,
} from "@react-google-maps/api";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { AppBarMain } from "components/AppBar";
import {
  useUserRole,
  useUserFilters,
  useUserOwn,
  useUpdateUserFilters,
  usePublicSearch,
  usePrivateSearch,
  useAddressFromGeocode,
} from "rest";
import { AuthContext } from "../../context/Auth";
import { useTranslation, withTranslation } from "react-i18next";
import "moment/locale/de";
import { useHistory } from "react-router-dom";
import { localStorageConstants } from "constants/localStorage";
import { Cookies } from "components";
import { Filters, SearchForm, CampCard } from "components/Home";
import { useHomeState } from "context/Home";
import { distance, getBoundingBox } from "helpers";
import {
  Switch,
  useMediaQuery,
  CssBaseline,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Collapse,
  Hidden,
  Box,
} from "@material-ui/core";
import { homeStyles, BlueSwitch, GreenSwitch } from "components/Home";
import { campCardServices, defaultFilters } from "constants/campCardItems";
import { getDate } from "date-fns";
import { SearchMap } from "components/Home/googleMap";

export default withTranslation()(function Home() {
  const {
    selectedPlace,
    setSelectedPlace,
    setAddress,
    isFiltersOpen,
    setFiltersOpen,
    getStartDate,
    getEndDate,
    infoOpen,
    setInfoOpen,
    getParkingData,
    setParkingData,
    moveBox,
    setMoveBox,
  } = useHomeState();
  const [mapRef, setMapRef] = useState();
  const [latlng, setLatlng] = useState("");
  // const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const { t } = useTranslation();
  const [centerlng, setCenterlng] = useState(17.3547264);
  const [centerlat, setCenterlat] = useState(38.1681664);
  const [zoom, setZoom] = useState(5);
  //newStuff
  const classes = homeStyles();
  const matches = useMediaQuery("(min-width:959px)");
  // const [getFocusedInput, setFocusedInput] = useState<any>(null);
  // const [getParkingData, setParkingData] = useState<any>([]);
  const [getLocation, setLocation] = useState<any>(null);
  const [getViewBox, setViewBox] = useState<any>(null);
  const [isMapOpen, setMapOpen] = useState<any>(false);
  // const [moveBox, setMoveBox] = useState<any>(false);
  const [own, setOwn] = useState<any>(false);

  const [getSwitch, setSwitch] = useState<any>(false);
  const [getFilters, setFilters] = useState<any>(defaultFilters);
  const [searchParams, setSearchParams] = useState<any>({});
  const [kostenlos, setKostenlos] = useState(false);
  //usin for filters
  const myStateRef = React.useRef(getFilters);
  const setMyState = (data: any) => {
    myStateRef.current = data;
    setFilters(data);
  };

  const [sort, setSort] = useState<any>("DIS");

  const { currentUser } = useContext<any>(AuthContext);
  const history = useHistory();

  const { refetch: getPrivateSearch } = usePrivateSearch(
    {
      lowerLon: getViewBox !== null ? getViewBox[0] : undefined,
      lowerLat: getViewBox !== null ? getViewBox[1] : undefined,
      upperLon: getViewBox !== null ? getViewBox[2] : undefined,
      upperLat: getViewBox !== null ? getViewBox[3] : undefined,
      limit: 500,
      startDate: getStartDate,
      endDate: getEndDate,
      ...getFilters,
    },
    {
      enabled: false,
    }
  );

  const { refetch: getPrivateSearch2 } = usePrivateSearch(searchParams, {
    enabled: false,
  });

  const { refetch: getPublicSearch } = usePublicSearch(
    {
      lowerLon: getViewBox !== null ? getViewBox[0] : undefined,
      lowerLat: getViewBox !== null ? getViewBox[1] : undefined,
      upperLon: getViewBox !== null ? getViewBox[2] : undefined,
      upperLat: getViewBox !== null ? getViewBox[3] : undefined,
      limit: 500,
      ...getFilters,
    },
    {
      enabled: false,
    }
  );

  const { mutate: updateUserFilters } = useUpdateUserFilters();

  const { refetch: getUserOwn } = useUserOwn({
    enabled: false,
    onSuccess: (data) => {
      let check = data.vehicle_valid && data.address_valid && data.person_valid;
      setOwn(check);
    },
  });

  // const { refetch: getAddressFromGeocode } = useAddressFromGeocode(
  //   {
  //     latlng,
  //   },
  //   {
  //     enabled: false,
  //     onSuccess: (adressData) => {
  //       console.log(adressData);
  //     },
  //     onError: (error) => console.error(error),
  //   }
  // );

  const { refetch: getUserFilters } = useUserFilters({
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      // console.log(data);
      setFilters(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // const { refetch: getUserRole } = useUserRole({
  //   retry: false,
  //   onSuccess: () => {
  //     getUserFilters();
  //     getUserOwn();
  //   },
  //   onError: (error) => {
  //     FirebaseAuth.signOut()
  //       .then((_) => console.log("user failed"))
  //       .catch((reason) => console.log(reason));
  //     console.info(error);
  //   },
  // });

  React.useEffect(() => {
    if (currentUser != null) {
      if (currentUser.emailVerified === false) {
        history.push("/user/verify");
      } else {
        getUserOwn();
        // getUserRole();
      }
    }

    const storedStateTemp: any = localStorage.getItem(localStorageConstants.parkingAreal.SearchLocalStorage);

    const storedStateFilterTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchFilterLocalStorage
    );
    const storedStateAddressTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage
    );

    if (storedStateTemp) {
      setCenterlat(JSON.parse(storedStateTemp).centerLat);
      setCenterlng(JSON.parse(storedStateTemp).centerLng);
      setLocation({ lat: JSON.parse(storedStateTemp).centerLat, lng: JSON.parse(storedStateTemp).centerLng });
      if (storedStateFilterTemp) {
        setMyState(JSON.parse(storedStateFilterTemp).filters);
      }
      if (storedStateAddressTemp) {
        setAddress(JSON.parse(storedStateAddressTemp).address);
      }
    } else {
      getPositionOf();
    }
  }, []);

  const handleAddressChange = (address: any) => {
    setAddress(address);
  };

  const handleFilterChange = (event: any) => {
    const id = event.target.id === "rentalBathroom" ? "rentalBathrooms" : event.target.id;
    if (!event.target.checked) {
      console.log(id);

      const { [id]: tmp, ...newFilters } = getFilters;
      setMyState({ ...getFilters, [id]: event.target.checked });
      localStorage.setItem(
        localStorageConstants.parkingAreal.SearchFilterLocalStorage,
        JSON.stringify({
          filters: newFilters,
        })
      );
    } else {
      setMyState({ ...getFilters, [id]: event.target.checked });
      localStorage.setItem(
        localStorageConstants.parkingAreal.SearchFilterLocalStorage,
        JSON.stringify({
          filters: { ...getFilters, [id]: event.target.checked },
        })
      );
    }
  };

  const handlekostenlosChange = (event: any) => {
    setKostenlos(!kostenlos);
    if (event.target.checked) {
      setParkingData(getParkingData.filter((first: any) => first.mainPriceUI == "Kostenlos"));
    } else {
      getData();
    }
  };

  const switchChange = (e) => {
    handleFilterChange(e);
    setSwitch(!getSwitch);
    // console.log(e.target.checked);
  };

  useEffect(() => {
    getData();
  }, [getSwitch]);

  const saveFilters = () => {
    updateUserFilters(getFilters);
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchFilterLocalStorage,
      JSON.stringify({
        filters: defaultFilters,
      })
    );
  };

  const handleSortChange = (event: any) => {
    setSort(event.target.value);
    switch (event.target.value) {
      case "ASC":
        setParkingData(
          getParkingData.sort((first: any, second: any) => {
            return parseFloat(first.mainPriceUI) - parseFloat(second.mainPriceUI);
          })
        );
        break;
      case "DSC":
        setParkingData(
          getParkingData.sort((first: any, second: any) => {
            return parseFloat(second.mainPriceUI) - parseFloat(first.mainPriceUI);
          })
        );
        break;
      case "DIS":
        setParkingData(
          getParkingData.sort((first: any, second: any) => {
            return first.distance - second.distance;
          })
        );
    }
  };

  const handleAddressSelect = (address: any) => {
    setAddress(address);
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage,
      JSON.stringify({
        address: address,
      })
    );
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLocation(latLng);
        setViewBox(getBoundingBox([latLng.lat, latLng.lng], 25));
        localStorage.setItem(
          localStorageConstants.parkingAreal.SearchLocationLocalStorage,
          JSON.stringify({
            location: getBoundingBox([latLng.lat, latLng.lng], 25),
          })
        );
      })
      .catch((error) => console.error(error));
  };

  const openMapAndCard = (event: any, item: any) => {
    setMapOpen(true);
    setMoveBox(false);
    setTimeout(function () {
      setSelectedPlace(item);
      setInfoOpen(true);
      setZoom(10);
    }, 1250);
  };

  const openMap = (event: any) => {
    setMapOpen(event.target.checked);
  };

  const openFilters = (event: any) => {
    setFiltersOpen(event.target.checked);
    const storedStateFilterTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchFilterLocalStorage
    );
    if (storedStateFilterTemp !== null) {
      //@ts-ignore
      setMyState(JSON.parse(storedStateFilterTemp).filters);
      //@ts-ignore
      // console.log(storedStateFilterTemp);
    }
  };

  const getData = () => {
    const storedLocationTemp: any = localStorage.getItem(
      localStorageConstants.parkingAreal.SearchLocationLocalStorage
    );

    if (getViewBox !== null) {
      if (currentUser) {
        getPrivateSearch()
          .then((response) => {
            // console.log("Response :", response);
            let result = response.data.map((doc: any) => {
              let item = Object.assign({}, doc);
              item.distance = distance(
                item.location.lat,
                item.location.lon,
                getLocation.lat,
                getLocation.lng
              );
              setCenterlat(getLocation.lat);
              setCenterlng(getLocation.lng);
              return item;
            });

            // setParkingData(
            //   result.sort((first: any, second: any) => {
            //     return first.distance - second.distance;
            //   })
            // );
            if (kostenlos) {
              setParkingData(result.filter((first: any) => first.mainPriceUI == "Kostenlos"));
            } else {
              setParkingData(
                // result.sort((first: any, second: any) => {
                //   return first.distance - second.distance;
                // })
                result
              );
            }
            localStorage.setItem(
              localStorageConstants.parkingAreal.SearchLocalStorage,
              JSON.stringify({
                parkingSpots: result.sort((first: any, second: any) => {
                  return first.distance - second.distance;
                }),
                centerLat: getLocation.lat,
                centerLng: getLocation.lng,
              })
            );
            // ??
            localStorage.setItem(
              localStorageConstants.parkingAreal.SearchLocationLocalStorage,
              JSON.stringify({
                location: getViewBox,
              })
            );
          })
          .catch((error) => console.error(error));
      } else {
        getPublicSearch()
          .then((response) => {
            let result = response.data.map((doc: any) => {
              let item = Object.assign({}, doc);
              item.distance = distance(
                item.location.lat,
                item.location.lon,
                getLocation.lat,
                getLocation.lng
              );
              setCenterlat(getLocation.lat);
              setCenterlng(getLocation.lng);
              return item;
            });

            setParkingData(
              // result.sort((first: any, second: any) => {
              //   return first.distance - second.distance;
              // })
              result
            );

            localStorage.setItem(
              localStorageConstants.parkingAreal.SearchLocationLocalStorage,
              JSON.stringify({
                location: getViewBox,
              })
            );

            localStorage.setItem(
              localStorageConstants.parkingAreal.SearchLocalStorage,
              JSON.stringify({
                parkingSpots: result.sort((first: any, second: any) => {
                  return first.distance - second.distance;
                }),
                centerLat: getLocation.lat,
                centerLng: getLocation.lng,
              })
            );
          })
          .catch((error) => console.error(error));
      }
    } else {
      if (storedLocationTemp) {
        setViewBox(JSON.parse(storedLocationTemp).location);
      }
    }
  };

  const mapGetData = (mapRef: any) => {
    // @ts-ignore
    const lt = mapRef.getCenter().lat();
    // @ts-ignore
    const ln = mapRef.getCenter().lng();

    setLocation({ lat: lt, lng: ln });
    setCenterlng(ln);
    setCenterlat(lt);

    let x = getBoundingBox([lt, ln], 25);
    setViewBox(x);

    setAddress("");
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage,
      JSON.stringify({
        address: "",
      })
    );
    // if (currentUser) {
    //   // setSearchParams({
    //   //   lowerLon: x[0],
    //   //   lowerLat: x[1],
    //   //   upperLon: x[2],
    //   //   upperLat: x[3],
    //   //   startDate: getStartDate,
    //   //   endDate: getEndDate,
    //   //   limit: 200,
    //   //   ...myStateRef.current,
    //   // });
    //   getPrivateSearch()
    //     .then((response) => {
    //       let result = response.data.map((doc: any) => {
    //         let item = Object.assign({}, doc);
    //         item.distance = distance(item.location.lat, item.location.lon, lt, ln);

    //         return item;
    //       });

    //       setParkingData(
    //         result.sort((first: any, second: any) => {
    //           return first.distance - second.distance;
    //         })
    //       );
    //       localStorage.setItem(
    //         localStorageConstants.parkingAreal.SearchLocalStorage,
    //         JSON.stringify({
    //           parkingSpots: result.sort((first: any, second: any) => {
    //             return first.distance - second.distance;
    //           }),
    //           centerLat: lt,
    //           centerLng: ln,
    //         })
    //       );
    //       setAddress("");
    //       localStorage.setItem(
    //         localStorageConstants.parkingAreal.SearchLocationLocalStorage,
    //         JSON.stringify({
    //           location: x,
    //         })
    //       );
    //       localStorage.setItem(
    //         localStorageConstants.parkingAreal.SearchAddressLocalStorage,
    //         JSON.stringify({
    //           address: "",
    //         })
    //       );
    //     })
    //     .catch((error) => console.error(error));
    // } else {
    //   // setViewBox(x);
    //   getPublicSearch()
    //     .then((response) => {
    //       let result = response.data.map((doc: any) => {
    //         let item = Object.assign({}, doc);
    //         item.distance = distance(item.location.lat, item.location.lon, lt, ln);
    //         return item;
    //       });
    //       setParkingData(
    //         result.sort((first: any, second: any) => {
    //           return first.distance - second.distance;
    //         })
    //       );
    //       localStorage.setItem(
    //         localStorageConstants.parkingAreal.SearchLocalStorage,
    //         JSON.stringify({
    //           parkingSpots: result.sort((first: any, second: any) => {
    //             return first.distance - second.distance;
    //           }),
    //           centerLat: lt,
    //           centerLng: ln,
    //         })
    //       );
    //       setAddress("");
    //       localStorage.setItem(
    //         localStorageConstants.parkingAreal.SearchLocationLocalStorage,
    //         JSON.stringify({
    //           location: x,
    //         })
    //       );
    //       localStorage.setItem(
    //         localStorageConstants.parkingAreal.SearchAddressLocalStorage,
    //         JSON.stringify({
    //           address: "",
    //         })
    //       );
    //     })
    //     .catch((error) => console.error(error));
    // }
  };

  const getPosition = (position: any) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    if (lat && lng) {
      setLocation({ lat, lng });

      setViewBox(getBoundingBox([lat, lng], 25));

      setCenterlat(lat);
      setCenterlng(lng);
      localStorage.setItem(
        localStorageConstants.parkingAreal.SearchLocationLocalStorage,
        JSON.stringify({
          location: getBoundingBox([lat, lng], 25),
        })
      );
    }
  };

  React.useEffect(() => {
    getData();
  }, [getViewBox]);

  const positionError = (error: any) => {
    console.log(error);
  };

  const geolocationOptions = {
    enableHighAccuracy: true,
  };

  const getPositionOf = () => {
    setAddress("");
    localStorage.setItem(
      localStorageConstants.parkingAreal.SearchAddressLocalStorage,
      JSON.stringify({
        address: "",
      })
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition, positionError, geolocationOptions);
    }
  };

  const centerChanged = (mapRef: any) => {
    if (moveBox === false) {
      //@ts-ignore
      setCenterlat(mapRef.getCenter().toJSON().lat);
      //@ts-ignore
      setCenterlng(mapRef.getCenter().toJSON().lng);
    }
  };

  const loadHandler = (map: any) => {
    console.log(map);
    setMapRef(map);
    setZoom(10);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker: any, item: any) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [item.id]: marker };
    });
  };

  const markerClickHandler = (event: any, item: any) => {
    setSelectedPlace(item);

    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    if (zoom < 13) {
      setZoom(10);
    }
  };
  //options for cirle
  const options = {
    strokeColor: "#fff",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#fff",
    fillOpacity: 0.1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 34000,
    zIndex: 1,
  };

  const icon = (item) => {
    if (item.park) {
      if (item.bookable) {
        return iconBookablePark;
      } else {
        return iconPark;
      }
    }
    if (item.camp) {
      if (item.bookable) {
        return iconBookableCamp;
      } else {
        return iconCamp;
      }
    }
  };

  const iconBookableCamp = {
    url: "/assets/images/marker/markerBookableCamp.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };

  const iconBookablePark = {
    url: "/assets/images/marker/markerBookablePark.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };
  const iconCamp = {
    url: "/assets/images/marker/markerCamp.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };
  const iconPark = {
    url: "/assets/images/marker/markerPark.png", // url
    scaledSize: new google.maps.Size(40, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
  };

  enum Icon {
    BC = "/assets/images/marker/markerBookableCamp.png",
    BP = "/assets/images/marker/markerBookablePark.png",
    C = "/assets/images/marker/markerCamp.png",
    P = "/assets/images/marker/markerPark.png",
  }

  const renderMap = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBarMain location={"homePage"} />
        {/* <Cookies /> */}
        <Grid
          container
          spacing={0}
          style={
            matches
              ? {
                  //   height: 510,
                  margin: "64px 0px 24px",
                  //   padding: "30px 0px",
                  backgroundImage: `url("/assets/images/back.webp")`,
                  backgroundPosition: "bottom center",
                  backgroundSize: "cover",
                  textAlign: "center",
                  boxShadow: "inset 0 0 10px #6a7400",
                }
              : {
                  //   height: 510,
                  margin: "44px 0px 24px",
                  //   padding: "30px 0px",
                  backgroundImage: `url("/assets/images/back.webp")`,
                  backgroundPosition: "bottom center",
                  backgroundSize: "cover",
                  textAlign: "center",
                  boxShadow: "inset 0 0 10px #6a7400",
                }
          }
        >
          <Grid
            item
            xs={12}
            style={{
              alignSelf: "center",
              paddingTop: "45px",
              paddingBottom: "25px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Typography variant="h2" style={{ color: "#fff", fontSize: "44px", fontWeight: "bold" }}>
              {t("start_plan_your_trip")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingRight: "15px",
              paddingLeft: "15px",
              zIndex: 2,
            }}
          >
            <SearchForm
              handleAddressChange={handleAddressChange}
              handleAddressSelect={handleAddressSelect}
              getPositionOf={getPositionOf}
              isFiltersOpen={isFiltersOpen}
              getData={getData}
              own={own}
            />
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <Collapse in={isFiltersOpen} collapsedSize={0}>
              <Filters
                kostenlos={kostenlos}
                handleKostenlosChange={handlekostenlosChange}
                getFilters={getFilters}
                handleFilterChange={handleFilterChange}
                currentUser={currentUser}
                resetFilters={resetFilters}
                saveFilters={saveFilters}
              />
            </Collapse>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              alignSelf: "center",
              paddingTop: isFiltersOpen ? "25px" : "0px",
              paddingBottom: "0px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            {/* <Typography
              variant="h3"
              style={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                // backgroundColor: matches ? "" : "#aec90b",
              }}
            >
              <span style={{ backgroundColor: "#aec90b" }}>
                Sie finden uns auf dem Caravansalon in Düsseldorf vom 26.08. – 03.09.2023 Halle 3/H16
              </span>
            </Typography> */}
          </Grid>
          <Grid container style={{ paddingTop: "0px", paddingBottom: "20px" }}>
            <Hidden xsDown>
              <Grid item xs={12} md={12}>
                <Box style={{ height: "20px" }}></Box>
              </Grid>

              <Grid item xs={12} md={3} />
            </Hidden>

            <Grid item xs={12} md={9} style={{ alignSelf: "self-end" }}>
              <Grid container spacing={0} justifyContent="flex-end">
                <Grid item xs={12} sm={6} md="auto">
                  <div className={classes.switch}>
                    <FormControlLabel
                      control={
                        <BlueSwitch
                          id="camp"
                          checked={getFilters["camp"]}
                          onChange={(e) => switchChange(e)}
                          color="primary"
                        />
                      }
                      label={t("campsite")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md="auto">
                  <div className={classes.switch}>
                    <FormControlLabel
                      control={
                        <BlueSwitch
                          id="park"
                          checked={getFilters["park"]}
                          onChange={(e) => switchChange(e)}
                          color="primary"
                        />
                      }
                      label={t("parking_space")}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md="auto">
                  <div className={classes.switch}>
                    <FormControlLabel
                      control={<BlueSwitch checked={isFiltersOpen} onChange={openFilters} color="primary" />}
                      label={t("label_show_filters")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md="auto">
                  <div className={classes.switch}>
                    <FormControlLabel
                      control={<BlueSwitch checked={isMapOpen} onChange={openMap} color="primary" />}
                      label={t("label_show_map")}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isMapOpen ? (
          <SearchMap center={{ lat: centerlat, lng: centerlng }} mapGetData={mapGetData} />
        ) : (
          <Container maxWidth={false} className={classes.content}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={5} lg={2} xl={1}>
                <InputLabel id="demo-simple-select-label" shrink>
                  {t("label_sort_by")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sort}
                  onChange={handleSortChange}
                  style={{ display: "block" }}
                >
                  <MenuItem value={"ASC"}>{t("label_price_ascending")}</MenuItem>
                  <MenuItem value={"DSC"}>{t("label_price_descending")}</MenuItem>
                  <MenuItem value={"DIS"}>{t("label_sort_distance")}</MenuItem>
                </Select>
              </Grid>
              <Grid item sm={6} md={7} lg={8} xl={11} />
              {getParkingData.map((item: any, index: number) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <CampCard item={item} openMapAndCard={openMapAndCard} />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
      </React.Fragment>
    );
  };

  return renderMap();
});

// export default Home  = withTranslation()(MapImp);
